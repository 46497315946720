import { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '0.375rem',

    ':hover .ant-select-item-option': {
        color: theme.colors.accentPrimary,
    },
});

export const text: TRuleWithTheme = () => ({
    color: 'unset',
    fontWeight: 500,
});
