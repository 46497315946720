import type { ReactNode } from 'react';
import { useFela } from 'react-fela';

import { Loader } from 'modules/ui';

import { SecurityAuditGraphColumn } from '../SecurityAuditGraphColumn';

import * as felaRules from './SecurityAuditGraph.rules';

export interface BasicReport {
    percentage: number;
    total: number;
}

export interface SecurityAuditGraphProps<Report extends BasicReport> {
    showLoader?: boolean;
    reports: Report[];
    columnKey: (report: Report) => string;
    columnLabel: (report: Report) => ReactNode;
    columnColor: (report: Report) => string;
    label?: ReactNode;
}

export function SecurityAuditGraph<Report extends BasicReport>({
    showLoader = false,
    reports,
    columnKey,
    columnLabel,
    columnColor,
    label,
}: SecurityAuditGraphProps<Report>) {
    const { css } = useFela();

    if (showLoader) {
        return <Loader />;
    }

    const maxPercentage = Math.max(...reports.map(({ percentage }) => percentage));

    return (
        <div className={css(felaRules.container)}>
            <div className={css(felaRules.wrapper)}>
                <div className={css(felaRules.graph)}>
                    {reports.map((report, index) => (
                        <SecurityAuditGraphColumn
                            key={columnKey(report)}
                            label={columnLabel(report)}
                            color={columnColor(report)}
                            percentage={report.percentage}
                            total={report.total}
                            maxPercentage={maxPercentage}
                            columnsCount={reports.length}
                            isFirstColumn={index === 0}
                            isLastColumn={index === reports.length - 1}
                        />
                    ))}
                </div>
            </div>
            {label}
        </div>
    );
}
