import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IS_DEMO } from 'constants/index';
import { useIsAdmin } from 'modules/auth';

import { useLocale } from 'modules/localizations';
import { selectGeneralSettings } from 'modules/settings';
import config from 'config';

export const useGetIframeUrl = () => {
    const { locale } = useLocale();

    const { userCount } = useSelector(selectGeneralSettings);
    const isAdmin = useIsAdmin();

    const urlParams = useMemo(() => {
        const params = new URLSearchParams({
            isDemo: IS_DEMO ? 'true' : 'false',
            isAdmin: isAdmin ? 'true' : 'false',
        });

        if (userCount) {
            params.append('userCount', userCount.toString());
        }

        return params;
    }, [userCount, isAdmin]);

    const baseIframeUrl = config.embeds.pricing.replace('{locale}', locale);
    const iframeUrl = `${baseIframeUrl}?${urlParams.toString()}`;

    return iframeUrl;
};
