import type { ReactNode } from 'react';
import { useFela } from 'react-fela';
import useToggle from 'react-use-toggle';
import { FormattedMessage, useIntl } from 'react-intl';

import { IS_DEMO } from 'constants/index';

import { Button, IconButton, Text } from 'modules/ui';
import { useSubscriptionUpgradeModal } from 'modules/saas-upgrade';

import * as felaRules from './UpgradeNotice.rules';

export interface UpgradeNoticeProps {
    children: ReactNode;
}

const shouldShowUpgrade = IS_DEMO;

export const UpgradeNotice = ({ children }: UpgradeNoticeProps) => {
    const [visible, toggle] = useToggle(shouldShowUpgrade);

    const { css } = useFela({
        visible,
    });

    const intl = useIntl();
    const { openUpgradeModal } = useSubscriptionUpgradeModal();

    return (
        <div className={css(felaRules.container)}>
            <div className={css(felaRules.noticeContainer)}>
                <div data-testid="upgrade-notice" className={css(felaRules.notice)}>
                    <Text size="small" color="white" customStyle={felaRules.text}>
                        <FormattedMessage
                            tagName="span"
                            id="demo.upgradeNotice.text"
                            values={{
                                b: chunks => <b>{chunks}</b>,
                            }}
                        />
                        <Button onClick={openUpgradeModal} size="small" customStyle={felaRules.buyButton}>
                            <FormattedMessage id="demo.upgradeNotice.cta" />
                        </Button>
                    </Text>
                    <IconButton
                        type="primary"
                        icon={{
                            type: 'close',
                            width: 16,
                            height: 16,
                        }}
                        onClick={toggle}
                        customStyle={felaRules.closeButton}
                        aria-label={intl.formatMessage({
                            id: 'upgradeNotice.close',
                        })}
                    />
                </div>
            </div>
            <div className={css(felaRules.content)}>{children}</div>
        </div>
    );
};
