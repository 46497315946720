// Rule used by TextField, TextAreaField and PasswordField
import type { TRuleWithTheme } from 'styles/theme';

export const style: TRuleWithTheme = ({ theme }) => {
    const active = {
        borderColor: theme.colors.accentSecondary,
    };

    const placeholder = {
        color: theme.colors.foregroundsPlaceholder,
    };

    const borderWidth = 1;

    const size = {
        paddingTop: `calc(0.5rem + 1px - ${borderWidth}px)`,
        paddingRight: '0.75rem',
        paddingBottom: `calc(0.5rem - 1px - ${borderWidth}px)`,
        paddingLeft: '0.75rem',
    };

    const font = {
        fontFamily: theme.fontFamily,
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '1.5rem',
    };

    const style = {
        height: '2.5rem',

        color: theme.colors.black,
        backgroundColor: theme.colors.white,

        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.colors.inputsBorder,

        boxShadow: 'none',
    };

    return {
        '&.ant-input': {
            ...size,
            ...font,
            ...style,
            ':hover': active,
            ':focus': active,
            '::placeholder': placeholder,
        },

        '&.ant-input[rows]': {
            height: 'unset',
        },

        '&.ant-input-affix-wrapper': {
            ...size,
            ...style,

            ':hover': active,
        },

        '&.ant-input-affix-wrapper.ant-input-affix-wrapper-focused': active,

        '&.ant-input-affix-wrapper > .ant-input': {
            ...font,

            backgroundColor: 'transparent',

            padding: '0',
            border: 'none',
            outline: 'none',

            '::placeholder': placeholder,
        },

        '&.ant-input-affix-wrapper > .ant-input:focus': {
            boxShadow: 'none !important',
        },

        '&.ant-input-affix-wrapper > .ant-input-suffix': {
            marginRight: '-0.25rem',
            marginLeft: '0.5rem',
        },

        '&.ant-input-affix-wrapper > .ant-input-prefix': {
            marginLeft: '-0.25rem',
            marginRight: '0.5rem',
        },
    };
};
