import { Antonio } from '@ackee/antonio-core';

import { IS_DEMO } from 'constants/index';
import { config } from 'config/config';

import { backendVersionInterceptor } from 'modules/backend-version/utils';
import { totalCountInterceptor } from 'modules/secrets/utilities/interceptors';
import { demoUserInterceptor, requestAuthHeaderInterceptor } from 'modules/auth';

const antonioConfig = {
    baseURL: config.api.base,
};

export const api = new Antonio(antonioConfig);
export const publicApi = new Antonio(antonioConfig);

const versionInterceptor = (request: Request) => {
    request.headers.set('version', `FE_${config.version}`);
    return request;
};

api.interceptors.request.use(undefined, requestAuthHeaderInterceptor);
api.interceptors.request.use(undefined, versionInterceptor);
if (IS_DEMO) api.interceptors.request.use(undefined, demoUserInterceptor);

api.interceptors.response.use(backendVersionInterceptor);
api.interceptors.response.use(totalCountInterceptor);

publicApi.interceptors.request.use(undefined, versionInterceptor);
publicApi.interceptors.response.use(backendVersionInterceptor);
