import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useFela } from 'react-fela';
import { useController } from 'react-hook-form';

import { Icon, Tag } from 'modules/ui';

import * as felaRules from './TagsInput.rules';
import { SelectInput, SelectInputProps } from '../SelectInput';

export interface TagsInputProps {
    name: string;
    options?: SelectInputProps['options'];
}

const MIN_TAG_LENGTH = 2;
const defaultArray = [];

export const TagsInput = ({ options = defaultArray, name }: TagsInputProps) => {
    const { formatMessage } = useIntl();
    const { css } = useFela();

    const {
        field: { onBlur, onChange, value },
    } = useController({ name, defaultValue: defaultArray });

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const filteredOptionsWithSearchValue = useMemo(() => {
        const filteredOptions = options.filter(option => 'value' in option && !value.includes(option.value));

        const shouldShowSearchOption =
            searchValue.length >= MIN_TAG_LENGTH &&
            !options.some(option => 'value' in option && option.value.toLowerCase() === searchValue.toLowerCase());

        if (!shouldShowSearchOption) return filteredOptions;

        const searchOption = {
            value: searchValue,
            label: formatMessage(
                {
                    id: 'form.field.tags.create',
                },
                {
                    value: searchValue,
                },
            ),
        };

        return [searchOption, ...filteredOptions];
    }, [formatMessage, options, searchValue, value]);

    return (
        <>
            <SelectInput
                mode="multiple"
                onDropdownVisibleChange={open => setDropdownOpen(open)}
                open={dropdownOpen}
                value={[]}
                onBlur={e => {
                    onBlur();
                }}
                onChange={newVal => {
                    onChange([...value, ...newVal]);
                    setDropdownOpen(false);
                    setSearchValue('');
                }}
                options={filteredOptionsWithSearchValue}
                // @ts-expect-error
                tagRender={() => null}
                placeholder={formatMessage({ id: 'tags.new' })}
                searchValue={searchValue}
                onSearch={setSearchValue}
            />
            {value.length > 0 && (
                <div className={css(felaRules.tagsListContainer)} role="none">
                    {value.map(tag => (
                        <Tag
                            closable
                            closeIcon={<Icon type="delete" width={14} height={14} />}
                            key={tag}
                            onClose={() => {
                                onChange(value.filter(val => val !== tag));
                            }}
                        >
                            {tag}
                        </Tag>
                    ))}
                </div>
            )}
        </>
    );
};
