import { FormattedMessage } from 'react-intl';

import { Button, Icon } from 'modules/ui';

import { useUpdateSecretForm } from '../../../../../hooks';

export const SaveSecretButton = () => {
    const { isDirty, isSubmitting } = useUpdateSecretForm();

    return (
        <Button
            type="primary"
            size="large"
            icon={<Icon width={20} height={20} type="save" />}
            htmlType="submit"
            loading={isSubmitting}
            disabled={!isDirty}
        >
            <span>
                <FormattedMessage id="secret.save" />
            </span>
        </Button>
    );
};
