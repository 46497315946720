import tinyColor from 'tinycolor2';

import type { SecretType } from 'modules/secrets';

const info = '#1890ff';
const red = '#f5222d';

const baseColors = {
    white: '#ffffff',
    black: '#000000',

    brownGrey: '#808080',
    brownGrey2: '#9b9b9b',
    brownGrey3: '#bfbfbf',
    brownGrey4: '#666666',

    lightGrey: ' #ebebeb',
    lightGrey2: '#f8f7f6',
    lightGrey3: '#d9d9d9',
    lightGrey4: '#e0e0e0',
    lightGrey5: '#ececec',

    blue: '#0000ff',
    hoverBlue: '#0000c2',
    overlayBlue: '#f4f4ff',

    brightMagenta: '#ff00eb',
    hoverBrightMagenta: tinyColor('#ff00eb').darken(5).toString(),
    paleMauve: '#fff0fe',

    lightGreen: '#70dcb1',
    lightPink: '#ffe5fc',
    green: '#2fd894',
    red,
    hoverRed: '#c70000',
    orange: '#ff9900',
    lime: '#e2ffd1',
    yellow: '#feff00',

    overlay: 'rgba(244, 244, 255, 0.7)',
    password: {
        0: `#f92525`,
        1: `#f95225`,
        2: `#ffb733`,
        3: `#3333ff`,
        4: `#2fd894`,
    },

    messageIcons: {
        success: '#2FD894',
        error: red,
        info,
        warning: '#faad14',
        loading: info,
    },

    report: {
        red: '#ff0000',
        yellow: '#ffd600',
        grey: '#9b9b9b',
    },

    bgGrey: `#F8F7F6`,
    columnGraphLabel: `#9B9B9B`,

    secretType: {
        password: '#0000FF',
        paymentCard: '#F6AC23',
        apiCredentials: '#FF00EB',
        databaseCredentials: '#000000',
        sshKey: '#2FD894',
        secureNote: '#F6AC23',
    } satisfies Record<SecretType, string>,

    // === New design system colors ===

    // Foregrounds
    foregroundsPrimary: '#000000',
    foregroundsSecondary: '#0000FF',
    foregroundsTertiary: '#737580',
    foregroundsPlaceholder: '#B7B7BC',
    foregroundsSeparator: '#E0E0E0',
    foregroundsOnPrimary: '#FFFFFF',

    // Backgrounds
    backgroundsSecondary: '#FFFFFF',
    backgroundsTertiary: '#F8F8FF',
    backgroundsOverlay: '#0000001A',
    backgroundsSelected: '#FFD1FB33',
    backgroundsOnSecondary: '#F8F7F6',

    // Accents
    accentPrimary: '#0000FF',
    accentSecondary: '#FF00EB',
    accentTertiary: '#2FD894',

    // Icons
    iconsPrimary: '#000000',
    iconsSecondary: '#9B9B9B',
    iconsTertiary: '#0000FF',
    iconsOnPrimary: '#FFFFFF',

    // Inputs
    inputsBackground: '#FFFFFF',
    inputsBackgroundPlaceholder: '#F8F7F6',
    inputsBorder: '#D9D9D9',
    inputsBorderFocus: '#FF00EB',
    inputsLabel: '#737580',
    inputsError: '#FF0000',

    // Alerts
    alertsSuccessText: '#1D9B68',
    alertsSuccessBg: '#E1F9EF',
    alertsWarningText: '#D06400',
    alertsWarningBg: '#FFEFD3',
    alertsErrorText: '#C4554D',
    alertsErrorBg: '#FAE3DE',
    alertsInfoText: '#4281DB',
    alertsInfoBg: '#F2F6FC',

    // Tabs
    tabsEnabled: '#000',
    tabsSelected: '#FF00EB',
} as const;

const clientCustomColors: typeof baseColors = (() => {
    const colors = process.env.REACT_APP_THEME_COLORS;
    return JSON.parse(colors || '{}');
})();

const colors = {
    ...baseColors,
    ...clientCustomColors,
};

export default colors;
