import type { TRuleWithTheme } from 'styles/theme';

export const sectionContainer: TRuleWithTheme<{ gap: number }> = ({ theme, gap }) => ({
    padding: '1.25rem',
    paddingBottom: '1.75rem',

    display: 'flex',
    flexDirection: 'column',
    gap: `${gap}rem`,

    borderRadius: '0.25rem',

    overflow: 'hidden',

    backgroundColor: theme.colors.backgroundsSecondary,
});

export const contentContainer: TRuleWithTheme = ({ theme }) => ({
    height: '100%',

    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',

    min1024: {
        gap: '1.5rem',
    },
});
