import type { CSSObject } from 'fela';

import type { TRuleWithTheme } from 'styles/theme';

import type { EmptyProps } from './Empty';

export enum EmptySize {
    REGULAR = 'regular',
    SMALL = 'small',
    EXTRA_SMALL = 'extra-small',
}

const SIZE_STYLE: Record<EmptySize, CSSObject> = {
    [EmptySize.REGULAR]: {
        marginTop: '3rem',
        marginBottom: '3.5rem',

        fontSize: '1rem',
        lineHeight: '1.5rem',
    },
    [EmptySize.SMALL]: {
        marginTop: '2rem',

        fontSize: '0.875rem',
        lineHeight: '1.5rem',
    },
    [EmptySize.EXTRA_SMALL]: {
        fontSize: '0.625rem',
        lineHeight: 'mormal',
    },
};

export const container: TRuleWithTheme<Pick<EmptyProps, 'size' | 'color'>> = ({
    theme,
    size,
    color = 'foregroundsTertiary',
}) => ({
    ...(size && SIZE_STYLE[size]),

    color: theme.colors[color] ?? theme.colors.foregroundsTertiary,

    width: '100%',

    textAlign: 'center',
    ':before': {
        content: `'"'`,
    },
    ':after': {
        content: `'"'`,
    },
});
