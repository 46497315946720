import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFela } from 'react-fela';

import { Label } from 'modules/forms';
import { Button, Icon, IconButton, Text } from 'modules/ui';
import type { IconProps } from 'modules/ui';
import { useUser } from 'modules/auth';
import { StaticModal, useModal } from 'modules/modals';
import { ALL_USERS_GROUP_ID } from 'modules/groups';
import type { MessageKey } from 'modules/localizations/translations/types';

import type { ViewFilter } from '../../types';
import { useViewFilter } from '../../hooks/useViewFilter';
import { SecretsViewModal } from '../SecretsViewModal';

import * as felaRules from './SecretsView.rules';

const getIconType = (viewFilter: ViewFilter | null): IconProps['type'] => viewFilter?.type ?? ('admin' as const);

const getMessageId = (viewFilter: ViewFilter | null) => `secrets.view.${getIconType(viewFilter)}` as const;

export const AdminSecretsView = () => {
    const { css } = useFela();

    const intl = useIntl();

    const user = useUser();

    const { viewFilter, setView } = useViewFilter();

    const { modal, updateModal } = useModal(StaticModal.VIEW_FILTER);

    const label = useMemo(() => {
        if (viewFilter) {
            if (viewFilter.id === ALL_USERS_GROUP_ID) {
                return intl.formatMessage({
                    id: 'secret.groups.allUsers.label',
                });
            }
            return viewFilter.name;
        }
        return user?.name;
    }, [viewFilter, user, intl]);

    return (
        <>
            <SecretsViewModal
                open={modal?.visibility ?? false}
                defaultViewFilter={viewFilter}
                onSubmit={viewFilter => {
                    setView(viewFilter);
                    updateModal(false);
                }}
                onCancel={() => {
                    updateModal(false);
                }}
            />
            <div className={css(felaRules.container)}>
                <div className={css(felaRules.userInfo)}>
                    <Label size="small" color="brownGrey">
                        <FormattedMessage id={getMessageId(viewFilter) as MessageKey} />
                    </Label>
                    <Button
                        type="link"
                        customStyle={felaRules.user}
                        onClick={() => {
                            updateModal(true);
                        }}
                        aria-label={`${intl.formatMessage({
                            id: getMessageId(viewFilter) as MessageKey,
                        })} ${label}`}
                    >
                        <Icon
                            color="black"
                            width={16}
                            height={16}
                            type={getIconType(viewFilter)}
                            customStyle={felaRules.userIcon}
                        />
                        <span className={css(felaRules.name)}>{label}</span>
                    </Button>
                </div>
                {viewFilter ? (
                    <IconButton
                        type="ghost"
                        icon={{
                            type: 'x',
                            width: 12,
                            height: 12,
                            color: 'brownGrey',
                        }}
                        onClick={() => {
                            setView(null);
                        }}
                        customStyle={felaRules.close}
                        aria-label={intl.formatMessage({
                            id: 'secrets.view.clear',
                        })}
                    />
                ) : null}
            </div>
        </>
    );
};

export const UserSecretsView = () => {
    const { css } = useFela();

    const user = useUser();

    return (
        <div className={css(felaRules.container)}>
            <div className={css(felaRules.userInfo)}>
                <Label size="small" color="brownGrey">
                    <FormattedMessage id="secrets.view.user" />
                </Label>
                <Text elementType="span" size="small" customStyle={felaRules.user}>
                    <Icon width={16} height={16} type="user" customStyle={felaRules.userIcon} />
                    <span className={css(felaRules.name)}>{user?.name}</span>
                </Text>
            </div>
        </div>
    );
};
