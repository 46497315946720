import { take, put, delay, race } from 'redux-saga/effects';

import { IS_DEVELOPMENT_NODE_ENV } from 'constants/index';

import { callWhenOnline } from 'modules/network';

import { activateSyncSecrets, syncSecrets } from '../actions';

export const SYNC_SECRETS_TIMEOUT = (IS_DEVELOPMENT_NODE_ENV ? 5 : 30) * 60 * 1000; // 5 or 30 minutes

function* syncingSaga() {
    while (true) {
        const result = yield race({
            delay: delay(SYNC_SECRETS_TIMEOUT),
            sync: take(syncSecrets.toString()),
        });

        if (result.delay) {
            yield put(syncSecrets());
        }
    }
}

export default function* () {
    yield take(activateSyncSecrets.toString());

    yield callWhenOnline(syncingSaga);
}
