import { put, takeEvery } from 'redux-saga/effects';

import { logger } from 'config/logger';
import { api } from 'config/antonio';
import config from 'config';
import { actions as messagesActions } from 'modules/messages';

import { saasCheckoutActions } from '../actions';
import { SAASCheckoutResponse } from '../../types';
import { getTrackdeskCid } from '../../utils';

function* saasCheckoutHandler({ payload }: ReturnType<typeof saasCheckoutActions.request>) {
    try {
        const response = yield* api.post<SAASCheckoutResponse>(config.api.saasCheckout, payload);

        if (response.data.url) {
            const stripeUrl = new URL(response.data.url);

            const trackdeskCid = getTrackdeskCid();

            if (trackdeskCid) {
                /** @link https://help.trackdesk.com/en/articles/6867895-stripe-integration-via-payment-links#h_3f22230f16 */
                stripeUrl.searchParams.append('client_reference_id', trackdeskCid);
            }

            window.location.href = stripeUrl.href;
        } else {
            throw new Error('No URL returned from the server');
        }

        yield put(saasCheckoutActions.success(response.data));
    } catch (e) {
        yield put(
            messagesActions.displayErrorMessage({
                message: { id: 'error.saas-upgrade.saasCheckout' },
                options: { duration: 10 },
            }),
        );

        logger.error(e);

        yield put(saasCheckoutActions.failure({ error: e }));
    }
}

export default function* saasCheckoutSaga() {
    yield takeEvery(saasCheckoutActions.request.toString(), saasCheckoutHandler);
}
