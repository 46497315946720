export const redactPaymentCardNumber = (cardNumber?: string) => {
    const END_OFFSET = 4;

    const number = cardNumber?.replaceAll(' ', '') ?? '';

    const redactedNumber = '•••• •••• •••• ' + number.slice(-END_OFFSET);

    return redactedNumber;
};

export const formatPaymentCardNumber = (cardNumber?: string) =>
    cardNumber
        ?.replaceAll(' ', '')
        .replace(/(.{4})/g, '$1 ')
        .trim() ?? '';
