import { Popover } from 'antd';
import { FormattedMessage } from 'react-intl';
import type { PropsWithChildren } from 'react';

import { Button } from 'modules/ui';

import * as felaRules from './GeneratorButtonPopover.rules';

export interface GeneratorButtonPopoverProps extends PropsWithChildren {
    isOpen: boolean;
    openPasswordGenerator: () => void;
}

export const GeneratorButtonPopover = ({ isOpen, openPasswordGenerator, children }: GeneratorButtonPopoverProps) => (
    <Popover
        content={
            <Button
                type="text"
                onClick={() => openPasswordGenerator()}
                color="foregroundsPrimary"
                customStyle={felaRules.generatePasswordButton}
            >
                <FormattedMessage id="passwordGenerator" />
            </Button>
        }
        open={isOpen}
        placement="bottomRight"
        arrow={false}
        zIndex={0}
        overlayInnerStyle={felaRules.popover}
        align={{ offset: [0, 0] }}
    >
        {children}
    </Popover>
);
