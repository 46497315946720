import { useSelector } from 'react-redux';

import { selectGeneralSettings } from '../services/selectors';
import { SubscriptionType } from '../constants';

export const useSubscriptionAlerts = () => {
    const { subscription } = useSelector(selectGeneralSettings);

    const canceledSubscriptionAlertVisible = subscription === SubscriptionType.CANCELED;

    const notLinkedAlertVisible = subscription === SubscriptionType.NOT_LINKED;

    return { canceledSubscriptionAlertVisible, notLinkedAlertVisible };
};
