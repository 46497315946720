import { logger } from 'config/logger';

import { TOTPError } from '../errors/TOTPError';

export function decodeQRCode(file: File) {
    return new Promise<string | TOTPError>((resolve, reject) => {
        const url = URL.createObjectURL(file);
        const revokeUrl = () => URL.revokeObjectURL(url);

        const image = new Image();

        image.onload = async () => {
            try {
                const { BrowserQRCodeReader } = await import('@zxing/browser');
                const codeReader = new BrowserQRCodeReader();
                const result = codeReader.decode(image);

                revokeUrl();

                resolve(result.getText());
            } catch (e) {
                switch ((e as Error)?.message) {
                    case 'NotFoundException':
                        resolve(new TOTPError('NO_CONTENT_PARSED'));
                        break;

                    default:
                        logger.error(e);
                        resolve(new TOTPError('UNKNOWN'));
                }
            }
        };

        image.onerror = event => {
            revokeUrl();
            reject(event);
        };

        image.src = url;
    });
}
