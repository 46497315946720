import { FormattedMessage } from 'react-intl';

import { hasReadPermission } from 'utilities/permission';
import { isEmptyOrUndefined } from 'utilities/string';

import { Fields, SecretType } from '../../../../../../constants';
import type { Secret } from '../../../../../../types';
import { CopySection } from '../CopySection';

export interface SshKeySectionsProps {
    secret: Extract<Secret, { type: SecretType.SshKey }>;
}

export const SshKeySections = ({ secret }: SshKeySectionsProps) => {
    const { sensitiveFields, publicKey } = secret;

    const hasRead = hasReadPermission(secret.userPermissions);

    return (
        <>
            <CopySection
                visible={sensitiveFields?.includes(Fields.PRIVATE_KEY)}
                headingMessageKey="secret.privateKey.label"
                secret={secret}
                property={Fields.PRIVATE_KEY}
                mask={<FormattedMessage id="crypted.password" />}
                disabled={!hasRead}
            />
            <CopySection
                visible={!isEmptyOrUndefined(publicKey)}
                headingMessageKey="secret.publicKey.label"
                secret={secret}
                property={Fields.PUBLIC_KEY}
                mask={<FormattedMessage id="crypted.password" />}
            />
        </>
    );
};
