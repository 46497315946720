import { useRef } from 'react';
import { VariableSizeList } from 'react-window';

import { useBreakpoint } from 'hooks/useBreakpoint';

import {
    ListItemType,
    SecretListId,
    SecurityAuditGroupSize,
    SecurityAuditSecretSize,
    SecurityAuditSecretWithAdditionalSize,
} from '../../constants';
import type { ListItemSize, SecurityAuditReportItem } from '../../types';
import { isSecurityAuditDuplicitiesSecret } from '../../utilities';

import { useSecretsListOffset } from '../../hooks/useSecretsListOffset';

import { SecurityAuditReportItemRenderer } from '../SecurityAuditReportItemRenderer';

export interface SecurityAuditReportSecretsListProps {
    data: SecurityAuditReportItem[];
    height: number;
    width: number;
}

export const SecurityAuditReportSecretsList = ({ data, height, width }: SecurityAuditReportSecretsListProps) => {
    const listRef = useRef<VariableSizeList<typeof data>>(null);

    const handleOffset = useSecretsListOffset(SecretListId.AUDIT_REPORT_LIST, listRef);

    const isMin768 = useBreakpoint('sm');

    return (
        <VariableSizeList<typeof data>
            ref={listRef}
            height={height}
            width={width}
            itemData={data}
            itemCount={data.length}
            estimatedItemSize={
                isMin768 ? SecurityAuditSecretWithAdditionalSize.desktop : SecurityAuditSecretWithAdditionalSize.mobile
            }
            itemSize={index => {
                const item = data[index];

                let size: ListItemSize = SecurityAuditGroupSize;

                if (item.listItemType === ListItemType.SECRET) {
                    if (isSecurityAuditDuplicitiesSecret(item)) {
                        size = SecurityAuditSecretWithAdditionalSize;
                    }

                    size = SecurityAuditSecretSize;
                }

                return isMin768 ? size.desktop : size.mobile;
            }}
            itemKey={index => data[index].key}
            onScroll={({ scrollOffset }) => {
                handleOffset(scrollOffset);
            }}
        >
            {/* @ts-expect-error */}
            {SecurityAuditReportItemRenderer}
        </VariableSizeList>
    );
};
