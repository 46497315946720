import { useFela } from 'react-fela';
import { FormattedMessage, useIntl } from 'react-intl';
import { Space } from 'antd';

import { FormField, Label, TextInput } from 'modules/forms';

import { Fields } from '../../../../constants';
import { CardExpirationField } from './CardExpirationField';

import * as felaRules from './CardInformationFields.rules';

export const CardInformationFields = () => {
    const { css } = useFela();
    const { formatMessage } = useIntl();

    return (
        <div className={css(felaRules.inputGroup)}>
            <FormField
                label={
                    <Label>
                        <FormattedMessage id="secret.paymentCardInformation.label" />
                    </Label>
                }
                name={[Fields.CARD_NUMBER, Fields.EXPIRATION_DATE, Fields.CVV_CODE]}
            >
                <Space.Compact direction="vertical" block={true}>
                    <TextInput
                        name={Fields.CARD_NUMBER}
                        autoComplete="off"
                        transformValue={val =>
                            val
                                .replace(/[^\d]/g, '')
                                .replace(/(\d{4})/g, '$1 ')
                                .trim()
                        }
                        placeholder={formatMessage({ id: 'secret.paymentCardInformation.cardNumber.placeholder' })}
                    />
                    <Space.Compact direction="horizontal">
                        <CardExpirationField />

                        <TextInput
                            name={Fields.CVV_CODE}
                            autoComplete="off"
                            transformValue={val => val.replace(/[^\d]/g, '')}
                            placeholder={formatMessage({ id: 'secret.paymentCardInformation.cvvCode.placeholder' })}
                        />
                    </Space.Compact>
                </Space.Compact>
            </FormField>
        </div>
    );
};
