import type { SVGProps } from 'react';

export const SecretTypeSecureNote = (props: SVGProps<SVGSVGElement>) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M3 3.25C3 3.11193 3.11193 3 3.25 3H14.75C14.8881 3 15 3.11193 15 3.25V9.33579C15 9.40209 14.9737 9.46568 14.9268 9.51256L12.2197 12.2197L9.51256 14.9268C9.46568 14.9737 9.40209 15 9.33579 15H3.25C3.11193 15 3 14.8881 3 14.75V3.25Z"
            stroke="currentColor"
            strokeWidth="1.5"
        />
        <path d="M9.75 15V10.25C9.75 9.97386 9.97386 9.75 10.25 9.75H14.25" stroke="currentColor" strokeWidth="1.5" />
    </svg>
);
