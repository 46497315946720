import { FormattedMessage } from 'react-intl';
import { useFela } from 'react-fela';
import tinycolor from 'tinycolor2';

import type { Theme } from 'styles/theme';

import { useFetchAudit } from '../../hooks/useFetchAudit';

import { SecurityAuditGraph } from '../SecurityAuditGraph';

export const SecurityAuditLevelGraph = () => {
    const { showLoader, data } = useFetchAudit();

    const { theme } = useFela<Theme>();

    const levelColors = [
        tinycolor(theme.colors.blue).setAlpha(0.3).toString(),
        tinycolor(theme.colors.blue).setAlpha(0.4).toString(),
        tinycolor(theme.colors.blue).setAlpha(0.6).toString(),
        tinycolor(theme.colors.blue).setAlpha(0.8).toString(),
        theme.colors.blue,
    ];

    return (
        <SecurityAuditGraph
            showLoader={showLoader}
            reports={data.reports}
            columnKey={report => `${report.level}`}
            columnLabel={report => <FormattedMessage id={`password.strength.${report.level}`} />}
            columnColor={report => levelColors[report.level]}
        />
    );
};
