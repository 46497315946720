import { takeEvery, put } from 'redux-saga/effects';
import { replace } from 'redux-first-history';

import { api } from 'config/antonio';
import { config } from 'config/config';
import { logger } from 'config/logger';

import { isSaasLimitReachedError } from 'services/utilities/isApiError';

import { actions as messagesActions } from 'modules/messages';

import { MAX_SAAS_SECRETS_COUNT, MAX_SAAS_SECRETS_COUNT_ERROR_TYPE } from '../../constants';

import {
    createSecretSuccess,
    createSecretFailure,
    setSecrets,
    createSecret,
    syncSecrets,
    setSecretsTotalCount,
} from '../actions';
import { normalizeSecret } from '../normalizers';
import { prepareSecretPayload } from '../selectors/utilities';

function* handler({ payload, meta }: ReturnType<typeof createSecret>) {
    try {
        const { data } = yield* api.post<any>(config.api.secrets, prepareSecretPayload(payload));

        const { entities, result } = normalizeSecret(data);

        yield put(createSecretSuccess());
        yield put(setSecrets(entities.secrets, [result]));

        meta.success();

        yield put(replace(config.routes.secret.replace(':id', result)));

        yield put(
            messagesActions.displaySuccessMessage({
                message: { id: 'success.secrets.create' },
            }),
        );
    } catch (e) {
        logger.error(e);

        yield put(createSecretFailure(e));

        if (isSaasLimitReachedError(e)) {
            meta.failure('root', {
                message: `error.secrets.create.saas-limit|{"maxSecrets": "${MAX_SAAS_SECRETS_COUNT}"}`,
                type: MAX_SAAS_SECRETS_COUNT_ERROR_TYPE,
            });

            // Show the count alert and trigger sync
            yield put(setSecretsTotalCount(MAX_SAAS_SECRETS_COUNT));
            yield put(syncSecrets(true));

            return;
        }

        meta.failure('root', {
            message: 'error.secrets.create',
        });
    }
}

export default function* () {
    yield takeEvery(createSecret.toString(), handler);
}
