import type { ReactNode } from 'react';

import { Secret } from './Secret';
import { Text } from './Text';
import type { TextProps } from './Text';

export enum CopyButtonType {
    TEXT = 'text',
    PASSWORD = 'password',
    TOTP = 'totp',
    PAYMENT_CARD_NUMBER = 'paymentCardNumber',
    CVV_CODE = 'cvvCode',
    SECURE_TEXT_AREA = 'secureTextArea',
}

export interface CopyButtonProps extends Pick<TextProps, 'text' | 'disabled' | 'children'> {
    type?: `${CopyButtonType}`;
    mask?: ReactNode;
}

export const CopyButton = ({ type = CopyButtonType.TEXT, ...props }: CopyButtonProps) => {
    if (
        type === CopyButtonType.PASSWORD ||
        type === CopyButtonType.TOTP ||
        type === CopyButtonType.PAYMENT_CARD_NUMBER ||
        type === CopyButtonType.CVV_CODE ||
        type === CopyButtonType.SECURE_TEXT_AREA
    ) {
        return <Secret type={type} {...props} />;
    }

    return <Text {...props} />;
};
