import { FormattedMessage } from 'react-intl';
import { useController } from 'react-hook-form';

import { RadioButtonGroup, SettingsFormItem } from 'modules/forms';

import { Fields } from '../../../constants';
import { SecretsFormat } from '../../../types';
import { ImportFormValues } from '../../../schemas';

const options = Object.values(SecretsFormat);

const name = Fields.SECRETS_FORMAT;

export const SecretsFormatField = () => {
    const {
        field: { ref, ...field },
    } = useController<ImportFormValues, typeof name>({ name });

    return (
        <SettingsFormItem name={name} label={<FormattedMessage id="import.form.secretsFormat.label" />}>
            <RadioButtonGroup
                {...field}
                optionType="button"
                options={options.map(value => ({
                    label: <FormattedMessage id={`import.form.secretsFormat.${value}`} />,
                    value,
                }))}
            />
        </SettingsFormItem>
    );
};
