import { createExpirationDate } from '@ackee/petrus';
import type { PetrusConfig } from '@ackee/petrus';

import type { AccessTokenResponse, DemoAccessTokenResponse } from '../../types';

import { getDemoUser } from '../helpers/getDemoUser';
import { isDemoAccessTokenResponse } from '../utils';

// @ts-expect-error
export const enforceAccessTokenScheme: PetrusConfig['oAuth']['enforceAccessTokenScheme'] =
    function enforceAccessTokenScheme(params: AccessTokenResponse | DemoAccessTokenResponse) {
        if (isDemoAccessTokenResponse(params)) {
            const { credentials } = getDemoUser(params.userId);
            const { accessToken, expiresIn } = credentials;

            return {
                token: accessToken,
                expiration: createExpirationDate(expiresIn * 1000),
                ...params,
            };
        }

        return {
            token: params.access_token,
            expiration: new Date(params.expiry_date).toISOString(),
            idToken: params.id_token,
        };
    };
