import { useFela } from 'react-fela';
import { FormattedMessage, useIntl } from 'react-intl';

import { Checkbox, Form, FormField, TextInput } from 'modules/forms';
import { Heading, Link, Text } from 'modules/ui';

import config from 'config';

import { ReviewSites } from './ReviewSites';
import { FormButtons } from './FormButtons';

import { reviewPromptFormSchema, type ReviewPromptFormSchemaType, type ReviewPromptFormValues } from '../../../schema';
import { useSubmitReviewPromptForm } from '../../../hooks';

import * as felaRules from './ReviewPromptForm.rules';

export interface ReviewPromptFormProps {
    onSubmitSuccess: () => void;
    onClose: () => void;
}

export const ReviewPromptForm = ({ onSubmitSuccess, onClose }: ReviewPromptFormProps) => {
    const { css } = useFela();
    const { formatMessage } = useIntl();

    const { handleSubmit, defaultValues } = useSubmitReviewPromptForm({
        onSubmitSuccess,
    });

    return (
        <Form<ReviewPromptFormSchemaType, ReviewPromptFormValues>
            schema={reviewPromptFormSchema}
            onSubmit={handleSubmit}
            defaultValues={defaultValues}
        >
            <Text>
                <FormattedMessage id="review-prompt.form.description" />
            </Text>

            <ReviewSites />

            <Heading elementType="h2" color="black" customStyle={felaRules.heading}>
                <FormattedMessage id="review-prompt.form.personalInfo.title" />
            </Heading>

            <div className={css(felaRules.inputsContainer)}>
                <FormField
                    name="name"
                    label={<FormattedMessage id="review-prompt.form.personalInfo.name.title" />}
                    required
                >
                    <TextInput
                        name="name"
                        placeholder={formatMessage({ id: 'review-prompt.form.personalInfo.name.placeholder' })}
                    />
                </FormField>
                <FormField
                    name="email"
                    label={<FormattedMessage id="review-prompt.form.personalInfo.email.title" />}
                    required
                >
                    <TextInput
                        name="email"
                        placeholder={formatMessage({ id: 'review-prompt.form.personalInfo.email.placeholder' })}
                    />
                </FormField>
                <FormField name="tocAgreement">
                    <Checkbox name="tocAgreement">
                        <FormattedMessage
                            id="review-prompt.form.personalInfo.tocAgreement"
                            values={{
                                link: chunks => (
                                    <Link
                                        type="native"
                                        href={config.links.ipadGiveawayTermsAndConditions}
                                        color="foregroundsSecondary"
                                    >
                                        {chunks}
                                    </Link>
                                ),
                            }}
                        />
                    </Checkbox>
                </FormField>
            </div>
            <div className={css(felaRules.buttonsContainer)}>
                <FormButtons onClose={onClose} />
            </div>
        </Form>
    );
};
