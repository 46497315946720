import { combineRules } from 'fela';
import { useFela } from 'react-fela';

import { Heading } from 'modules/ui';

import type { Secret } from '../../types';

import * as felaRules from './SecretNameWithIcon.rules';
import { SecretTypeIcon } from '../SecretTypeIcon';
import { WebsiteFavicon } from '../WebsiteFavicon';

export interface SecretNameProps {
    secret: Secret;
    customStyle?: typeof felaRules.heading;
}

export const SecretNameWithIcon = ({ secret, customStyle }: SecretNameProps) => {
    const { css } = useFela();

    const favicon = 'favicon' in secret && secret.favicon ? secret.favicon : null;

    return (
        <div className={css(felaRules.container)}>
            {favicon ? (
                <WebsiteFavicon base64Favicon={favicon} name={secret.name} width={28} height={28} />
            ) : (
                <SecretTypeIcon type={secret.type} width={28} height={28} />
            )}
            <Heading
                size="big"
                elementType="h1"
                color="foregroundsPrimary"
                customStyle={customStyle ? combineRules(felaRules.heading, customStyle) : felaRules.heading}
            >
                {secret.name}
            </Heading>
        </div>
    );
};
