import { useDispatch } from 'react-redux';
import type { PropsWithChildren } from 'react';

import { Form, handleFormSubmit, useTranslateSubmitError } from 'modules/forms';

import { createSecret } from '../../services/actions';
import { secretFormSchema, type SecretFormSchemaType, type SecretFormValues } from '../../schemas';
import { passwordDefaultValues } from '../../constants/defaultValues';

export interface NewSecretFormProviderProps extends PropsWithChildren {}

export const NewSecretFormProvider = ({ children }: NewSecretFormProviderProps) => {
    const dispatch = useDispatch();

    const translateSubmitError = useTranslateSubmitError<SecretFormValues>();

    return (
        <Form<SecretFormSchemaType, SecretFormValues>
            defaultValues={passwordDefaultValues}
            schema={secretFormSchema}
            onSubmit={async (values, { setError }) => {
                const error = await handleFormSubmit<SecretFormValues>((values, actions) => {
                    dispatch(createSecret(values, actions));
                })(values);

                if (error) {
                    const translatedError = translateSubmitError(...error);
                    setError(...translatedError);
                }
            }}
        >
            {children}
        </Form>
    );
};
