import { useFela } from 'react-fela';
import classNames from 'classnames';

import { Input } from 'antd';
import type { TextAreaProps } from 'antd/es/input';

import { Controller, useFormContext } from 'react-hook-form';

import { style } from '../TextInput/TextInput.rules';

export interface TextareaInputProps extends TextAreaProps {
    customStyle?: typeof style;
}

export const TextareaInput = ({ customStyle, name, onChange, onBlur, ...props }: TextareaInputProps) => {
    const { css } = useFela();

    const context = useFormContext();

    if (!context || !name) {
        return (
            <Input.TextArea
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                {...props}
                className={classNames(css(style, customStyle), props.className)}
            />
        );
    }

    return (
        <Controller
            name={name}
            render={({ field: { onChange: hookFormOnChange, onBlur: hookFormOnBlur, value } }) => (
                <Input.TextArea
                    name={name}
                    value={value}
                    onChange={e => {
                        hookFormOnChange(e);

                        onChange?.(e);
                    }}
                    onBlur={e => {
                        hookFormOnBlur();

                        onBlur?.(e);
                    }}
                    {...props}
                    className={classNames(css(style, customStyle), props.className)}
                />
            )}
        />
    );
};
