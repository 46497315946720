import { FormattedMessage } from 'react-intl';

import { hasReadPermission } from 'utilities/permission';

import { Fields, SecretType } from '../../../../../../constants';
import type { Secret } from '../../../../../../types';
import { CopySection } from '../CopySection';

export interface SecureNoteSectionsProps {
    secret: Extract<Secret, { type: SecretType.SecureNote }>;
}

export const SecureNoteSections = ({ secret }: SecureNoteSectionsProps) => {
    const { sensitiveFields } = secret;

    const hasRead = hasReadPermission(secret.userPermissions);

    return (
        <>
            <CopySection
                visible={sensitiveFields?.includes(Fields.SECURE_NOTE)}
                headingMessageKey="secret.secureNote.label"
                secret={secret}
                property={Fields.SECURE_NOTE}
                mask={<FormattedMessage id="crypted.password" />}
                disabled={!hasRead}
            />
        </>
    );
};
