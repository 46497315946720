import { init as _init } from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';

import config from 'config';

import { IS_DEMO, IS_SAAS, IS_DEVELOPMENT_NODE_ENV } from 'constants/index';

// Must be synchronized with config/transformWebpackConfig.js
const release = `passwd-${IS_DEMO ? 'demo' : IS_SAAS ? 'saas' : 'app'}@${process.env.REACT_APP_VERSION}`;

const init = () =>
    _init({
        dsn: config.sentry.dsn,
        debug: IS_DEVELOPMENT_NODE_ENV,
        enabled: process.env.REACT_APP_IS_SENTRY_ENABLED === 'true' && !Boolean(window.Cypress),
        environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || 'production',
        release,
        integrations: [new BrowserTracing()],
    });

export const initSentry = () => {
    window.requestIdleCallback ? window.requestIdleCallback(init) : init();
};
