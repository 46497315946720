import type { TRuleWithTheme } from 'styles/theme';

export const inputsContainer: TRuleWithTheme = () => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1.25rem',
});

export const buttonsContainer: TRuleWithTheme = () => ({
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    columnGap: '1.25rem',
    rowGap: '0.5rem',

    marginTop: '2rem',
});

export const heading: TRuleWithTheme = () => ({
    paddingBottom: '1.25rem',
});
