import jwtDecode from 'jwt-decode';
import { put } from 'redux-saga/effects';
import type { PetrusConfig } from '@ackee/petrus';

import { config } from 'config/config';
import { logger } from 'config/logger';
import { publicApi } from 'config/antonio';

import { ErrorCode, isApiAntonioError } from 'services/utilities/isApiError';
import { actions as messagesActions } from 'modules/messages';
import { actions as modalActions, StaticModal } from 'modules/modals';

import type {
    GoogleIdToken,
    DemoSearchParams,
    SearchParams,
    AccessTokenResponse,
    DemoAccessTokenResponse,
} from '../../types';
import { defaultDemoUserId } from '../../config';

import { forwardParamsToExtension } from '../helpers/forwardParamsToExtension';

import { isDemoSearchParams, parseState } from '../utils';
import type { UTMParamsTuple } from '../utils/utm';
import { getUtmParams } from '../utils/utm';

export const errorMessageIdMap = {
    [ErrorCode.FIRST_LOGIN_BY_ADMIN]: 'error.auth.first-login',
    [ErrorCode.MISSING_CLIENT_SECRET]: 'error.auth.client-secret',
    [ErrorCode.ACCOUNT_NOT_IN_WORKSPACE]: 'error.auth.account-not-in-workspace',
};

export type ForceReturnType = {
    accessToken: string;
    expiresIn?: number | string;
    refreshToken?: string;
};

export interface OauthCallbackResponse extends AccessTokenResponse {
    isFirstLoginSaas?: string;
}

// @ts-expect-error
export const fetchAccessToken: PetrusConfig['oAuth']['fetchAccessToken'] = function* fetchAccessToken(
    params: SearchParams | DemoSearchParams,
) {
    if (isDemoSearchParams(params)) {
        const idToken = jwtDecode<GoogleIdToken>(params.idToken);

        const payload: {
            email: string;
            utmParams?: UTMParamsTuple;
        } = { email: idToken.email };

        const utmParams = getUtmParams();

        if (utmParams) {
            payload.utmParams = utmParams;
        }

        try {
            yield* publicApi.post(config.api.demoUsers, payload, {
                baseURL: config.api.base,
            });
        } catch (e) {
            logger.error(e);
        }

        const fetchTokenResponse: DemoAccessTokenResponse = {
            userId: defaultDemoUserId,
            googleUser: {
                name: idToken.name,
                email: idToken.email,
            },
        };
        // TODO: remove this when Petrus supports customizing return type of fetchAccessToken
        return fetchTokenResponse as unknown as ForceReturnType;
    }

    try {
        const state = parseState(params.state);

        if (state.extensionId) {
            yield forwardParamsToExtension(params, state);
        } else {
            const payload: {
                utmParams?: UTMParamsTuple;
            } = {};

            const utmParams = getUtmParams();

            if (utmParams) {
                payload.utmParams = utmParams;
            }

            const authResponse = yield* publicApi.post<OauthCallbackResponse>(config.api.oauthCallback, payload, {
                baseURL: config.api.base,
                params: {
                    code: params.code,
                },
                headers: { 'x-islocalhost': config.isLocalhost ? 'true' : 'false' },
            });

            if (authResponse.data.isFirstLoginSaas) {
                yield put(modalActions.setModalVisibility(StaticModal.SUBSCRIPTION_UPGRADE, true));
            }

            return authResponse.data;
        }
    } catch (e) {
        if (isApiAntonioError(e)) {
            const errorMessageId = errorMessageIdMap[e.data.errorCode];

            if (errorMessageId) {
                yield put(
                    messagesActions.displayErrorMessage({
                        message: { id: errorMessageId },
                        options: {
                            duration: 20,
                        },
                    }),
                );
            }
        }

        logger.error(e);

        throw e;
    }
};
