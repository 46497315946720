import { Modal } from 'modules/modals';

import { useReviewPrompt } from '../../hooks';
import { ReviewPromptSteps } from '../ReviewPromptSteps';

export const ReviewPromptModal = () => {
    const { modal } = useReviewPrompt();

    return (
        <Modal open={modal?.visibility} width={520}>
            <ReviewPromptSteps />
        </Modal>
    );
};
