import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/useAppSelector';
import { useSyncRef } from 'hooks/useSyncRef';

import { selectModal } from '../services/selectors';
import { setModalVisibility } from '../services/actions';

export function useModal<Data extends {}>(modalKey: string, { autoClose }: { autoClose?: boolean } = {}) {
    const dispatch = useDispatch();

    const modal = useAppSelector(state => selectModal(state, { modal: modalKey })) as
        | {
              visibility: boolean;
              data?: Data;
          }
        | undefined;

    const updateModal = useCallback(
        (visibility: boolean, data?: Data) => {
            dispatch(setModalVisibility(modalKey, visibility, data));
        },
        [modalKey, dispatch],
    );

    const updateModalRef = useSyncRef(updateModal);
    const autocloseRef = useSyncRef(autoClose);

    useEffect(
        () => () => {
            if (autocloseRef.current) {
                updateModalRef.current(false);
            }
        },
        [autocloseRef, updateModalRef],
    );

    return {
        modal,
        updateModal,
    };
}
