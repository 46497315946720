import { FormattedMessage } from 'react-intl';
import { useController } from 'react-hook-form';

import { AccessRole } from 'types';

import { RadioButtonGroup, SettingsFormItem } from 'modules/forms';

import { Fields } from '../../constants';

export interface DefaultPermissionFieldProps {
    onChange: () => void;
}

const options = [AccessRole.VIEWER, AccessRole.EDITOR, AccessRole.VIEWER_AUTOFILL_ONLY];

const name = Fields.DEFAULT_PERMISSION;
export const DefaultPermissionField = ({ onChange }: DefaultPermissionFieldProps) => {
    const {
        field: { ref, ...field },
    } = useController({ name });

    return (
        <SettingsFormItem name={name} label={<FormattedMessage id="settings.form.defaultPermission.label" />}>
            <RadioButtonGroup
                {...field}
                onChange={e => {
                    field.onChange(e.target.value);
                    onChange();
                }}
                optionType="button"
                options={options.map(value => ({
                    label: <FormattedMessage id={`settings.form.defaultPermission.${value}`} />,
                    value,
                }))}
            />
        </SettingsFormItem>
    );
};
