import { useAppSelector } from 'hooks/useAppSelector';
import { useIsFreeTier } from 'modules/settings';

import { MAX_SAAS_SECRETS_COUNT, WARNING_SAAS_SECRETS_COUNT } from '../constants';

import { selectSecretsCount } from '../services/selectors';

export const useSaasCountReached = () => {
    const count = useAppSelector(selectSecretsCount);
    const isFreeTier = useIsFreeTier();

    return {
        count,
        isWarning: isFreeTier && count >= WARNING_SAAS_SECRETS_COUNT,
        isError: isFreeTier && count >= MAX_SAAS_SECRETS_COUNT,
    };
};
