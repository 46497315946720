import type { Secret } from '../../../../../types';
import { SecretType } from '../../../../../constants';

import { PasswordSections } from './PasswordSections';
import { DatabaseCredentialsSections } from './DatabaseCredentialsSections';
import { PaymentCardSections } from './PaymentCardSections';
import { ApiCredentialsSections } from './ApiCredentialsSections';
import { SshKeySections } from './SshKeySections';
import { SecureNoteSections } from './SecureNoteSections';

export interface CopySecretValueSectionsProps {
    secret: Secret;
}

export const CopySecretValueSections = ({ secret }: CopySecretValueSectionsProps) => {
    switch (secret.type) {
        case SecretType.Password:
            return <PasswordSections secret={secret} />;
        case SecretType.PaymentCard:
            return <PaymentCardSections secret={secret} />;
        case SecretType.ApiCredentials:
            return <ApiCredentialsSections secret={secret} />;
        case SecretType.SshKey:
            return <SshKeySections secret={secret} />;
        case SecretType.DatabaseCredentials:
            return <DatabaseCredentialsSections secret={secret} />;
        case SecretType.SecureNote:
            return <SecureNoteSections secret={secret} />;
        default:
            return null;
    }
};
