import { eventChannel } from 'redux-saga';
import { fork, take } from 'redux-saga/effects';

import { logger } from 'config/logger';

import { IS_PRODUCTION_NODE_ENV } from 'constants/index';

import { promptInstal, promptUpdate } from '../../utils/messages';

import { updateChecker } from './updateChecker';

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

const documentLoaded = new Promise<void>(resolve => window.addEventListener('load', () => resolve()));

const installingWorkerStateEmitter = (registration: ServiceWorkerRegistration) =>
    eventChannel<ServiceWorkerState>(emitter => {
        registration.onupdatefound = () => {
            const installingWorker = registration.installing;

            if (installingWorker === null) {
                return;
            }

            installingWorker.onstatechange = () => {
                emitter(installingWorker.state);
            };
        };
        return () => {};
    });

function* registerValidSW(swUrl: string) {
    const { serviceWorker } = navigator;

    try {
        const registration: ServiceWorkerRegistration = yield serviceWorker.register(swUrl);

        if (serviceWorker.controller && registration.waiting) {
            // We can have some waiting worker in a background
            // this case will happen, when we detected new service worker to install, show UI prompt
            // and then user refresh or close the page
            // this will show to ui message again until the user accept the update
            yield* promptUpdate(registration);
        }

        yield fork(updateChecker);

        while (true) {
            // installState will fire when new service worker wants to be installed
            // then installState will change everytime the progress of installating new service worker changes
            // when installState of service worker became installed, there is two scenarious possible
            // 1. it is first time, when service worker is being installed - no problem
            // 2. our new service worker stucks at WAITING phase, because there is other SW which is active
            // we then let user decide to update or not, if so then we will send message to currently waiting
            // service worker to skipWaiting - which means that the SW became active
            // because all other instances are listening for installlatin progress and they observe
            // that new service worker became active, page will reload.
            const installState: ServiceWorkerState = yield take(installingWorkerStateEmitter(registration));

            if (installState === 'installed') {
                if (serviceWorker.controller) {
                    yield* promptUpdate(registration);
                } else {
                    yield* promptInstal();
                }
            }
        }
    } catch (e) {
        logger.error(e);
    }
}

function* checkValidServiceWorker(swUrl: string) {
    const { serviceWorker } = navigator;

    // Check if the service worker can be found. If it can't reload the page.
    try {
        const response = yield fetch(swUrl);

        const contentType = response.headers.get('content-type');

        if (response.status === 404 || (contentType != null && contentType.indexOf('javascript') === -1)) {
            // No service worker found. Probably a different app. Reload the page.
            const registration: ServiceWorkerRegistration = yield serviceWorker.ready;
            yield registration.unregister();
            window.location.reload();
        } else {
            // Service worker found. Proceed as normal.
            yield registerValidSW(swUrl);
        }
    } catch (e) {
        logger.error(e);

        logger.info('No internet connection found. App is running in offline mode.');
    }
}

export function reloadWhenSwUpdates() {
    // eslint-disable-next-line compat/compat
    const sw = navigator.serviceWorker;

    let skipFirstUpdate = sw.controller == null;

    sw.oncontrollerchange = () => {
        if (sw.controller) {
            sw.controller.onstatechange = () => {
                if (sw.controller?.state !== 'activated') {
                    return;
                }

                if (!skipFirstUpdate) {
                    window.location.reload();
                }

                skipFirstUpdate = false;
            };
        }
    };
}

export default function* register() {
    const { serviceWorker } = navigator;

    if (IS_PRODUCTION_NODE_ENV && serviceWorker) {
        // The URL constructor is available in all browsers that support SW.
        const publicUrl = new URL(process.env.PUBLIC_URL ?? '', window.location.href);

        if (publicUrl.origin !== window.location.origin) {
            // Our service worker won't work if PUBLIC_URL is on a different origin
            // from what our page is served on. This might happen if a CDN is used to
            // serve assets; see https://github.com/facebook/create-react-app/issues/2374
            return;
        }

        reloadWhenSwUpdates();

        yield documentLoaded;

        const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

        if (IS_PRODUCTION_NODE_ENV && isLocalhost) {
            // This is running on localhost. Let's check if a service worker still exists or not.
            yield checkValidServiceWorker(swUrl);

            // Add some additional logging to localhost, pointing developers to the
            // service worker/PWA documentation.

            yield serviceWorker.ready;

            logger.info(
                'This web app is being served cache-first by a service ' +
                    'worker. To learn more, visit http://bit.ly/CRA-PWA',
            );
        } else {
            // Is not localhost. Just register service worker
            yield registerValidSW(swUrl);
        }
    }
}
