import { FocusEvent, ReactNode, useLayoutEffect, useRef, useState } from 'react';
import { useFela } from 'react-fela';
import classNames from 'classnames';

import useStyle from 'antd/lib/input/style';

import type { Theme } from 'styles/theme';

import { getPasswordCharColor } from 'modules/ui';

import { restoreSelection, SavedSelection, saveSelection, setSelectionAtTheEnd } from '../../utilities/selection';

import { style } from './PasswordInput.rules';

export interface PasswordInputProps {
    id?: string;
    value?: string;
    onChange?: (value: string | undefined) => void;
    onFocus?: (e: FocusEvent<HTMLSpanElement, Element>) => void;
    onBlur?: (e: FocusEvent<HTMLSpanElement, Element>) => void;
    prefix?: ReactNode;
    suffix?: ReactNode;
    className?: string;
    customStyle?: typeof style;
}

const prefixCls = 'ant-input';

export const PasswordInput = ({
    id,
    value = '',
    onChange,
    onFocus,
    onBlur,
    prefix,
    suffix,
    customStyle,
    className,
}: PasswordInputProps) => {
    const [wrapSSR, hashId] = useStyle(prefixCls);

    const { theme, css } = useFela<Theme>();
    const containerRef = useRef<HTMLSpanElement>(null);

    const [focused, setFocused] = useState(false);

    const [innerValue, setInnerValue] = useState<string | undefined>(value);

    useLayoutEffect(() => {
        setInnerValue(value);
    }, [value]);

    useLayoutEffect(() => {
        let selection: SavedSelection | null = null;

        if (document.activeElement === containerRef.current) {
            selection = saveSelection(containerRef.current);
        }

        if (containerRef.current) containerRef.current.innerHTML = '';

        [...(innerValue ?? '')].forEach(char => {
            const element = document.createElement('span');

            element.style.color = getPasswordCharColor(char, theme);
            element.innerText = char;

            if (containerRef.current) containerRef.current.appendChild(element);
        });

        if (selection && containerRef.current) {
            restoreSelection(containerRef.current, selection);
        }
    }, [innerValue, theme]);

    const felaClassName = css(style, customStyle);

    let input = (
        <span
            id={id}
            className={classNames('ant-input', hashId, {
                [felaClassName]: !suffix && !prefix,
                [className ?? '']: !suffix && !prefix,
            })}
            ref={containerRef}
            contentEditable
            autoCorrect="false"
            onInput={() => {
                setInnerValue(containerRef.current?.innerText);
                onChange?.(containerRef.current?.innerText);
            }}
            onKeyDown={e => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                }
            }}
            onFocus={e => {
                setFocused(true);
                setSelectionAtTheEnd(containerRef.current);
                onFocus?.(e);
            }}
            onBlur={e => {
                setFocused(false);
                setInnerValue(containerRef.current?.innerText);
                onBlur?.(e);
            }}
        />
    );

    if (suffix) {
        input = (
            <span
                className={classNames(
                    {
                        [`${prefixCls}-affix-wrapper`]: true,
                        [`${prefixCls}-affix-wrapper-focused`]: focused,
                    },
                    hashId,
                    felaClassName,
                    className,
                )}
            >
                {prefix ? (
                    <span
                        className={classNames(hashId, {
                            [`${prefixCls}-prefix`]: true,
                        })}
                    >
                        {prefix}
                    </span>
                ) : null}
                {input}
                {suffix ? (
                    <span
                        className={classNames(hashId, {
                            [`${prefixCls}-suffix`]: true,
                        })}
                    >
                        {suffix}
                    </span>
                ) : null}
            </span>
        );
    }

    return wrapSSR(input);
};
