import { FormattedMessage } from 'react-intl';
import { useFela } from 'react-fela';
import { useNavigate } from 'react-router-dom-v5-compat';

import { useIsAdmin } from 'modules/auth';
import { Button, Icon } from 'modules/ui';
import { useTidio } from 'modules/chat/hooks';
import config from 'config';

import { SubscriptionType } from '../../../constants';

import { SubscriptionAlertModal } from '../SubscriptionAlertModal';

import * as felaRules from '../SubscriptionFirewall.rules';

export const NotLinkedAlert = () => {
    const { css } = useFela();
    const navigate = useNavigate();
    const isAdmin = useIsAdmin();
    const { show: showChat } = useTidio();

    const subscription = SubscriptionType.NOT_LINKED;

    return (
        <SubscriptionAlertModal
            title={{ id: `settings.subscription.${subscription}.alert.title` }}
            text={{
                id: isAdmin
                    ? `settings.subscription.${subscription}.alert.adminText`
                    : `settings.subscription.${subscription}.alert.text`,
            }}
            endContent={
                <div>
                    <div className={css(felaRules.buttons)}>
                        {isAdmin && (
                            <Button
                                type="primary"
                                icon={<Icon type="chat" width="22" height="22" customStyle={felaRules.icon} />}
                                onClick={showChat}
                            >
                                <span>
                                    <FormattedMessage id={`settings.subscription.${subscription}.alert.chat`} />
                                </span>
                            </Button>
                        )}
                        <Button
                            icon={<Icon type="logout" width="16" height="16" customStyle={felaRules.icon} />}
                            onClick={() => {
                                navigate(config.routes.logout);
                            }}
                        >
                            <span>
                                <FormattedMessage id={`settings.subscription.${subscription}.alert.logout`} />
                            </span>
                        </Button>
                    </div>
                </div>
            }
        />
    );
};
