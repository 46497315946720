import { put, select, all } from 'redux-saga/effects';

import { api } from 'config/antonio';
import { config } from 'config/config';
import { logger } from 'config/logger';

import { takeLatestRequest } from 'services/utilities/takeRequest';

import { actions as messagesActions } from 'modules/messages';

import type { Group } from '../../types';

import {
    fetchGroups,
    fetchGroupsSuccess,
    fetchGroupsRequest,
    fetchGroupsFailure,
    fetchGroupsNext,
    fetchGroupsCancel,
} from '../actions';
import { normalizeGroups } from '../normalizers';
import { selectGroupsApi } from '../selectors';

function* handler(action: ReturnType<typeof fetchGroups> | ReturnType<typeof fetchGroupsNext>, signal: AbortSignal) {
    const apiState: ReturnType<typeof selectGroupsApi> = yield select(selectGroupsApi);

    const isNext = action.type === fetchGroupsNext.toString();

    if (isNext && !apiState.nextPageToken) {
        return;
    }

    const params: Record<string, any> = {};

    if (action.meta.filter) {
        params.query = action.meta.filter;
    }

    if (isNext) {
        params.nextPageToken = apiState.nextPageToken;
    }

    yield put(fetchGroupsRequest(isNext));

    try {
        const { data } = yield* api.get<{
            data: Group[];
            metadata: {
                nextPageToken?: string;
            };
        }>(config.api.groupsQuery, {
            params,
            signal,
        });

        const { entities, result } = normalizeGroups(data.data ?? []);

        yield put(
            fetchGroupsSuccess(entities.groups, result, {
                filter: action.meta.filter,
                nextPageToken: data.metadata.nextPageToken,
                isNext,
            }),
        );
    } catch (e) {
        yield put(
            messagesActions.displayErrorMessage({
                message: { id: 'error.groups.fetch' },
            }),
        );
        logger.error(e);

        yield put(fetchGroupsFailure((e as Error).message, isNext));
    }
}

export function* fetchGroupsSaga() {
    yield all([
        yield takeLatestRequest(
            {
                actionCreator: fetchGroups,
                cancelActionFunction: () => fetchGroupsCancel(),
                idSelector: () => 'groups',
            },
            handler,
        ),
        yield takeLatestRequest(
            {
                actionCreator: fetchGroupsNext,
                cancelActionFunction: () => fetchGroupsCancel(true),
                idSelector: () => 'groups',
            },
            handler,
        ),
    ]);
}
