import { Navigate } from 'react-router-dom-v5-compat';
import { useFela } from 'react-fela';

import { config } from 'config/config';

import { AdminFirewall, NonDemoFirewall } from 'modules/auth';

import { Import } from '../Import';
import { Export } from '../Export';

import * as felaRules from './ImportExportPage.rules';

export const ImportExportPage = () => {
    const { css } = useFela();
    return (
        <NonDemoFirewall>
            <div className={css(felaRules.container)}>
                <AdminFirewall firewall={<Navigate to={config.routes.home} />}>
                    <Export />
                    <Import />
                </AdminFirewall>
            </div>
        </NonDemoFirewall>
    );
};
