import { FormattedMessage } from 'react-intl';

import { hasReadPermission } from 'utilities/permission';
import { isEmptyOrUndefined } from 'utilities/string';
import { CopyButton, Text } from 'modules/ui';

import { SecretFieldSection } from '../../SecretFieldSection';
import type { Secret } from '../../../types';
import { SecretType } from '../../../constants';
import { EmptyContentGuard } from '../EmptyContentGuard';

export interface DatabaseCredentialsSectionsProps {
    secret: Extract<Secret, { type: SecretType.DatabaseCredentials }>;
}

export const DatabaseCredentialsSections = ({ secret }: DatabaseCredentialsSectionsProps) => {
    const { userPermissions, databaseName, username, password, server, port, databaseType } = secret;

    const hasRead = hasReadPermission(userPermissions);

    return (
        <EmptyContentGuard>
            {!isEmptyOrUndefined(databaseName) && (
                <SecretFieldSection heading={<FormattedMessage id="secret.databaseName.label" />}>
                    <Text>{databaseName}</Text>
                </SecretFieldSection>
            )}

            {!isEmptyOrUndefined(username) && (
                <SecretFieldSection heading={<FormattedMessage id="secret.username.label" />}>
                    <CopyButton text={username} />
                </SecretFieldSection>
            )}

            {!isEmptyOrUndefined(password) && (
                <SecretFieldSection heading={<FormattedMessage id="secret.password.label" />}>
                    <CopyButton type="password" text={password} disabled={!hasRead} />
                </SecretFieldSection>
            )}

            {!isEmptyOrUndefined(server) && (
                <SecretFieldSection heading={<FormattedMessage id="secret.server.label" />}>
                    <Text>{server}</Text>
                </SecretFieldSection>
            )}

            {!isEmptyOrUndefined(port) && (
                <SecretFieldSection heading={<FormattedMessage id="secret.port.label" />}>
                    <Text>{port}</Text>
                </SecretFieldSection>
            )}
            {!isEmptyOrUndefined(databaseType) && (
                <SecretFieldSection heading={<FormattedMessage id="secret.databaseType.label" />}>
                    <Text>{databaseType}</Text>
                </SecretFieldSection>
            )}
        </EmptyContentGuard>
    );
};
