import { FormattedMessage } from 'react-intl';
import { useMatch } from 'react-router-dom-v5-compat';

import { hasReadPermission } from 'utilities/permission';
import { isEmptyOrUndefined } from 'utilities/string';
import { CopyButton, PasswordVisualisation } from 'modules/ui';
import config from 'config';

import { SecretFieldSection } from '../../SecretFieldSection';
import { SecretUrl } from '../../SecretUrl';
import { TOTP } from '../../TOTP';

import type { PasswordTypeSecret } from '../../../types';
import { EmptyContentGuard } from '../EmptyContentGuard';

export interface PasswordSectionsProps {
    secret: PasswordTypeSecret;
}

export const PasswordSections = ({ secret }: PasswordSectionsProps) => {
    const { web, username, id, userPermissions, password, hasTOTP } = secret;

    const hasRead = hasReadPermission(userPermissions);
    const isPublicSharingPage = useMatch(config.routes.share);

    return (
        <EmptyContentGuard>
            {!isEmptyOrUndefined(web) && (
                <SecretFieldSection heading={<FormattedMessage id="secret.web" />}>
                    <CopyButton text={web}>
                        <SecretUrl web={web} />
                    </CopyButton>
                </SecretFieldSection>
            )}

            {!isEmptyOrUndefined(username) && (
                <SecretFieldSection heading={<FormattedMessage id="secret.username" />}>
                    <CopyButton text={username} />
                </SecretFieldSection>
            )}

            {!isEmptyOrUndefined(password) && (
                <SecretFieldSection heading={<FormattedMessage id="secret.password" />}>
                    <CopyButton disabled={!hasRead} type="password" text={password}>
                        <PasswordVisualisation password={password} />
                    </CopyButton>
                </SecretFieldSection>
            )}

            {hasTOTP && (
                <SecretFieldSection heading={<FormattedMessage id="secret.totp" />}>
                    <TOTP id={id} isPubliclyShared={Boolean(isPublicSharingPage)} />
                </SecretFieldSection>
            )}
        </EmptyContentGuard>
    );
};
