import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import config from 'config';

import { saasCheckoutActions } from '../../../services/actions';

const origin = new URL(config.embeds.pricing).origin;
const EVENT_TYPE = 'upgrade-button-click';

interface IframeMessagePayload {
    eventType: string;
    payload?: Record<string, any>;
}

export const useReceiveIframeMessages = () => {
    const dispatch = useDispatch();

    function onReceivedMessage(event: MessageEvent) {
        if (event.origin !== origin && !event.origin.startsWith('http://localhost')) {
            return;
        }

        const eventData = event.data as IframeMessagePayload;

        if (eventData.eventType === EVENT_TYPE) {
            dispatch(saasCheckoutActions.request({ couponId: eventData.payload?.coupon }));
        }
    }

    useEffect(() => {
        window.addEventListener('message', onReceivedMessage);

        return () => {
            window.removeEventListener('message', onReceivedMessage);
        };
    });
};
