import type { TRuleWithTheme } from 'styles/theme';

export const form: TRuleWithTheme = () => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

export const title: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
});

export const description: TRuleWithTheme = () => ({
    marginTop: '0.125rem',
});

export const freeTierSwitchButton: TRuleWithTheme = ({ theme }) => ({
    '&.ant-switch': {
        cursor: 'not-allowed',
        opacity: 0.3,
    },
});
