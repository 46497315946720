import { createAction } from '@reduxjs/toolkit';

import type { ImportFormValues } from '../../schemas';

import types from '../actionTypes';

export const importValidationRequest = createAction(types.IMPORT_VALIDATION_REQUEST, (values: ImportFormValues) => ({
    payload: values,
}));

export const importValidationRequestSuccess = createAction(types.IMPORT_VALIDATION_REQUEST_SUCCESS);
export const importValidationRequestFailure = createAction(
    types.IMPORT_VALIDATION_REQUEST_FAILURE,
    (isCsvValidationError: boolean = false) => ({
        payload: undefined,
        meta: {
            isCsvValidationError,
        },
    }),
);
export const importValidationRequestCancel = createAction(types.IMPORT_VALIDATION_REQUEST_CANCEL);
