/**
 * Cookie handling from official Trackdesk documentation
 * @link https://help.trackdesk.com/en/articles/6867895-stripe-integration-via-payment-links#h_3f22230f16
 */
export const getTrackdeskCid = () => {
    const cookie = document.cookie.match('(^|;)\\s*trakdesk_cid\\s*=\\s*([^;]+)');

    if (Array.isArray(cookie)) {
        try {
            const cookieValue = cookie.pop();

            if (cookieValue) {
                const trakdeskCid = JSON.parse(cookieValue);
                const cid: string = trakdeskCid['cid'];

                return cid;
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
    }

    return null;
};
