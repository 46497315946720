import { forwardRef } from 'react';
import { combineRules } from 'fela';
import { useFela } from 'react-fela';
import type { InputRef } from 'antd/es/input';

import { Icon } from 'modules/ui';

import { TextInput } from '../TextInput';
import type { TextInputProps } from '../TextInput';

import * as felaRules from './SearchInput.rules';

export const SearchInput = forwardRef<
    InputRef,
    TextInputProps & {
        showClear?: boolean;
        onClear?: () => void;
    }
>(({ customStyle, showClear, onClear, ...props }, ref) => {
    const { css } = useFela();

    return (
        <TextInput
            ref={ref}
            {...props}
            // @ts-expect-error
            customStyle={combineRules(felaRules.input, customStyle)}
            suffix={
                showClear ? (
                    <button
                        type="button"
                        onClick={() => {
                            onClear?.();
                        }}
                        className={css(felaRules.clear)}
                    >
                        <Icon type="close" color="brownGrey2" />
                    </button>
                ) : (
                    <Icon type="search" color="brownGrey2" />
                )
            }
        />
    );
});
