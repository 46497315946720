import { useContext } from 'react';

import { FormSubmitContext } from '../context';

export const useFormSubmit = () => {
    const context = useContext(FormSubmitContext);

    if (!context) {
        throw new Error('useFormSubmit must be used within a FormSubmitProvider');
    }

    return context;
};
