import { FormattedMessage } from 'react-intl';
import { useMatch } from 'react-router-dom-v5-compat';
import { useFela } from 'react-fela';

import { config } from 'config/config';
import { IS_DEMO, IS_SAAS } from 'constants/index';

import { MenuItem, MenuDivider } from 'modules/layout';
import { Link } from 'modules/ui';
import type { IconProps } from 'modules/ui';
import { LanguageSwitcher } from 'modules/localizations';
import { AdminFirewall, DemoUsersSwitcher } from 'modules/auth';
import { useHasSecurityAuditReport } from 'modules/secrets';
import { DemoNotice } from 'modules/demo';
import { Version } from 'modules/backend-version';

import { TagsFilter } from 'modules/secrets/modules/tags';

import { useWhatIsNewVisible } from '../../hooks/useWhatIsNewVisible';

import * as felaRules from './SidebarContent.rules';

const nativeLinkIcon: IconProps = {
    type: 'link',
    color: 'brownGrey',
};

const reportIcon: IconProps = {
    type: 'warning-inverse',
    color: 'red',
};

export const SidebarContent = () => {
    const { css } = useFela();

    const matchSecrets = useMatch({
        path: config.routes.secrets,
    });

    const whatIsNewVisible = useWhatIsNewVisible();
    const hasReport = useHasSecurityAuditReport();

    return (
        <div className={css(felaRules.container)}>
            {config.demo.enable && <DemoUsersSwitcher />}
            {config.demo.enable && <DemoNotice />}
            <MenuItem
                icon="lock"
                link={{
                    type: 'router',

                    to: config.routes.secrets,
                }}
            >
                <FormattedMessage id="menu.secrets" />
            </MenuItem>
            <AdminFirewall loader={null}>
                <MenuItem
                    icon="security-audit"
                    informationIcon={hasReport ? reportIcon : undefined}
                    additionalRoutesWithActiveClassName={[
                        config.routes.securityAuditAge,
                        config.routes.securityAuditLeaks,
                        config.routes.securityAuditLevel,
                    ]}
                    link={{
                        type: 'router',
                        to: config.routes.securityAudit,
                    }}
                >
                    <FormattedMessage id="menu.security-audit" />
                </MenuItem>
            </AdminFirewall>
            <AdminFirewall loader={null}>
                <MenuItem
                    icon="export"
                    link={{
                        type: 'router',
                        to: config.routes.importExport,
                    }}
                >
                    <FormattedMessage id="menu.import-export" />
                </MenuItem>
                <MenuItem
                    icon="settings"
                    link={{
                        type: 'router',
                        to: config.routes.settings,
                    }}
                >
                    <FormattedMessage id="menu.settings" />
                </MenuItem>
                {!(IS_DEMO || IS_SAAS) ? (
                    <MenuItem
                        icon="guide"
                        informationIcon={nativeLinkIcon}
                        link={{
                            type: 'native',
                            href: config.links.guide,
                        }}
                    >
                        <FormattedMessage id="menu.guide" />
                    </MenuItem>
                ) : null}
            </AdminFirewall>
            {whatIsNewVisible && !IS_DEMO ? (
                <MenuItem
                    icon="news"
                    informationIcon={nativeLinkIcon}
                    link={{
                        type: 'native',
                        href: config.links.whatIsNew,
                    }}
                >
                    <FormattedMessage id="menu.new" />
                </MenuItem>
            ) : null}
            <MenuItem
                icon="faq"
                informationIcon={nativeLinkIcon}
                link={{
                    type: 'native',
                    href: config.links.faq,
                }}
            >
                <FormattedMessage id="menu.faq" />
            </MenuItem>
            <MenuItem
                icon="logout"
                link={{
                    type: 'router',
                    to: config.routes.logout,
                }}
            >
                <FormattedMessage id="menu.logout" />
            </MenuItem>

            {matchSecrets ? (
                <>
                    <MenuDivider />
                    <TagsFilter />
                </>
            ) : null}

            <MenuDivider />
            <LanguageSwitcher />

            <MenuDivider />
            <Version />
            <Link type="router" to={config.routes.changelog} size="small" color="blue" customStyle={felaRules.link}>
                <FormattedMessage id="menu.changelog" />
            </Link>
        </div>
    );
};
