import config from 'config';

export interface ITagManager {
    initialize: () => void;
    push: (event: string, data?: Record<string, any>) => void;
}

export async function gtmInterface(): Promise<ITagManager> {
    const TagManager = await import('react-gtm-module');

    return {
        initialize: () =>
            TagManager.initialize({
                gtmId: config.googleTagManager.trackingCode,
            }),
        push: (event, data) => {
            window.dataLayer = window.dataLayer ?? [];

            window.dataLayer.push({
                event: event,
                ...data,
            });
        },
    };
}
