import { FormattedMessage } from 'react-intl';

import { Label, TextareaInput, FormField } from 'modules/forms';

import { Fields } from '../../../constants';

export interface NoteFieldProps {
    onChange?: () => void;
}

export const NoteField = ({ onChange }: NoteFieldProps) => {
    const name = Fields.NOTE;

    const label = (
        <Label>
            <FormattedMessage id={`secret.${name}.label`} />
        </Label>
    );

    return (
        <FormField name={name} label={label} htmlFor={name}>
            <TextareaInput name={name} id={name} rows={5} />
        </FormField>
    );
};
