import { useFela } from 'react-fela';

import { Checkbox as AntdCheckbox } from 'antd';
import type { CheckboxProps as AntdCheckboxProps } from 'antd/es/checkbox';

import { Controller, useFormContext } from 'react-hook-form';

import { style } from './Checkbox.rules';

export interface CheckboxProps extends AntdCheckboxProps {
    customStyle?: typeof style;
}

export function Checkbox({ customStyle, name, onChange, ...props }: CheckboxProps) {
    const { css } = useFela();

    const context = useFormContext();

    if (!context || !name) {
        return <AntdCheckbox {...props} className={css(style, customStyle)} />;
    }

    return (
        <Controller
            name={name}
            render={({ field: { onChange: hookFormOnChange, value } }) => (
                <AntdCheckbox
                    name={name}
                    checked={value}
                    onChange={e => {
                        hookFormOnChange(e);

                        onChange?.(e);
                    }}
                    {...props}
                    className={css(style, customStyle)}
                />
            )}
        />
    );
}
