import { uniqueId } from 'lodash';
import { takeLeading } from 'redux-saga/effects';

import { IS_DEMO } from 'constants/index';

import { config } from 'config/config';

import { login } from '../actions';

function createGoogleSignInUrl() {
    const url = new URL(config.googleOAuth.origin);

    const redirectUrl = new URL(config.googleOAuth.redirectUrl, window.location.href);

    if (!config.googleOAuth.cliendId) {
        throw new Error('Google client ID is not set');
    }

    url.searchParams.set('client_id', config.googleOAuth.cliendId);
    url.searchParams.set('redirect_uri', redirectUrl.toString());
    url.searchParams.set('state', encodeURIComponent(JSON.stringify({ url: window.location.pathname })));
    url.searchParams.set('prompt', 'consent');
    url.searchParams.set(
        'scope',
        'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
    );

    if (IS_DEMO) {
        url.searchParams.set('response_type', 'id_token');
        url.searchParams.set('nonce', uniqueId());
        url.searchParams.set('access_type', 'online');
    } else {
        url.searchParams.set('response_type', 'code');
        url.searchParams.set('access_type', 'offline');
    }

    return url.toString();
}
function handler() {
    window.location.replace(createGoogleSignInUrl());
}

export function* loginSaga() {
    yield takeLeading(login.toString(), handler);
}
