import { useFela } from 'react-fela';

import { Text } from 'modules/ui';

import * as felaRules from './RedactedPaymentCardNumber.rules';
import { formatPaymentCardNumber } from '../../utilities';

export interface RedactedPaymentCardNumberProps {
    redactedNumber?: string;
}

const END_OFFSET = 4;

export const RedactedPaymentCardNumber = ({ redactedNumber }: RedactedPaymentCardNumberProps) => {
    const { css } = useFela();

    const formattedNumber = formatPaymentCardNumber(redactedNumber);

    return (
        <Text size="regular" elementType="span">
            <span className={css(felaRules.crypted)}>{formattedNumber?.slice(0, -END_OFFSET)}</span>
            <span>{formattedNumber?.slice(-END_OFFSET)}</span>
        </Text>
    );
};
