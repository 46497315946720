import { forwardRef, type ReactNode } from 'react';
import { combineRules } from 'fela';

import { useFela } from 'react-fela';

import { Label } from 'modules/forms';
import type { container } from 'modules/forms/components/Label/Label.rules';

import * as felaRules from './SecretFieldSection.rules';

export interface SecretFieldSectionProps {
    heading?: ReactNode;
    children: ReactNode;
    customLabelStyle?: typeof container;
}

export const SecretFieldSection = forwardRef<HTMLDivElement, SecretFieldSectionProps>(
    ({ heading = null, children, customLabelStyle }, ref) => {
        const { css } = useFela();

        return (
            <div ref={ref}>
                {heading && (
                    <Label
                        customStyle={
                            customLabelStyle ? combineRules(felaRules.label, customLabelStyle) : felaRules.label
                        }
                        elementType="h2"
                    >
                        {heading}
                    </Label>
                )}
                <div className={css(felaRules.container)}>{children}</div>
            </div>
        );
    },
);
