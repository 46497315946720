export enum StaticModal {
    EXTENSION = 'extension',
    USER_FEEDBACK = 'user-feedback',
    IMPORT_VALIDATION = 'import-validation',
    IMPORT_LIMIT_REACHED = 'import-limit-reached',
    DELETE_SECRETS = 'delete-secrets',
    REMOVE_TAG = 'remove-tag',
    UPDATE_TAG = 'update-tag',
    VIEW_FILTER = 'view-filter',
    HIDE_GROUP = 'hide-group',
    REVIEW_PROMPT = 'review-prompt',
    SUBSCRIPTION_UPGRADE = 'subscription-upgrade',
}
