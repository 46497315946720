import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/useAppSelector';

import { selectAuditData, selectAuditApi } from '../services/selectors';
import { fetchAudit } from '../services/actions';

export const useFetchAudit = () => {
    const api = useAppSelector(selectAuditApi);
    const data = useAppSelector(selectAuditData);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAudit());
    }, [dispatch]);

    return {
        showLoader: api.inProgress,
        data,
    };
};
