import { z } from 'zod';

import { Fields, SecretType } from '../constants';
import { basicSecretFormSchema } from './basicSecretFormSchema';

export const apiCredentialsFormSchema = basicSecretFormSchema.merge(
    z.object({
        [Fields.TYPE]: z.literal(SecretType.ApiCredentials),
        [Fields.USERNAME]: z.string().optional(),
        [Fields.CREDENTIALS]: z.string().optional(),
        [Fields.HOSTNAME]: z.string().optional(),
    }),
);
