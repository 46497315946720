import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Button } from 'modules/ui';

import type { ReviewPromptFormValues } from '../../../../schema';

export interface FormButtonsProps {
    onClose: () => void;
}

export const FormButtons = ({ onClose }: FormButtonsProps) => {
    const {
        formState: { isSubmitting },
    } = useFormContext<ReviewPromptFormValues>();

    const trustPilotVisited = useWatch<ReviewPromptFormValues>({ name: 'trustpilotVisited' });
    const g2Visited = useWatch<ReviewPromptFormValues>({ name: 'g2Visited' });

    const submitDisabled = !trustPilotVisited && !g2Visited;

    return (
        <>
            <Button type="primary" size="large" htmlType="submit" disabled={submitDisabled} loading={isSubmitting}>
                <FormattedMessage id="review-prompt.form.button.submit" />
            </Button>
            <Button type="default" size="large" htmlType="button" onClick={onClose} disabled={isSubmitting}>
                <FormattedMessage id="review-prompt.form.button.close" />
            </Button>
        </>
    );
};
