import type { KeysOfUnion } from 'types/utils';

import type { ListItemSize, Secret } from '../types';

export const GROUP_OTHER = '#';
export const SECRET_GROUPS = [GROUP_OTHER, ...'abcdefghijklmnopqrstuvwxyz'] as const;

export enum SecretType {
    Password = 'password',
    PaymentCard = 'paymentCard',
    ApiCredentials = 'apiCredentials',
    DatabaseCredentials = 'databaseCredentials',
    SshKey = 'sshKey',
    SecureNote = 'secureNote',
}

export enum SecretListId {
    MAIN_LIST = 'main-list',
    AUDIT_REPORT_LIST = 'audit-report-list',
    AUDIT_LEVEL_LIST = 'audit-level-list',
    AUDIT_AGE_LIST = 'audit-age-list',
}

export enum ListItemType {
    SECRET = 'secret',
    GROUP = 'group',
}

export enum Fields {
    TYPE = 'type',
    NAME = 'name',
    USERNAME = 'username',
    PASSWORD = 'password',
    WEB = 'web',
    NOTE = 'note',
    TAGS = 'tags',
    GROUPS = 'groups',
    WHITELIST_USERS = 'whitelistUsers',
    FILE = 'file',
    TOTP = 'TOTP',
    // A helper field for TOTP manipulation
    HAS_TOTP = 'hasTOTP',
    CARD_NUMBER = 'cardNumber',
    // A helper field
    REDACTED_CARD_NUMBER = 'redactedCardNumber',
    CVV_CODE = 'cvvCode',
    CARDHOLDER_NAME = 'cardholderName',
    EXPIRATION_DATE = 'expirationDate',
    SECURE_NOTE = 'secureNote',
    CREDENTIALS = 'credentials',
    HOSTNAME = 'hostname',
    DATABASE_NAME = 'databaseName',
    DATABASE_TYPE = 'databaseType',
    SERVER = 'server',
    PORT = 'port',
    PRIVATE_KEY = 'privateKey',
    PUBLIC_KEY = 'publicKey',
}
export enum Readability {
    EASY_TO_SAY = 'easyToSay',
    EASY_TO_READ = 'easyToRead',
    ALL_CHARACTERS = 'allCharacters',
}
export enum Options {
    UPPERCASE = 'uppercase',
    LOWERCASE = 'lowercase',
    NUMBERS = 'numbers',
    SYMBOLS = 'symbols',
}
export enum GeneratePassword {
    LENGTH = 'length',
    READABILITY = 'readability',
    OPTIONS = 'options',
}
export const TOTP_VALUE_PLACEHOLDER = '••••••';

export const REMOVE_SECRET_MODAL_KEY = 'removeSecret';

export const SECRETS_CACHE = 'secrets';

export const LIST_OFFSET_DEBOUNCE = 250;

export const SENSITIVE_DATA_KEYS = [
    Fields.PASSWORD,
    Fields.NOTE,
    Fields.FILE,
    Fields.CARD_NUMBER,
    Fields.CVV_CODE,
    Fields.CREDENTIALS,
    Fields.PRIVATE_KEY,
    Fields.SECURE_NOTE,
] as const satisfies Array<KeysOfUnion<Secret>>;

export const COPY_RESET_DELAY = 1500;

export const PASSWORD_CHECK_DEBOUNCE = 200;

export const MAX_PASSWORD_CHECK_LENGTH = 100;

export const WARNING_SAAS_SECRETS_COUNT = 10;
export const MAX_SAAS_SECRETS_COUNT = 15;

export const MAX_SAAS_SECRETS_COUNT_ERROR_TYPE = 'saas-limit';

export const SECRETS_TOTAL_COUNT_HEADER = 'x-total-count';

export enum SecurityLevel {
    EXTRA_WEAK = 0,
    WEAK = 1,
    AVERAGE = 2,
    STRONG = 3,
    EXTRA_STRONG = 4,
}

export enum SecurityAge {
    LESS_THAN_ONE_YEAR = 0,
    ONE_YEAR = 1,
    MORE_THAN_TWO_YEARS = 2,
}

export enum SecurityLeaks {
    NO_LEAKS = 0,
    FEW_LEAKS = 1,
    MANY_LEAKS = 2,
}

export const UP_TO_LEAKS = 10;

export enum SecurityReport {
    LOW_LEVEL = 'lowLevel',
    HIGH_AGE = 'highAge',
    DUPLICITIES = 'duplicities',
    MANY_LEAKS = 'manyLeaks',
}

export const IGNORE_SECURITY_AUDIT_REPORT_MODAL = 'ignore-security-audit-report';

export const SecurityAuditGroupSize: ListItemSize = {
    desktop: 56,
    mobile: 56,
};

export const SecurityAuditSecretSize: ListItemSize = {
    desktop: 80,
    mobile: 110,
};

export const SecurityAuditSecretWithAdditionalSize: ListItemSize = {
    desktop: 95,
    mobile: 125,
};

export const SecurityAuditSecretWithAgeAndIndicatorSize: ListItemSize = {
    desktop: 95,
    mobile: 150,
};
