import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom-v5-compat';

import { Loadable, Empty } from 'modules/ui';

import { useFetchSecretUpdate } from '../../hooks/useFetchSecretUpdate';

import { UpdateSecretFormProvider } from '../UpdateSecretFormProvider';
import { UpdateSecret } from './UpdateSecret/UpdateSecret';

export const UpdateSecretPage = () => {
    const { id } = useParams<{
        id: string;
    }>();

    if (!id) {
        throw new Error('Missing secret ID');
    }

    const { showLoader, hasSecret, hasPermissions } = useFetchSecretUpdate(id);

    if (showLoader) return <Loadable showLoader />;

    if (!hasSecret || !hasPermissions)
        return <Empty data-testid="update-secret-empty" text={<FormattedMessage id="secret.detail.empty" />} />;

    return (
        <UpdateSecretFormProvider>
            <UpdateSecret />
        </UpdateSecretFormProvider>
    );
};
