import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import type { Group } from '../types';
import { ALL_USERS_GROUP_ID } from '../constants';

export const useGroupsWithLocalizedAllUsersGroup = (groups: Group[]) => {
    const { formatMessage } = useIntl();

    return useMemo(() => {
        const allUsersGroup = groups.find(group => group.id === ALL_USERS_GROUP_ID);
        const hasAllUsersGroup = Boolean(allUsersGroup);

        return [
            hasAllUsersGroup && {
                ...allUsersGroup,
                name: formatMessage({ id: 'secret.groups.allUsers.label' }),
            },
            ...groups.filter(group => group.id !== ALL_USERS_GROUP_ID),
        ].filter(Boolean) as Group[];
    }, [groups, formatMessage]);
};
