import { createSelector } from '@reduxjs/toolkit';

import { apiSelector } from '@ackee/redux-utils';

import { selectEntities } from 'services/selectors';

import type { AppState } from 'types';

import type { SettingsFormValues } from '../schemas';

export const selectSettingsEntities = createSelector(selectEntities, entities => entities.settings);
export const selectGeneralSettings = createSelector(selectSettingsEntities, ({ general }) => general);
export const selectSettingsFormInitialValues = createSelector(
    selectGeneralSettings,
    ({ defaultPermission, sharingPermission }): SettingsFormValues => ({
        // @ts-expect-error
        defaultPermission,
        // @ts-expect-error
        sharingPermission,
    }),
);
export const selectOrderInfo = createSelector(selectSettingsEntities, ({ order }) => order);

export const selectSettingsApi = (state: AppState) => apiSelector(state, 'settings', 'load');
export const selectOrderApi = (state: AppState) => apiSelector(state, 'settings', 'loadOrder');
