import { useParams } from 'react-router-dom-v5-compat';
import { FormattedMessage, useIntl } from 'react-intl';

import { Label } from 'modules/forms';
import { FormFieldWhitelist, useWhitelistUsers } from 'modules/whitelist';
import type { WhitelistUser } from 'modules/whitelist';

import { Fields } from '../../../constants';

import { useCreatedBy } from '../../../hooks/useCreatedBy';

import { SelectField } from '../SelectField';
import type { SelectFieldProps } from '../SelectField';

export const WhitelistField = ({ onChange }: Pick<SelectFieldProps<WhitelistUser>, 'onChange'>) => {
    const { id } = useParams<{
        id: string;
    }>();

    const createdById = useCreatedBy(id ?? '');

    const name = Fields.WHITELIST_USERS;

    const intl = useIntl();

    const placeholder = intl.formatMessage({ id: 'secret.whitelistUsers.placeholder' });

    const { api, data, handleLoadMore, handleSearch } = useWhitelistUsers();

    return (
        <SelectField<WhitelistUser>
            id={name}
            name={name}
            label={
                <Label>
                    <FormattedMessage id="secret.whitelistUsers.label" />
                </Label>
            }
            placeholder={placeholder}
            options={data
                .filter(user => user.id !== createdById)
                .map(user => ({
                    value: user.id,
                    key: user.id,
                    label: `${user.name} (${user.email})`,
                    entity: user,
                }))}
            onChange={onChange}
            loadingMore={api.inProgressNext}
            onSearch={handleSearch}
            onLoadMore={handleLoadMore}
            hasMore={Boolean(api.nextPageToken)}
            popupShouldIgnoreParentContainer
        >
            {(value, actions) => (
                <FormFieldWhitelist value={value} onUpdate={actions.onUpdate} onRemove={actions.onRemove} />
            )}
        </SelectField>
    );
};
