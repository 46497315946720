import { FormattedMessage } from 'react-intl';
import { Controller, type ControllerRenderProps } from 'react-hook-form';

import { FileInput, FormField, Label } from 'modules/forms';

import type { Base64File } from '../../../types';
import { getBase64EncodedFile } from '../../../utilities';
import { Fields } from '../../../constants';
import { SecretFile } from '../../SecretFile';

import * as felaRules from './FileField.rules';

const handleChange = (val: Base64File | null, field: ControllerRenderProps) => {
    field.onChange(val);
    field.onBlur();
};

export const FileField = () => {
    const name = Fields.FILE;

    const label = (
        <Label>
            <FormattedMessage id={`secret.${name}.label`} />
        </Label>
    );

    return (
        <FormField name={name} label={label} customStyle={felaRules.formItem}>
            <Controller
                name={name}
                render={({ field }) => (
                    <FileInput<Base64File>
                        file={field.value}
                        onFile={file => {
                            if (file) {
                                getBase64EncodedFile(file)
                                    .then(base64 => {
                                        handleChange(base64, field);
                                    })
                                    .catch(() => {
                                        handleChange(null, field);
                                    });
                            } else {
                                handleChange(null, field);
                            }
                        }}
                        renderFile={({ onRemove }) => <SecretFile file={field.value} onRemove={onRemove} />}
                    />
                )}
            />
        </FormField>
    );
};
