import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.brownGrey,

    '& h1': {
        fontSize: '1.75rem',
        marginBottom: '1em',
    },
    '& h2': {
        fontSize: '1.5rem',
        marginBottom: '0.75em',
    },
    '& h3': {
        fontSize: '1.25rem',
        marginBottom: '0.5em',
    },
    '& h4': {
        fontSize: '1.15rem',
        marginBottom: '0.35em',
    },
    '& h5': {
        fontSize: '1.05rem',
    },
});

export const paragraph: TRuleWithTheme = () => ({
    marginBottom: '1em',
    lineHeight: '1.65',
});

export const list: TRuleWithTheme = () => ({
    marginBottom: '1em',

    '& ul': {
        paddingLeft: '2rem',
        paddingTop: '0.5rem',
    },

    '& ul > li': {
        listStyleType: 'disc',
        paddingBlock: '0.2rem',
    },
});

export const listItem: TRuleWithTheme = () => ({
    lineHeight: '1.35',
    paddingTop: '0.55em',
    paddingBottom: '0.55em',
});
