import { z } from 'zod';

import { MessageKey } from 'modules/localizations/translations/types';

import { Fields, SecretType } from '../constants';
import { basicSecretFormSchema } from './basicSecretFormSchema';

export const paymentCardSecretFormSchema = basicSecretFormSchema.merge(
    z.object({
        [Fields.TYPE]: z.literal(SecretType.PaymentCard),
        [Fields.CARD_NUMBER]: z
            .string()
            .transform(val => val.replace(/\s+/g, ''))
            .pipe(
                z
                    .string()
                    .min(8, { message: 'secret.paymentCardInformation.cardNumber.error.short' satisfies MessageKey })
                    .max(19, { message: 'secret.paymentCardInformation.cardNumber.error.long' satisfies MessageKey }),
            )
            .optional()
            .or(z.literal('')),
        [Fields.EXPIRATION_DATE]: z
            .string()
            .regex(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/, {
                message: 'secret.paymentCardInformation.expirationDate.error.pattern' satisfies MessageKey,
            })
            .optional()
            .or(z.literal('')),
        [Fields.CVV_CODE]: z
            .string()
            .min(3, { message: 'secret.paymentCardInformation.cvvCode.error.short' satisfies MessageKey })
            .max(4, { message: 'secret.paymentCardInformation.cvvCode.error.long' satisfies MessageKey })
            .optional()
            .or(z.literal('')),
        [Fields.CARDHOLDER_NAME]: z.string().optional(),
    }),
);
