import { FormattedMessage } from 'react-intl';
import { useController } from 'react-hook-form';

import { RadioButtonGroup, SettingsFormItem } from 'modules/forms';
import { toLowerCase } from 'utilities/string';
import type { Entries } from 'types/utils';

import { Fields } from '../../../constants';
import { Delimiter } from '../../../types';
import { ImportFormValues } from '../../../schemas';

const options = Object.entries(Delimiter) as Entries<typeof Delimiter>;

const name = Fields.DELIMITER;

export const DelimiterField = () => {
    const {
        field: { ref, ...field },
    } = useController<ImportFormValues, typeof name>({ name });

    return (
        <SettingsFormItem name={name} label={<FormattedMessage id="import.form.delimiter.label" />}>
            <RadioButtonGroup
                {...field}
                optionType="button"
                options={options.map(([key, value]) => ({
                    label: <FormattedMessage id={`import.form.delimiter.${toLowerCase(key)}`} />,
                    value,
                }))}
            />
        </SettingsFormItem>
    );
};
