import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFela } from 'react-fela';

import config from 'config/index';
import { Icon, Link, Alert, Button } from 'modules/ui';
import { useSubscriptionUpgradeModal } from 'modules/saas-upgrade';

import { MAX_SAAS_SECRETS_COUNT } from '../../constants';
import { useSaasCountReached } from '../../hooks/useSaasCountReached';

import * as felaRules from './SecretsCountAlert.rules';

export interface SecretsCountAlertProps {}

export const SecretsCountAlert = () => {
    const { css } = useFela();
    const { count, isError, isWarning } = useSaasCountReached();
    const { openUpgradeModal } = useSubscriptionUpgradeModal();

    const type = isError ? 'error' : 'warning';

    if (isWarning || isError) {
        return (
            <Alert
                showIcon
                icon={<Icon type="warning" width={20} height={20} />}
                size="fullWidth"
                message={
                    <FormattedMessage
                        id="secret.count-alert"
                        values={{
                            count,
                            maxCount: MAX_SAAS_SECRETS_COUNT,
                            a1: (parts: ReactNode) => (
                                <Button size="middle" type="link" underline inheritColor onClick={openUpgradeModal}>
                                    {parts}
                                </Button>
                            ),
                            a2: (parts: ReactNode) => (
                                <Link size="regular" type="native" inheritColor href={config.links.freeTierUpgrade}>
                                    {parts}
                                </Link>
                            ),
                        }}
                    />
                }
                type={type}
                data-testid={`secrets-count-saas-alert-${type}`}
            />
        );
    }

    return <div className={css(felaRules.divider)} />;
};
