import type { TRuleWithTheme } from 'styles/theme';

export const modalContainer: TRuleWithTheme = () => ({
    '& .ant-modal .ant-modal-content': {
        padding: 0,
        margin: '0 auto',

        height: '95vh',
        width: '95%',
        maxWidth: '100rem',

        overflow: 'hidden',

        min1024: {
            maxHeight: '62rem',
        },
    },

    '& .ant-modal .ant-modal-body': {
        height: '100%',
    },
});

export const contentContainer: TRuleWithTheme = () => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',

    height: '100%',
    overflow: 'hidden',
});

export const textContainer: TRuleWithTheme = () => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1.25rem',

    textAlign: 'center',

    paddingTop: '2.5rem',
    paddingInline: '1.5rem',
    margin: '0 auto',

    maxWidth: '38rem',

    min1024: {
        paddingTop: '4.125rem',
        paddingInline: '2rem',
    },
});
export const iframeContainer: TRuleWithTheme = () => ({
    height: '100%',
    overflow: 'hidden',
});
export const iframe: TRuleWithTheme = () => ({
    height: '100%',
    overflow: 'scroll',
});
