export const Fields = {
    DEFAULT_PERMISSION: 'defaultPermission',
    SHARING_PERMISSION: 'sharingPermission',
};

export enum SharingPermission {
    EVERYONE = 'everyone',
    ADMIN_ONLY = 'adminOnly',
    DISALLOWED = 'disallowed',
}

export enum SubscriptionType {
    ACTIVE = 'active',
    CANCELED = 'canceled',
    NOT_LINKED = 'not-linked',
    FREE = 'free',
}
