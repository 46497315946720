import type { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { useAppSelector } from 'hooks/useAppSelector';
import { Loader } from 'modules/ui';
import { Form, handleFormSubmit } from 'modules/forms';

import { updateSettings } from '../../services/actions';
import { selectSettingsApi, selectSettingsFormInitialValues } from '../../services/selectors';
import { settingsFormSchema, SettingsFormSchemaType, SettingsFormValues } from '../../schemas';

export interface SettingsFormProviderProps {
    children: ReactNode;
}

export const SettingsFormProvider = ({ children }: SettingsFormProviderProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const api = useAppSelector(selectSettingsApi);
    const defaultValues = useAppSelector(selectSettingsFormInitialValues);

    if (api.inProgress) {
        return <Loader />;
    }

    return (
        <Form<SettingsFormSchemaType, SettingsFormValues>
            schema={settingsFormSchema}
            defaultValues={defaultValues}
            onSubmit={handleFormSubmit((values, actions) => dispatch(updateSettings(values, actions)))}
            aria-label={intl.formatMessage({
                id: 'settings.form.title',
            })}
        >
            {children}
        </Form>
    );
};
