import type { SVGProps } from 'react';

export const SecretTypeDatabase = (props: SVGProps<SVGSVGElement>) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M3.75 4.875C3.75 5.37228 4.30312 5.84919 5.28769 6.20083C6.27226 6.55246 7.60761 6.75 9 6.75C10.3924 6.75 11.7277 6.55246 12.7123 6.20083C13.6969 5.84919 14.25 5.37228 14.25 4.875C14.25 4.37772 13.6969 3.90081 12.7123 3.54917C11.7277 3.19754 10.3924 3 9 3C7.60761 3 6.27226 3.19754 5.28769 3.54917C4.30312 3.90081 3.75 4.37772 3.75 4.875Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M3.75 5.25V9.25C3.75 9.78043 4.30312 10.2891 5.28769 10.6642C6.27226 11.0393 7.60761 11.25 9 11.25C10.3924 11.25 11.7277 11.0393 12.7123 10.6642C13.6969 10.2891 14.25 9.78043 14.25 9.25V5.25"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M3.75 9V13C3.75 13.5304 4.30312 14.0391 5.28769 14.4142C6.27226 14.7893 7.60761 15 9 15C10.3924 15 11.7277 14.7893 12.7123 14.4142C13.6969 14.0391 14.25 13.5304 14.25 13V9"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
