import { useSelector } from 'react-redux';

import { selectGeneralSettings } from '../services/selectors';
import { SubscriptionType } from '../constants';

export const useIsFreeTier = () => {
    const { subscription } = useSelector(selectGeneralSettings);

    return subscription === SubscriptionType.FREE;
};
