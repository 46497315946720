import { SecretType } from '../constants';
import type { Secret } from '../types';

export const updatedAtLookup = (secret: Secret) => {
    let timestamp: string | undefined;

    if (secret.type === SecretType.Password) {
        timestamp = secret.passwordUpdatedAt;
    }

    return timestamp ?? secret.updatedAt ?? secret.createdAt;
};

export const isPublicSharingActive = (share: Secret['share']) =>
    Boolean(share?.until && new Date(share.until) >= new Date());

export * from './files';
export * from './validate';
export * from './guards';
export * from './search';
export * from './paymentCard';
