import { useFormContext, useWatch } from 'react-hook-form';
import { useEffect } from 'react';

import { secretTypeToDefaultValues } from '../../../constants/defaultValues';
import { Fields } from '../../../constants';
import { SecretFormValues } from '../../../schemas';

export const useResetDefaultValuesOnSecretTypeChange = () => {
    const secretType = useWatch<SecretFormValues, Fields.TYPE>({ name: Fields.TYPE });
    const { reset } = useFormContext<SecretFormValues>();

    useEffect(() => {
        reset(secretTypeToDefaultValues[secretType], { keepDirtyValues: true });
    }, [secretType, reset]);
};
