import { useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { hasReadPermission } from 'utilities/permission';
import { isEmptyOrUndefined } from 'utilities/string';

import { SecretFieldSection } from '../../../../../SecretFieldSection';
import type { PasswordTypeSecret } from '../../../../../../types';
import { Fields } from '../../../../../../constants';

import { CopySecretTOTP } from '../../../CopySecretTOTP';
import { CopySection } from '../CopySection';

import * as felaRules from '../CopySection/CopySection.rules';

export interface PasswordSectionsProps {
    secret: PasswordTypeSecret;
}

export const PasswordSections = ({ secret }: PasswordSectionsProps) => {
    const TOTPFieldRef = useRef<HTMLDivElement>(null);

    const hasRead = hasReadPermission(secret.userPermissions);

    const { id, username, hasTOTP, sensitiveFields } = secret;

    return (
        <>
            <CopySection
                visible={!isEmptyOrUndefined(username)}
                headingMessageKey="secret.username.label"
                secret={secret}
                property={Fields.USERNAME}
            />
            <CopySection
                visible={sensitiveFields?.includes(Fields.PASSWORD)}
                headingMessageKey="secret.password.label"
                secret={secret}
                property={Fields.PASSWORD}
                mask={<FormattedMessage id="crypted.password" />}
                disabled={!hasRead}
            />
            {hasTOTP && (
                <SecretFieldSection
                    heading={<FormattedMessage id="secret.totp" />}
                    customLabelStyle={felaRules.propertyLabel}
                    ref={TOTPFieldRef}
                >
                    <CopySecretTOTP id={id} disabled={!hasRead} ref={TOTPFieldRef} />
                </SecretFieldSection>
            )}
        </>
    );
};
