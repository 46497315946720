import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

export const useScrollToFirstError = () => {
    const {
        formState: { errors, isSubmitSuccessful, submitCount },
    } = useFormContext();

    useEffect(() => {
        const errorKeys = Object.keys(errors ?? []);

        if (isSubmitSuccessful || !errorKeys.length) return;

        if (errorKeys.includes('root')) {
            const mainEl = document.getElementsByTagName('main')?.[0];

            mainEl?.scrollTo({ top: 0, behavior: 'smooth' });

            return;
        }

        const el = document.getElementById(errorKeys[0]);

        if (el) {
            el.scrollIntoView({ behavior: 'smooth', block: 'center' });
            el.focus({ preventScroll: true });
        }
    }, [errors, isSubmitSuccessful, submitCount]);
};
