import type { ReactNode } from 'react';
import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import { Upload } from 'antd';

import { noop } from 'lodash';
import type { RcFile } from 'antd/es/upload';

import { Text } from 'modules/ui';

import * as felaRules from './InnerInput.rules';

const { Dragger } = Upload;

export interface InnerInputProps {
    disabled?: boolean;
    hasError?: boolean;
    onFile: (file?: RcFile) => void;
}

export const InnerInput = ({ disabled, onFile, hasError }: InnerInputProps) => {
    const { css } = useFela({
        disabled,
        hasError,
    });

    return (
        <Dragger
            className={css(felaRules.dragger)}
            name="file"
            showUploadList={false}
            disabled={disabled}
            customRequest={noop}
            onChange={data => {
                onFile(data.file.originFileObj);
            }}
            data-testid="dropzone-input"
        >
            <Text elementType="span" size="small" color="foregroundsTertiary" customStyle={felaRules.text}>
                <FormattedMessage
                    id="dropzone.text"
                    values={{
                        button: (chunks: ReactNode) => <span className={css(felaRules.button)}>{chunks}</span>,
                    }}
                />
            </Text>
        </Dragger>
    );
};
