import type { TRuleWithTheme } from 'styles/theme';

export const generatePasswordButton: TRuleWithTheme = ({ theme }) => ({
    '&.ant-btn.ant-btn-round': {
        paddingBlock: '0.66rem',
        paddingInline: '0.75rem',
        height: 'unset',

        ':hover': {
            color: theme.colors.foregroundsSecondary,
        },
    },
});

export const popover = {
    padding: 0,
};
