import type { TRuleWithTheme, Theme } from 'styles/theme';

export const radioButton: TRuleWithTheme<{
    backgroundColor?: keyof Theme['colors'];
}> = ({ theme, backgroundColor }) => ({
    '& .ant-radio-button-wrapper': {
        border: 'none',
        borderRadius: 999,
        backgroundColor: 'transparent',
        '::before': {
            width: 0,
        },
    },

    '& .ant-radio-button-wrapper:hover': {
        color: theme.colors.black,
    },

    '& label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked': {
        color: theme.colors.white,
        backgroundColor: theme.colors.brightMagenta,
        boxShadow: 'none',
    },

    '&.ant-radio-group': {
        fontSize: '14px',
        borderRadius: 999,
        backgroundColor: backgroundColor ? theme.colors[backgroundColor] : theme.colors.white,
    },
});
