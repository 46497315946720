import { AUTH_SESSION_END } from '@ackee/petrus';
import { createReducer } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, createMigrate } from 'redux-persist';

import { StaticModal } from '../constants';

import { setModalVisibility } from './actions';

const initialState: Record<
    string,
    {
        visibility: boolean;
        data?: {};
    }
> = {};

export const modals = createReducer(initialState, builder => {
    builder.addCase(setModalVisibility, (state, action) => {
        state[action.meta.modal] = action.payload;
    });
    builder.addCase(AUTH_SESSION_END, state => {
        delete state[StaticModal.USER_FEEDBACK];
    });
});

export const reducer = persistReducer(
    {
        key: 'modals',
        storage,
        whitelist: [StaticModal.EXTENSION, StaticModal.USER_FEEDBACK, StaticModal.REVIEW_PROMPT],

        migrate: createMigrate({
            // @ts-expect-error
            0: state => {
                const newState = { ...state };

                if (state?.[StaticModal.EXTENSION] !== 'undefined') {
                    newState[StaticModal.EXTENSION] = {
                        visibility: state?.[StaticModal.EXTENSION],
                    };
                }

                return newState;
            },
        }),
        version: 0,
    },
    modals,
);
