import type { TRuleWithTheme } from 'styles/theme';

import type { CopyButtonType } from '../CopyButton';

export const container: TRuleWithTheme<{ type: `${CopyButtonType}`; show: boolean }> = ({ show, type }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '0.75rem',

    min768: {
        gap: '1.25rem',
    },

    ...(type === 'secureTextArea' &&
        show && {
            alignItems: 'flex-start',
            width: '100%',
        }),
});

export const text: TRuleWithTheme = () => ({
    wordBreak: 'break-word',
    overflow: 'hidden',
    width: 'inherit',
});
