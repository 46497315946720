import { FormattedMessage } from 'react-intl';

import config from 'config';

import type { SecurityAuditAgeItem } from '../../types';

import { ListItemType } from '../../constants';

import { updatedAtLookup } from '../../utilities';

import { IgnoreIndicator } from '../IgnoreIndicator';
import { AgeIndicator } from '../AgeIndicator';

import { SecurityAuditGroupItem } from '../SecurityAuditGroupItem';
import { SecurityAuditSecretItem } from '../SecurityAuditSecretItem';

import { SecurityAuditItemRenderer } from '../SecurityAuditItemRenderer';
import type { SecurityAuditItemRendererProps } from '../SecurityAuditItemRenderer';

export interface SecurityAuditAgeItemRendererProps
    extends Omit<SecurityAuditItemRendererProps<SecurityAuditAgeItem>, 'children'> {}

export const SecurityAuditAgeItemRenderer = (props: SecurityAuditAgeItemRendererProps) => (
    <SecurityAuditItemRenderer<SecurityAuditAgeItem> {...props}>
        {({ item, isFirstGroup, isLastInGroup }) => {
            if (item.listItemType === ListItemType.GROUP) {
                return (
                    <SecurityAuditGroupItem
                        name={<FormattedMessage id={`password.age.${item.securityAge}`} />}
                        count={item.count}
                        isFirstGroup={isFirstGroup}
                    />
                );
            }

            const date = updatedAtLookup(item);
            const ignore = item.ignoreSecurityReport;

            return (
                <SecurityAuditSecretItem
                    id={item.id}
                    name={item.name}
                    updateSecretRoute={config.routes.securityAuditAgeUpdateSecret}
                    isLastInGroup={isLastInGroup}
                    additional={
                        <>
                            {date ? <AgeIndicator date={date} /> : null}
                            {ignore ? <IgnoreIndicator /> : null}
                        </>
                    }
                />
            );
        }}
    </SecurityAuditItemRenderer>
);
