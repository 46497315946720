import type { SVGProps } from 'react';

export const Premium = (props: SVGProps<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M7.33325 14H4.66659C4.31296 14 3.97382 13.8595 3.72378 13.6095C3.47373 13.3594 3.33325 13.0203 3.33325 12.6667V8.66667C3.33325 8.31304 3.47373 7.97391 3.72378 7.72386C3.97382 7.47381 4.31296 7.33333 4.66659 7.33333H10.6666V4.66667C10.6666 3.95942 10.3856 3.28115 9.88554 2.78105C9.38544 2.28095 8.70716 2 7.99992 2C7.29267 2 6.6144 2.28095 6.1143 2.78105C5.6142 3.28115 5.33325 3.95942 5.33325 4.66667V7.33333"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.05 8.88496C11.1916 8.44903 11.8084 8.44903 11.95 8.88496L12.5623 10.7694H14.5438C15.0021 10.7694 15.1927 11.356 14.8219 11.6254L13.2188 12.7901L13.8312 14.6746C13.9728 15.1105 13.4739 15.473 13.103 15.2036L11.5 14.0389L9.89696 15.2036C9.52614 15.473 9.02721 15.1105 9.16885 14.6746L9.78115 12.7901L8.17812 11.6254C7.8073 11.356 7.99787 10.7694 8.45623 10.7694H10.4377L11.05 8.88496Z"
            fill="currentColor"
        />
    </svg>
);
