import { combineRules } from 'fela';
import tinyColor from 'tinycolor2';

import { config } from 'config/config';

import type { TRuleWithTheme } from 'styles/theme';

import { menuDefault } from '../MenuDesktop/MenuDesktop.rules';

export const TRANSITION_DURATION = 250; // ms

export const menuMobile: TRuleWithTheme = ({ theme }) => ({
    position: 'fixed',

    left: -theme.widths.menu,
    top: 0,
    zIndex: theme.zIndexes.mobileMenu,

    width: theme.widths.menu,

    '&.enter': {
        left: -theme.widths.menu,
    },

    '&.enter-active': {
        left: 0,

        transitionProperty: 'left',
        transitionDuration: `${TRANSITION_DURATION / 1000}s`,
        transitionTimingFunction: 'ease-in-out',
    },

    '&.enter-done': {
        left: 0,
    },

    '&.exit': {
        left: 0,
    },

    '&.exit-active': {
        left: -theme.widths.menu,

        transitionProperty: 'left',
        transitionDuration: `${TRANSITION_DURATION / 1000}s`,
        transitionTimingFunction: 'ease-in-out',
    },

    '&.exit-done': {
        left: -theme.widths.menu,
    },
});

export const menu = combineRules(menuDefault, menuMobile);

export const menuOverlay: TRuleWithTheme = ({ theme }) => ({
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: theme.zIndexes.mobileMenuOverlay,

    backgroundColor: [theme.colors.blue, tinyColor(theme.colors.overlayBlue).setAlpha(0.7).toRgbString()],

    min1024: {
        backgroundColor: 'transparent',
    },
});

export const menuHeader: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    backgroundColor: theme.colors.white,

    paddingTop: config.demo.enable ? '0.875rem' : '1.5rem',
    paddingRight: '1rem',
    paddingBottom: config.demo.enable ? '0.875rem' : '1rem',
    paddingLeft: '1rem',

    min768: {
        paddingTop: '1.75rem',
        paddingRight: '2.5rem',
        paddingBottom: '1.75rem',
        paddingLeft: '2.5rem',
    },
});

export const menuButton: TRuleWithTheme = () => ({
    marginLeft: 'auto',
    marginBottom: '0.75rem',
});

export const logoWrapper: TRuleWithTheme = () => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
});
