import { useLayoutEffect, useMemo, useRef } from 'react';
import type { MutableRefObject } from 'react';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';

import { useAppSelector } from 'hooks/useAppSelector';

import { LIST_OFFSET_DEBOUNCE, SecretListId } from '../constants';

import { selectSecretsListOffset } from '../services/selectors';
import { setSecretsListOffset } from '../services/actions';

export function useSecretsListOffset(
    listId: SecretListId,
    listRef: MutableRefObject<{
        scrollTo: (offset: number) => void;
    } | null>,
) {
    const dispatch = useDispatch();

    const offset = useAppSelector(selectSecretsListOffset);

    const handleOffset = useMemo(
        () =>
            debounce((offset: number) => {
                dispatch(setSecretsListOffset(offset, listId));
            }, LIST_OFFSET_DEBOUNCE),
        [dispatch, listId],
    );

    const offsetRef = useRef(offset);

    useLayoutEffect(() => {
        const isOffsetOfGivenList = offsetRef.current?.listId === listId;

        listRef.current?.scrollTo(isOffsetOfGivenList ? offsetRef.current?.offset ?? 0 : 0);
    }, [listId, listRef, dispatch]);

    return handleOffset;
}
