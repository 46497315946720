import type { SVGProps } from 'react';

export const SecretTypePaymentCard = (props: SVGProps<SVGSVGElement>) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M2.25 4.4375C2.25 4.16136 2.47386 3.9375 2.75 3.9375H15.25C15.5261 3.9375 15.75 4.16136 15.75 4.4375V13.8438C15.75 14.1199 15.5261 14.3438 15.25 14.3438H2.75C2.47386 14.3438 2.25 14.1199 2.25 13.8437V4.4375Z"
            stroke="currentColor"
            strokeWidth="1.5"
        />
        <path
            d="M2.25 7.5H15.75"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5.25 11.25H5.2575"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.25 11.25H9.75"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="square"
            strokeLinejoin="round"
        />
    </svg>
);
