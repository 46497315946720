import type { CSSProperties } from 'fela';

import type { Theme, TRuleWithTheme } from 'styles/theme';

export enum TextSize {
    REGULAR = 'regular',
    MEDIUM = 'medium',
    SMALL = 'small',
    EXTRA_SMALL = 'extraSmall',
    LABEL_LARGE = 'labelLarge',
    LABEL_SMALL = 'labelSmall',
}

export const SIZE_STYLE = {
    [TextSize.REGULAR]: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
        letterSpacing: '0.03125rem',
        fontWeight: 400,
    },
    [TextSize.MEDIUM]: {
        fontSize: '0.875rem', // 14px
        lineHeight: '1.5rem', // 24px
        letterSpacing: '0.05em', // 0.7px
    },
    [TextSize.SMALL]: {
        fontSize: '.875rem', // 14px
        lineHeight: '1.43rem', // ~ 20px
        letterSpacing: '0.0143em', // ~ 0.2px
        fontWeight: 400,
    },
    [TextSize.EXTRA_SMALL]: {
        fontSize: '0.625rem', // 10px
        lineHeight: '0.75rem', // 12px
        fontWeight: 400,
    },
    [TextSize.LABEL_LARGE]: {
        fontSize: '0.75rem', // 12px
        lineHeight: '1rem', // 16px
        fontWeight: 400,
    },
    [TextSize.LABEL_SMALL]: {
        fontSize: '0.625rem', // 10px
        lineHeight: '0.75rem', // 12px
        fontWeight: 400,
    },
} satisfies Record<TextSize, CSSProperties>;

export const container: TRuleWithTheme<{
    size?: `${TextSize}`;
    color?: keyof Theme;
    preserveNewLines: boolean;
    bold?: boolean;
}> = ({ theme, size = 'regular', color = theme.colors.black, bold, preserveNewLines }) => ({
    ...SIZE_STYLE[size],
    color: theme.colors[color],
    ...(bold
        ? {
              fontWeight: 700,
          }
        : undefined),
    ...(preserveNewLines && {
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
    }),
    marginBottom: 0,
});
