import { FormattedMessage } from 'react-intl';
import { useFela } from 'react-fela';
import { useController } from 'react-hook-form';

import { Checkbox, FormField } from 'modules/forms';

import { Fields } from '../../../constants';

import * as felaRules from './ReplaceSecretField.rules';

const name = Fields.REPLACE_SECRETS;

export const ReplaceSecretsField = () => {
    const { css } = useFela();

    const {
        field: { ref, value, onChange, ...field },
    } = useController({ name });

    return (
        <FormField name={name} customStyle={felaRules.formItem}>
            <Checkbox {...field} checked={value} onChange={e => onChange(e.target.checked)}>
                <span className={css(felaRules.label)}>
                    <FormattedMessage id="import.form.rewrite" />
                </span>
            </Checkbox>
        </FormField>
    );
};
