import type { Task } from 'redux-saga';
import { takeEvery, put, all, fork, cancel, select, delay } from 'redux-saga/effects';

import { MAX_COUNT, TRANSITION_DURATION } from '../../config';

import { removeMessage, addMessageResolve, hideMessage, addMessageRequest } from '../actions';
import { selectMessages } from '../selectors';

import type { Message, MessageOptions } from '../../types';

import { Level } from '../../constants';

function* closeMessage(id: Message['id']) {
    yield put(hideMessage(id));

    yield delay(TRANSITION_DURATION);

    yield put(removeMessage(id));
}

function* newMessageHandler(action: ReturnType<typeof addMessageRequest>) {
    const messages: ReturnType<typeof selectMessages> = yield select(selectMessages);

    if (messages.ids.length + 1 > MAX_COUNT) {
        yield fork(closeMessage, messages.ids[0]);
    }

    yield put(addMessageResolve(action.payload, action.meta));
}

const timeoutsByIds = new Map<Message['id'], Task>();
const timeoutsByKeys = new Map<MessageOptions['key'], Message['id']>();

function* addMessageHandler(action: ReturnType<typeof addMessageResolve>) {
    const { level } = action.payload;
    const { id, duration, key } = action.meta;

    if (timeoutsByIds.has(id)) {
        yield put(removeMessage(id));
        return;
    }

    if (key) {
        if (level === Level.LOADING) {
            timeoutsByKeys.set(key, id);
        } else {
            const loadingMessageId = timeoutsByKeys.get(key);

            timeoutsByKeys.delete(key);

            if (loadingMessageId) yield put(removeMessage(loadingMessageId));
        }
    }

    let task: Task | null = null;
    if (typeof duration === 'number' && duration > 0) {
        task = yield fork(function* () {
            yield delay(duration * 1000);

            yield* closeMessage(id);
        });
    }

    if (task) timeoutsByIds.set(id, task);
}

function* removeMessageHandler(action: ReturnType<typeof removeMessage>) {
    const { id } = action.meta;

    const task = timeoutsByIds.get(id);

    if (task) {
        yield cancel(task);
    }

    timeoutsByIds.delete(id);
}

export default function* messagesVisibility() {
    yield all([
        takeEvery(addMessageRequest.toString(), newMessageHandler),
        takeEvery(addMessageResolve.toString(), addMessageHandler),

        takeEvery(removeMessage.toString(), removeMessageHandler),
    ]);
}
