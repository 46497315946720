import { useIntl } from 'react-intl';
import { Prompt } from 'react-router';
import { useFela } from 'react-fela';

import { Alert } from 'modules/ui';

import { useUpdateSecretForm } from '../../hooks/useUpdateSecretForm';

import { FileField } from '../SecretForm/FileField';
import { GroupsField } from '../SecretForm/GroupsField';
import { NameFieldWithFavicon } from '../SecretForm/NameFieldWithFavicon';
import { NoteField } from '../SecretForm/NoteField';
import { TagsField } from '../SecretForm/TagsField';
import { WhitelistField } from '../SecretForm/WhitelistField';
import { SecretSection } from '../SecretSection';
import { GroupsAndPermissionsInfoLabel } from '../GroupsAndPermissionsInfoLabel';
import { MainInformationFields } from '../SecretForm/MainInformationFields';

import * as felaRules from './UpdateSecretForm.rules';

export const UpdateSecretForm = () => {
    const { submitError, isDirty, isSubmitting, secretType } = useUpdateSecretForm();
    const { formatMessage } = useIntl();
    const { css } = useFela();

    return (
        <div className={css(felaRules.container)}>
            {submitError && <Alert message={submitError.message} type="error" />}
            <div className={css(felaRules.sectionsContainer)}>
                <NameFieldWithFavicon type="update" />
                <SecretSection title={`secret.type.${secretType}`}>
                    <MainInformationFields />
                </SecretSection>
                <SecretSection title="secret.sections.details.title">
                    <TagsField />
                    <FileField />
                    <NoteField />
                </SecretSection>
                <SecretSection title="secret.sections.permissions.title" customStyle={felaRules.rightColumn} gap={0.75}>
                    <GroupsAndPermissionsInfoLabel />
                    <GroupsField />
                    <WhitelistField />
                </SecretSection>
            </div>

            <Prompt when={isDirty && !isSubmitting} message={formatMessage({ id: 'secret.form.unsavedWarning' })} />
        </div>
    );
};
