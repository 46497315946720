import { useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';

import { TextInput } from 'modules/forms';

import type { SecretFormValues } from '../../../../../schemas';
import { Fields } from '../../../../../constants';
import { formatExpirationValue } from './utils';

export const CardExpirationField = () => {
    const { formatMessage } = useIntl();

    const { setValue, getValues } = useFormContext<SecretFormValues>();

    return (
        <TextInput
            name={Fields.EXPIRATION_DATE}
            autoComplete="off"
            onInput={formatExpirationValue}
            onKeyDown={e => {
                const value = getValues(Fields.EXPIRATION_DATE);

                // Handle removing the slash when the user presses backspace
                if (e.key === 'Backspace' && value && e.currentTarget.selectionStart === 4) {
                    setValue(Fields.EXPIRATION_DATE, value.slice(0, 3));
                }
            }}
            placeholder={formatMessage({
                id: 'secret.paymentCardInformation.expirationDate.placeholder',
            })}
        />
    );
};
