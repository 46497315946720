import { combineRules } from 'fela';

import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({});

export const propertyLabel: TRuleWithTheme = () => ({
    marginBottom: 0,

    whiteSpace: 'nowrap',
});

export const propertyText: TRuleWithTheme = () => ({
    maxWidth: '16rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

export const cryptedCharacters: TRuleWithTheme = combineRules(propertyText, (({ theme }) => ({
    ...theme.typography.Crypted,
})) as TRuleWithTheme);
