import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import type { AppState } from 'types';

const initialState: {
    timestamp: number;
} = {
    timestamp: 0,
};

const name = 'wakedUp';

const { reducer: wakeUpReducer, actions } = createSlice({
    name,
    initialState,
    reducers: {
        apiWakedUp(state, { payload = new Date().getTime() }: PayloadAction<number | undefined>) {
            state.timestamp = payload;
        },
    },
});

const { apiWakedUp } = actions;

export { apiWakedUp };

export const reducer = persistReducer(
    {
        key: name,
        storage,
    },
    wakeUpReducer,
);

export const selectWakeUpFlag = (state: AppState) => state.wakeUp;
