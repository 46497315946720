import { Routes, Route } from 'react-router-dom-v5-compat';

import { config } from 'config/config';
import { useIsAdmin } from 'modules/auth';

import { SecurityAuditLevelGraph } from '../SecurityAuditLevelGraph';
import { SecurityAuditAgeGraph } from '../SecurityAuditAgeGraph';
import { SecurityAuditLeaksGraph } from '../SecurityAuditLeaksGraph';

export const SecurityAuditGraphRoutes = () => {
    const isAdmin = useIsAdmin();

    if (!isAdmin) return null;

    return (
        <Routes>
            <Route path={config.routes.securityAuditAge} element={<SecurityAuditAgeGraph />} />
            <Route path={config.routes.securityAuditLevel} element={<SecurityAuditLevelGraph />} />
            <Route path={config.routes.securityAuditLeaks} element={<SecurityAuditLeaksGraph />} />
        </Routes>
    );
};
