import type { SVGProps } from 'react';

export const Checkmark = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="12" viewBox="0 0 17 12" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.999 12C5.743 12 5.487 11.902 5.292 11.707L0 6.415L1.413 5L5.999 9.586L15.585 0L16.997 1.417L6.706 11.707C6.511 11.902 6.255 12 5.999 12Z"
            fill="currentColor"
        />
    </svg>
);
