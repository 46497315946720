import { FormattedMessage, useIntl } from 'react-intl';

import { useWhitelistUsers } from 'modules/whitelist';
import { useUser } from 'modules/auth';

import type { ViewFilter } from '../../types';

import { SecretsViewSelect, Option } from '../SecretsViewSelect';

export interface SecretsViewUserSelectProps {
    viewFilter: ViewFilter | null;
    onChange: (viewFilter: ViewFilter | null) => void;
}

const ADMIN = 'admin';
const YOU = 'you';

export const SecretsViewUserSelect = ({ viewFilter, onChange }: SecretsViewUserSelectProps) => {
    const { data, api, handleSearch, handleLoadMore } = useWhitelistUsers();

    const intl = useIntl();

    const user = useUser();

    return (
        <SecretsViewSelect
            placeholder={intl.formatMessage({
                id: 'secrets.view.modal.user.placeholder',
            })}
            onChange={value => {
                let view: ViewFilter | null = null;

                if (value === YOU) {
                    if (!user) {
                        view = null;
                    } else {
                        view = {
                            id: user.id,
                            email: user.email,
                            name: user.name,
                            type: 'user',
                        };
                    }
                } else if (value !== ADMIN) {
                    const option = data.find(({ id }) => id === value);

                    if (!option) {
                        view = null;
                    } else {
                        view = {
                            id: option?.id,
                            email: option?.email,
                            name: option?.name,
                            type: 'user',
                        };
                    }
                }

                onChange(view);
                handleSearch('');
            }}
            options={[
                {
                    key: 'default',
                    label: <FormattedMessage id="secrets.view.modal.user.defaults" />,
                    options: [
                        {
                            key: 'admin',
                            value: ADMIN,
                            title: intl.formatMessage({
                                id: 'secrets.view.modal.user.admin',
                            }),
                            label: (
                                <Option icon="admin">
                                    <FormattedMessage id="secrets.view.modal.user.admin" />
                                </Option>
                            ),
                        },
                        {
                            key: 'you',
                            value: YOU,
                            title: intl.formatMessage(
                                {
                                    id: 'secrets.view.modal.user.you',
                                },
                                {
                                    email: user?.email,
                                },
                            ),
                            label: (
                                <Option icon="user">
                                    <FormattedMessage
                                        id="secrets.view.modal.user.you"
                                        values={{
                                            email: user?.email,
                                        }}
                                    />
                                </Option>
                            ),
                        },
                    ],
                },
                data.length > 0 || viewFilter?.type === 'user'
                    ? {
                          key: 'users',
                          label: <FormattedMessage id="secrets.view.modal.user.options" />,
                          options: (data.length > 0 ? data : [viewFilter].filter(wf => wf !== null)).map(
                              ({ id, email }) => ({
                                  key: id,
                                  value: id,
                                  title: email,
                                  label: <Option icon="user">{email}</Option>,
                              }),
                          ),
                      }
                    : null,
            ].filter(opt => opt !== null)}
            onSearch={handleSearch}
            hasMoreOptions={Boolean(api.nextPageToken)}
            loadingMoreOptions={api.inProgressNext}
            onMoreOptions={handleLoadMore}
            defaultValue={viewFilter?.type === 'user' ? viewFilter?.id : undefined}
        />
    );
};
