import { forwardRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFela } from 'react-fela';

import { Text } from 'modules/ui';

import type { Secret } from '../../../../types';
import { CopySecretValue, CopySecretValueProps } from '../CopySecretValue';
import { TOTPCountdown } from '../TOTPCountdown';
import { Fields } from '../../../../constants';

import * as felaRules from './CopySecretTOTP.rules';

export interface CopySecretTOTPProps {
    id: Secret['id'];
    disabled: boolean;
}

export const CopySecretTOTP = forwardRef<HTMLDivElement, CopySecretTOTPProps>(({ id, disabled }, TOTPFieldRef) => {
    const { css } = useFela();

    const [copied, setCopied] = useState(false);

    const handleCopy: CopySecretValueProps['onCopy'] = ({ success }) => {
        setCopied(success);
    };

    return (
        <div className={css(felaRules.container)}>
            <Text
                color={!disabled ? 'foregroundsPrimary' : 'foregroundsTertiary'}
                customStyle={felaRules.cryptedCharacters}
            >
                <FormattedMessage id="crypted.totp" />
            </Text>
            <CopySecretValue id={id} property={Fields.TOTP} disabled={disabled} onCopy={handleCopy} />
            {!disabled && <TOTPCountdown id={id} copied={copied} ref={TOTPFieldRef} />}
        </div>
    );
});
