import type { CSSProperties } from 'fela';

import type { TRuleWithTheme } from 'styles/theme';

import { SIZE_STYLE } from '../typography/Text/Text.rules';

export type AlertSize = 'fullWidth' | 'small';

export const container: TRuleWithTheme<{ size: AlertSize; textAlign: CSSProperties['textAlign'] }> = ({
    theme,
    size,
    textAlign,
}) => ({
    '&.ant-alert': {
        alignItems: 'flex-start',

        textAlign,

        border: 'none',

        ...(size === 'fullWidth'
            ? {
                  paddingBlock: '0.75rem',
                  paddingInline: '2rem',

                  minHeight: '2.75rem',

                  fontSize: '0.875rem',

                  borderRadius: 0,
              }
            : {
                  paddingBlock: '0.5rem',
                  paddingInline: '0.75rem',

                  marginBottom: '2rem',

                  fontSize: '0.75rem',

                  borderRadius: '0.25rem',
              }),
    },

    '&.ant-alert .ant-alert-content': {
        alignSelf: 'center',
    },

    '&.ant-alert .ant-alert-message': {
        ...SIZE_STYLE['small'],
    },

    '&.ant-alert.ant-alert-info': {
        color: theme.colors.alertsInfoText,
        backgroundColor: theme.colors.alertsInfoBg,
    },

    '&.ant-alert.ant-alert-info .ant-alert-icon': {
        color: theme.colors.alertsInfoText,
    },

    '&.ant-alert.ant-alert-success': {
        color: theme.colors.alertsSuccessText,
        backgroundColor: theme.colors.alertsSuccessBg,
    },

    '&.ant-alert.ant-alert-success .ant-alert-icon': {
        color: theme.colors.alertsSuccessText,
    },

    '&.ant-alert.ant-alert-warning': {
        color: theme.colors.alertsWarningText,
        backgroundColor: theme.colors.alertsWarningBg,
    },

    '&.ant-alert.ant-alert-warning .ant-alert-icon': {
        color: theme.colors.alertsWarningText,
    },

    '&.ant-alert.ant-alert-error': {
        color: theme.colors.alertsErrorText,
        backgroundColor: theme.colors.alertsErrorBg,
    },

    '&.ant-alert.ant-alert-error .ant-alert-icon': {
        color: theme.colors.alertsErrorText,
    },
});
