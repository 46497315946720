import { FormattedMessage, useIntl } from 'react-intl';

import { InfoLabel } from 'modules/forms';
import { Spacer } from 'modules/ui';

import { Fields } from '../../../constants';
import { TextField } from '../TextField';
import { UsernameField } from '../UsernameField';

export const ApiCredentialsFields = () => {
    const { formatMessage } = useIntl();

    return (
        <>
            <UsernameField />
            <div>
                <TextField
                    name={Fields.CREDENTIALS}
                    placeholder={formatMessage({ id: 'secret.credentials.placeholder' })}
                />
                <Spacer space={0.5} />
                <InfoLabel>
                    <FormattedMessage id="secret.credentials.description" />
                </InfoLabel>
            </div>
            <TextField name={Fields.HOSTNAME} placeholder={formatMessage({ id: 'secret.hostname.placeholder' })} />
        </>
    );
};
