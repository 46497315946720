import { FormattedMessage } from 'react-intl';

import { hasReadPermission } from 'utilities/permission';
import { isEmptyOrUndefined } from 'utilities/string';

import { Fields, type SecretType } from '../../../../../../constants';
import type { Secret } from '../../../../../../types';
import { CopySection } from '../CopySection';

export interface ApiCredentialsSectionsProps {
    secret: Extract<Secret, { type: SecretType.ApiCredentials }>;
}

export const ApiCredentialsSections = ({ secret }: ApiCredentialsSectionsProps) => {
    const { username, sensitiveFields } = secret;

    const hasRead = hasReadPermission(secret.userPermissions);

    return (
        <>
            <CopySection
                visible={!isEmptyOrUndefined(username)}
                headingMessageKey="secret.username.label"
                secret={secret}
                property={Fields.USERNAME}
            />
            <CopySection
                visible={sensitiveFields?.includes(Fields.CREDENTIALS)}
                headingMessageKey="secret.credentials.label"
                secret={secret}
                property={Fields.CREDENTIALS}
                mask={<FormattedMessage id="crypted.password" />}
                disabled={!hasRead}
            />
        </>
    );
};
