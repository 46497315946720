import { FormattedMessage } from 'react-intl';

import { hasReadPermission } from 'utilities/permission';
import { isEmptyOrUndefined } from 'utilities/string';
import { CopyButton } from 'modules/ui';

import { TextareaInput } from 'modules/forms';

import { SecretFieldSection } from '../../SecretFieldSection';
import type { Secret } from '../../../types';
import { SecretType } from '../../../constants';
import { EmptyContentGuard } from '../EmptyContentGuard';

export interface SshKeySectionsProps {
    secret: Extract<Secret, { type: SecretType.SshKey }>;
}

export const SshKeySections = ({ secret }: SshKeySectionsProps) => {
    const { userPermissions, privateKey, publicKey } = secret;

    const hasRead = hasReadPermission(userPermissions);

    return (
        <EmptyContentGuard>
            {!isEmptyOrUndefined(privateKey) && (
                <SecretFieldSection heading={<FormattedMessage id="secret.privateKey.label" />}>
                    <CopyButton type="secureTextArea" disabled={!hasRead} text={privateKey}>
                        <TextareaInput readOnly rows={5} value={privateKey} />
                    </CopyButton>
                </SecretFieldSection>
            )}

            {!isEmptyOrUndefined(publicKey) && (
                <SecretFieldSection heading={<FormattedMessage id="secret.publicKey.label" />}>
                    <CopyButton type="secureTextArea" text={publicKey} disabled={!hasRead}>
                        <TextareaInput readOnly rows={5} value={publicKey} />
                    </CopyButton>
                </SecretFieldSection>
            )}
        </EmptyContentGuard>
    );
};
