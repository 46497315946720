import { PasswordField } from '../PasswordField';
import { TOTPField } from '../TOTPField';
import { UsernameField } from '../UsernameField';
import { WebField } from '../WebField';

export const PasswordFields = () => (
    <>
        <WebField />
        <UsernameField />
        <PasswordField />
        <TOTPField />
    </>
);
