import { useEffect } from 'react';

import { useIsAdmin, useUser } from 'modules/auth';
import { useGoogleUser } from 'modules/auth/hooks/useGoogleUser';
import type { GoogleUser } from 'modules/auth/types';
import config from 'config';
import type { User } from 'types';

import { IS_SAAS, IS_DEMO } from '../../../constants';
import type { TidioVisitorProps } from '../types';

const TIDIO_ID = 'tidio-chat-script';

const getTidioVisitorProps = (user: User | null, demoUser?: GoogleUser): TidioVisitorProps => {
    if (config.demo.enable) {
        return demoUser ?? {};
    }

    // eslint-disable-next-line camelcase
    return user ? { name: user.name, email: user.email, distinct_id: user.id } : {};
};

export const useTidio = () => {
    const user = useUser();
    const demoUser = useGoogleUser();
    const isUserAdmin = useIsAdmin();

    const enableTidio = IS_SAAS || IS_DEMO || isUserAdmin || window.Cypress;
    const isSubscriber = !IS_SAAS && !IS_DEMO;

    useEffect(() => {
        if (enableTidio) {
            const tidioVisitorProps = getTidioVisitorProps(user, demoUser);

            // Initial visitor data
            document.tidioIdentify = tidioVisitorProps;

            if (!document.getElementById(TIDIO_ID)) {
                const tidioScript = document.createElement('script');
                tidioScript.id = TIDIO_ID;
                tidioScript.src = `https://code.tidio.co/${config.tidioKey}.js`;
                tidioScript.async = true;
                document.body.appendChild(tidioScript);
            }
        }
    }, [enableTidio, user, demoUser]);

    useEffect(() => {
        if (enableTidio && window.tidioChatApi) {
            const tidioVisitorProps = getTidioVisitorProps(user, demoUser);

            // Update visitor data
            window.tidioChatApi.setVisitorData?.(tidioVisitorProps);
            window.tidioChatApi.setContactProperties?.({
                subscriber: isSubscriber ? 1 : 0,
            });
        }
    }, [user, demoUser, enableTidio, isSubscriber]);

    const show = () => {
        window.tidioChatApi?.open?.();
    };

    const close = () => {
        window.tidioChatApi?.close?.();
    };

    return { show, close };
};
