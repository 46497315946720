import { useFela } from 'react-fela';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { Text } from 'modules/ui';

import { useSecretDetailContext } from '../SecretDetailProvider';

import * as felaRules from './UpdatedAndCreatedDates.rules';

const commonFormattedDateProps = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
} as const satisfies Intl.DateTimeFormatOptions;

export const UpdatedAndCreatedDates = () => {
    const { css } = useFela(felaRules);

    const { updatedAt, createdAt, createdBy } = useSecretDetailContext();

    return (
        <div className={css(felaRules.container)}>
            {createdAt !== updatedAt && (
                <Text size="labelLarge" color="foregroundsTertiary">
                    <FormattedMessage
                        id="secret.updatedAt"
                        values={{
                            date: <FormattedDate value={updatedAt} {...commonFormattedDateProps} />,
                        }}
                    />
                </Text>
            )}
            <Text size="labelLarge" color="foregroundsTertiary">
                <FormattedMessage
                    id="secret.createdAt"
                    values={{
                        date: <FormattedDate value={createdAt} {...commonFormattedDateProps} />,
                        user: createdBy?.name ?? '',
                    }}
                />
            </Text>
        </div>
    );
};
