import { createSelector } from '@reduxjs/toolkit';
import { apiSelector } from '@ackee/redux-utils';

import type { AppState } from 'types';

import { selectEntities } from 'services/selectors';

import { MODULE_PREFIX } from '../constants';

export const selectAuditApi = (state: AppState) => apiSelector(state, MODULE_PREFIX, 'fetch');

const selectAudit = createSelector(selectEntities, entities => entities.securityAudit);

export const selectAuditData = createSelector(selectAudit, audit => audit);
