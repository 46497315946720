import { TRuleWithTheme } from 'styles/theme';

import { Fields } from '../../../../constants';

export const inputGroup: TRuleWithTheme = () => ({
    [`& [name="${Fields.CARD_NUMBER}"]`]: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,

        marginBottom: '-1px',

        ':hover, :focus': {
            zIndex: 1,
        },
    },

    [`& .ant-space-compact [name="${Fields.EXPIRATION_DATE}"]`]: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: '0.25rem',
    },

    [`& .ant-space-compact [name="${Fields.CVV_CODE}"]`]: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: '0.25rem',
    },
});
