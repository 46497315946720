import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import type { ReactNode } from 'react';
import { useController } from 'react-hook-form';

import { FileInput, FormField, InfoLabel } from 'modules/forms';
import { actions as messagesActions } from 'modules/messages';
import { Link } from 'modules/ui';
import { config } from 'config/config';

import { ImportFormValues } from 'modules/import-export/schemas';

import { SUPPORTED_FILE_MIME, Fields } from '../../../constants';

const name = Fields.SECRETS;

export const SecretsField = () => {
    const {
        field: { value, onChange },
        formState: { errors },
    } = useController<ImportFormValues, typeof name>({ name });

    const dispatch = useDispatch();

    const label = (
        <InfoLabel>
            <FormattedMessage
                id="import.moreInfo"
                values={{
                    a: (parts: ReactNode[]) => (
                        <Link size="small" type="native" href={config.links.import} inheritColor>
                            {parts}
                        </Link>
                    ),
                }}
            />
        </InfoLabel>
    );

    return (
        <FormField name={name} label={label}>
            <FileInput
                file={value}
                hasError={Boolean(errors.secrets)}
                onFile={(file, action) => {
                    if (action === 'dropped') {
                        onChange(file);
                    } else {
                        if (action === 'unsupported') {
                            dispatch(
                                messagesActions.displayWarningMessage({
                                    message: {
                                        id: 'import.unsupportedFile',
                                    },
                                }),
                            );
                        }
                        onChange(null);
                    }
                }}
                accept={SUPPORTED_FILE_MIME}
            />
        </FormField>
    );
};
