import { useFela } from 'react-fela';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { MessageKey } from 'modules/localizations';
import { Heading } from 'modules/ui';

import passwdIpad from '../../assets/passwd-ipad.jpg';

import * as felaRules from './ReviewPromptLayout.rules';

export interface ReviewPromptLayoutProps {
    showImage: boolean;
    title: MessageKey;
    content: ReactNode;
    buttons?: ReactNode;
}

export const ReviewPromptLayout = ({ showImage, title, content, buttons }: ReviewPromptLayoutProps) => {
    const { css } = useFela();

    return (
        <div className={css(felaRules.container)}>
            {showImage && <img className={css(felaRules.image)} src={passwdIpad} alt="" />}
            <Heading size="regular" elementType="h2" color="black">
                <FormattedMessage id={title} />
            </Heading>
            <div>{content}</div>
            {Boolean(buttons) && <div className={css(felaRules.buttonsContainer)}>{buttons}</div>}
        </div>
    );
};
