import type { PropsWithChildren } from 'react';

import type { Secret } from '../../../types';
import { useSecretDetail } from '../../../hooks';

import { SecretDetailContext } from './context';

export interface SecretDetailProviderProps extends PropsWithChildren {
    id: Secret['id'];
}

export const SecretDetailProvider = ({ children, id }: SecretDetailProviderProps) => {
    const detail = useSecretDetail(id);

    return <SecretDetailContext.Provider value={detail ?? null}>{children}</SecretDetailContext.Provider>;
};
