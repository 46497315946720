import { FormattedMessage } from 'react-intl';

import { hasReadPermission } from 'utilities/permission';
import { isEmptyOrUndefined } from 'utilities/string';
import { CopyButton } from 'modules/ui';

import { TextareaInput } from 'modules/forms';

import { SecretFieldSection } from '../../SecretFieldSection';
import type { Secret } from '../../../types';
import { SecretType } from '../../../constants';
import { EmptyContentGuard } from '../EmptyContentGuard';

export interface SecureNoteSectionsProps {
    secret: Extract<Secret, { type: SecretType.SecureNote }>;
}

export const SecureNoteSections = ({ secret }: SecureNoteSectionsProps) => {
    const { userPermissions, secureNote } = secret;

    const hasRead = hasReadPermission(userPermissions);

    return (
        <EmptyContentGuard>
            {!isEmptyOrUndefined(secureNote) && (
                <SecretFieldSection heading={<FormattedMessage id="secret.secureNote.label" />}>
                    <CopyButton type="secureTextArea" disabled={!hasRead} text={secureNote}>
                        <TextareaInput readOnly rows={5} value={secureNote} />
                    </CopyButton>
                </SecretFieldSection>
            )}
        </EmptyContentGuard>
    );
};
