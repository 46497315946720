import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Modal } from 'modules/modals';

import type { ViewFilter } from '../../types';

import { SecretsViewForm } from '../SecretsViewForm';

export interface SecretsViewModalProps {
    open: boolean;
    defaultViewFilter: ViewFilter | null;
    onSubmit: (view: ViewFilter | null) => void;
    onCancel: () => void;
}

export const SecretsViewModal = ({ open, defaultViewFilter, onSubmit, onCancel }: SecretsViewModalProps) => {
    const [viewFilter, setViewFilter] = useState<ViewFilter | null>(defaultViewFilter);

    useEffect(() => {
        if (!open) {
            setViewFilter(defaultViewFilter);
        }
    }, [open, defaultViewFilter]);

    return (
        <Modal
            open={open}
            okButton={{
                onClick: () => {
                    onSubmit(viewFilter);
                },
                disabled: defaultViewFilter?.email === viewFilter?.email,
                children: <FormattedMessage id="secrets.view.modal.submit" />,
            }}
            cancelButton={{
                onClick: onCancel,
                children: <FormattedMessage id="secrets.view.modal.cancel" />,
            }}
            closable
            width={474}
            alignButtonsRight
        >
            <SecretsViewForm
                viewFilter={viewFilter}
                onChange={setViewFilter}
                onReset={() => {
                    setViewFilter(defaultViewFilter);
                }}
            />
        </Modal>
    );
};
