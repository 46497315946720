import { schema, normalize } from 'normalizr';
import { merge, omit } from 'lodash';

import { isEmptyOrUndefined } from 'utilities/string';

import { SecretType, SENSITIVE_DATA_KEYS } from '../constants';
import { redactPaymentCardNumber } from '../utilities';
import type { EntityKey, Secret, SecretById } from '../types';
import { secretTypeToDefaultValues } from '../constants/defaultValues';

export const preprocessSecret = (originalSecret: Secret) => {
    const secret = { ...originalSecret };

    if (originalSecret.type === undefined) {
        secret.type = SecretType.Password;
    }

    if (secret.type === SecretType.PaymentCard && !isEmptyOrUndefined(secret.cardNumber)) {
        secret.redactedCardNumber = redactPaymentCardNumber(secret.cardNumber);
    }

    return secret;
};
export const omitSensitiveKeys = (secret: Secret) => omit(secret, SENSITIVE_DATA_KEYS);

const secretSchema = new schema.Entity<Secret>(
    'secrets',
    {},
    {
        processStrategy: (entity: Secret) =>
            merge(
                { userPermissions: [], share: null },
                secretTypeToDefaultValues[entity.type],
                preprocessSecret(entity),
            ),
    },
);
const secretListSchema = new schema.Array<Secret>(secretSchema);

export const normalizeSecret = (data: any) =>
    normalize<
        Secret,
        {
            [EntityKey.SECRETS]: SecretById;
        },
        Secret['id']
    >(data, secretSchema);

export const normalizeSecrets = (data: any[]) =>
    normalize<
        Secret,
        {
            [EntityKey.SECRETS]: SecretById;
        },
        Secret['id'][]
    >(data, secretListSchema);
