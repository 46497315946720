import { useFela } from 'react-fela';

import { Icon, IconProps, IconType } from 'modules/ui';

import { SecretType } from '../../constants';

import * as felaRules from './SecretTypeIcon.rules';

export interface SecretTypeIconProps extends Omit<IconProps, 'type'> {
    type: SecretType;
}

const secretTypeToIconType = {
    [SecretType.Password]: IconType.SECRET_TYPE_PASSWORD,
    [SecretType.PaymentCard]: IconType.SECRET_TYPE_PAYMENT_CARD,
    [SecretType.ApiCredentials]: IconType.SECRET_TYPE_API_CREDENTIALS,
    [SecretType.DatabaseCredentials]: IconType.SECRET_TYPE_DATABASE,
    [SecretType.SshKey]: IconType.SECRET_TYPE_SSH_KEY,
    [SecretType.SecureNote]: IconType.SECRET_TYPE_SECURE_NOTE,
};

export const SecretTypeIcon = ({ type, ...rest }: SecretTypeIconProps) => {
    const { css } = useFela({ type });

    return (
        <span className={css(felaRules.icon)}>
            <Icon type={secretTypeToIconType[type]} {...rest} />
        </span>
    );
};
