import { useDispatch } from 'react-redux';
import type { DefaultValues } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { useUser } from 'modules/auth';
import { actions as messagesActions } from 'modules/messages';
import { logger } from 'config/logger';
import config from 'config';

import type { ReviewPromptFormValues } from '../schema';

export interface UseSubmitReviewPromptFormParams {
    onSubmitSuccess: () => void;
}

const defaultValues: DefaultValues<ReviewPromptFormValues> = {
    name: '',
    email: '',
    tocAgreement: false,
    trustpilotVisited: false,
    g2Visited: false,
};

export const useSubmitReviewPromptForm = ({ onSubmitSuccess }: UseSubmitReviewPromptFormParams) => {
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();

    const loggedInUser = useUser();
    const passwdInstance = window.location.hostname;

    const handleSubmit = async (formValues: ReviewPromptFormValues) => {
        const body = JSON.stringify({ ...formValues, loggedInUser, passwdInstance });

        try {
            const res = await fetch(config.formsparkLinks.reviewPrompt, {
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });

            if (!res.ok) {
                throw new Error();
            }

            onSubmitSuccess();
        } catch (error) {
            dispatch(
                messagesActions.displayErrorMessage({
                    message: formatMessage({ id: 'review-prompt.form.submit.error' }),
                    options: { duration: 5 },
                }),
            );
            logger.error(error);
        }
    };

    return { handleSubmit, defaultValues };
};
