import type { ErrorOption, FieldErrors, FieldValues } from 'react-hook-form';
import type { useIntl } from 'react-intl';
import type { ZodArrayDef, ZodDiscriminatedUnionDef, ZodObjectDef, ZodRecordDef, ZodTypeAny } from 'zod';

import { isMessageKey } from 'modules/localizations';

import { isArrayDef } from './isArrayDef';
import { isObjectDef } from './isObjectDef';
import { isRecordDef } from './isRecordDef';
import { unwrapPossibleZodEffectsDef } from './unwrapPossibleZodEffectsDef';

interface TranslateUtils {
    formatMessage: ReturnType<typeof useIntl>['formatMessage'];
}

function possiblyTranslateMessageKey(message: string, { formatMessage }: TranslateUtils) {
    if (isMessageKey(message)) {
        return formatMessage({ id: message });
    }

    if (message.includes('|')) {
        const [id, values] = message.split('|');
        if (isMessageKey(id)) {
            const parsedValues = JSON.parse(values);

            return formatMessage({ id }, parsedValues);
        }
    }

    return null;
}

export function possiblyTranslate(value: ErrorOption, { formatMessage }: TranslateUtils) {
    if (value && 'message' in value && typeof value.message === 'string') {
        const translation = possiblyTranslateMessageKey(value.message, { formatMessage });

        if (translation) {
            return {
                ...value,
                message: translation,
            };
        }
    }

    return value;
}

function translateInnerSchemaErrors(def: ZodTypeAny, errors: any, utils: TranslateUtils): object {
    const unwrappedDef = unwrapPossibleZodEffectsDef(def);

    if (isObjectDef(unwrappedDef)) {
        return translateObjectErrors(unwrappedDef, errors, utils);
    }
    if (isRecordDef(unwrappedDef)) {
        return translateRecordErrors(unwrappedDef, errors, utils);
    }
    if (isArrayDef(unwrappedDef)) {
        return translateArrayErrors(unwrappedDef, errors, utils);
    }

    return errors;
}

function translateErrors(
    errors: FieldErrors,
    utils: TranslateUtils,
    getInnerDefOrNull: (key: string) => ZodTypeAny | null,
) {
    const entries = Object.entries(errors).map(([key, value]) => {
        if (typeof value !== 'object') {
            return [key, value];
        }
        const innerDef = getInnerDefOrNull(key);

        if (innerDef) {
            value = translateInnerSchemaErrors(innerDef, value, utils);
        }

        // @ts-expect-error
        return [key, possiblyTranslate(value, utils)];
    });

    return Object.fromEntries(entries);
}

function translateArrayErrors(def: ZodArrayDef, errors: FieldErrors, utils: TranslateUtils) {
    return translateErrors(errors, utils, key => {
        const isKey = !isNaN(Number(key));

        if (isKey) {
            return def.type._def;
        }

        return null;
    });
}

export function translateRecordErrors(def: ZodRecordDef, errors: FieldErrors, utils: TranslateUtils) {
    return translateErrors(errors, utils, key => {
        const isKey = def.keyType.safeParse(key).success;

        if (isKey) {
            return def.valueType._def;
        }

        return null;
    });
}

export function translateObjectErrors(def: ZodObjectDef, errors: FieldErrors, utils: TranslateUtils) {
    const shape = def.shape();

    return translateErrors(errors, utils, key => shape[key]?._def ?? null);
}

export function translateDiscriminatedUnionErrors(
    def: ZodDiscriminatedUnionDef<string>,
    errors: FieldErrors,
    values: FieldValues,
    utils: TranslateUtils,
) {
    const discriminatorKey: string = values[def.discriminator];

    const shapeDef = def.optionsMap.get(discriminatorKey);

    const shape = shapeDef?._def.shape();

    return translateErrors(errors, utils, key => shape?.[key]?._def ?? null);
}
