import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'none',

    min1024: {
        display: 'block',
        width: '100%',
    },
});
