import { useParams } from 'react-router-dom-v5-compat';
import { useFela } from 'react-fela';

import { useSecretDetail } from 'modules/secrets/hooks';

import { Fields } from '../../../constants';
import { TextField } from '../TextField';
import { SecretTypeIcon } from '../../SecretTypeIcon';

import * as felaRules from './NameFieldWithFavicon.rules';
import { WebsiteFavicon } from '../../WebsiteFavicon';

export enum NameFieldType {
    NEW_SECRET = 'new',
    UPDATE_SECRET = 'update',
}

export interface NameFieldProps {
    type: `${NameFieldType}`;
}

export const NameFieldWithFavicon = ({ type }: NameFieldProps) => {
    const { css } = useFela();
    const { id } = useParams<{
        id: string;
    }>();

    const detail = useSecretDetail(id ?? '');

    const isInUpdateForm = type === 'update' && detail !== undefined;

    const favicon = isInUpdateForm && 'favicon' in detail && detail.favicon ? detail.favicon : null;

    return (
        <div className={css(felaRules.fieldAndFaviconContainer)}>
            {isInUpdateForm ? (
                favicon ? (
                    <WebsiteFavicon base64Favicon={favicon} name={detail.name} width={28} height={28} />
                ) : (
                    <SecretTypeIcon type={detail.type} width={28} height={28} />
                )
            ) : null}
            <TextField
                name={Fields.NAME}
                hideLabel={isInUpdateForm}
                required={!isInUpdateForm}
                extend={{
                    formItem: felaRules.largeFormItem,
                    input: isInUpdateForm ? felaRules.largeInput : undefined,
                }}
            />
        </div>
    );
};
