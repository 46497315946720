import { FormattedMessage, useIntl } from 'react-intl';
import { useFela } from 'react-fela';
import { Prompt } from 'react-router';
import { useFormContext } from 'react-hook-form';

import { Alert, Button } from 'modules/ui';
import { useScrollToFirstError } from 'modules/forms';

import { SecretTypeField } from '../SecretForm/SecretTypeField';
import { MainInformationFields } from '../SecretForm/MainInformationFields';
import { FileField } from '../SecretForm/FileField';
import { GroupsField } from '../SecretForm/GroupsField';
import { NameFieldWithFavicon } from '../SecretForm/NameFieldWithFavicon';
import { NoteField } from '../SecretForm/NoteField';
import { TagsField } from '../SecretForm/TagsField';
import { WhitelistField } from '../SecretForm/WhitelistField';
import { SecretSection } from '../SecretSection';
import { useHandleBackButtonClick } from '../BackButton/hooks';
import { GroupsAndPermissionsInfoLabel } from '../GroupsAndPermissionsInfoLabel';

import { MAX_SAAS_SECRETS_COUNT_ERROR_TYPE } from '../../constants';
import type { SecretFormValues } from '../../schemas';
import { useResetDefaultValuesOnSecretTypeChange } from './hooks';

import * as felaRules from './NewSecretForm.rules';

export const NewSecretForm = () => {
    const { css } = useFela();
    const { formatMessage } = useIntl();

    const {
        formState: { isDirty, isSubmitting, errors },
    } = useFormContext<SecretFormValues>();

    useResetDefaultValuesOnSecretTypeChange();
    useScrollToFirstError();
    const handleCancel = useHandleBackButtonClick();

    const submitError = errors.root;
    const isSaasLimitReachedError = submitError?.type === MAX_SAAS_SECRETS_COUNT_ERROR_TYPE;

    return (
        <>
            <div className={css(felaRules.sectionsContainer)}>
                {submitError && (
                    <Alert
                        message={submitError.message}
                        type="error"
                        customStyle={felaRules.errorAlert}
                        data-testid={isSaasLimitReachedError ? 'saas-limit-reached-alert' : 'submit-error-alert'}
                    />
                )}

                <SecretSection title="secret.sections.mainInformation.title">
                    <SecretTypeField />
                    <NameFieldWithFavicon type="new" />

                    <MainInformationFields />
                </SecretSection>
                <SecretSection title="secret.sections.permissions.title" gap={0.75}>
                    <GroupsAndPermissionsInfoLabel />
                    <GroupsField />
                    <WhitelistField />
                </SecretSection>
                <SecretSection title="secret.sections.details.title">
                    <TagsField />
                    <FileField />
                    <NoteField />
                </SecretSection>
            </div>

            <div className={css(felaRules.buttonsContainer)}>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={isSubmitting}
                    disabled={isSubmitting || isSaasLimitReachedError}
                >
                    <FormattedMessage id="secret.add" />
                </Button>
                <Button type="text" size="large" onClick={handleCancel}>
                    <FormattedMessage id="cancel" />
                </Button>
            </div>

            <Prompt when={isDirty && !isSubmitting} message={formatMessage({ id: 'secret.form.unsavedWarning' })} />
        </>
    );
};
