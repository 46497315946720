import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1.25rem',
});

export const image: TRuleWithTheme = () => ({
    marginBottom: '0.75rem',
});

export const buttonsContainer: TRuleWithTheme = () => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    columnGap: '1.25rem',
    rowGap: '0.5rem',
    alignItems: 'center',

    marginTop: '0.75rem',
});
