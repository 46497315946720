import { FormattedMessage } from 'react-intl';
import { useController, useFormContext } from 'react-hook-form';

import { FormField, InfoLabel, Label, TextInput } from 'modules/forms';
import { IconButton, Spacer } from 'modules/ui';
import { useIsFreeTier } from 'modules/settings';
import { useSubscriptionUpgradeModal, SubscriptionRequiredAlert } from 'modules/saas-upgrade';

import type { SecretFormValues } from '../../../schemas';

import { Fields } from '../../../constants';
import { SecretTOTP } from '../../SecretTOTP';
import { TOTPFromQRCodeButton, isTOTPError } from '../../TOTPFromQRCodeButton';

import * as felaRules from './TOTPField.rules';

const name = Fields.TOTP;

export const TOTPField = () => {
    const isFreeTier = useIsFreeTier();

    const {
        field: { value, onBlur, onChange },
    } = useController({
        name,
    });

    const { watch, formState, setValue } = useFormContext<SecretFormValues>();

    const hasTOTP = watch(Fields.HAS_TOTP);

    const { openUpgradeModal } = useSubscriptionUpgradeModal();

    return (
        <div>
            <FormField
                htmlFor={name}
                label={
                    <Label customStyle={felaRules.label}>
                        <FormattedMessage id="secret.TOTP.label" />
                        {isFreeTier && (
                            <IconButton
                                type="ghost"
                                icon={{ type: 'premium', color: 'alertsWarningText' }}
                                onClick={openUpgradeModal}
                            />
                        )}
                    </Label>
                }
                name={name}
            >
                {hasTOTP ? (
                    <SecretTOTP
                        onRemove={() => {
                            onChange(null);
                            setValue(Fields.HAS_TOTP, false);
                        }}
                    />
                ) : (
                    <>
                        <TextInput
                            data-testid="totp-input"
                            id={name}
                            value={isTOTPError(value) ? null : value}
                            onBlur={onBlur}
                            disabled={formState.isSubmitting}
                            readOnly={isFreeTier}
                            onClick={isFreeTier ? () => openUpgradeModal() : undefined}
                            suffix={
                                <TOTPFromQRCodeButton
                                    onChange={qrCodeContent => {
                                        onChange({ target: { value: qrCodeContent } });
                                        onBlur();
                                    }}
                                />
                            }
                            onChange={e => {
                                if (e.target.value.trim().length === 0) {
                                    onChange(null);
                                } else {
                                    onChange(e);
                                }
                            }}
                            {...(isFreeTier && { customStyle: felaRules.readOnlyInput })}
                        />
                    </>
                )}
            </FormField>
            <Spacer space={0.62} />
            {isFreeTier ? (
                <SubscriptionRequiredAlert message="secret.TOTP.upgrade" />
            ) : (
                <InfoLabel>
                    {hasTOTP ? (
                        <FormattedMessage id="secret.TOTP.help.hasTOTP" />
                    ) : (
                        <FormattedMessage id="secret.TOTP.help" />
                    )}
                </InfoLabel>
            )}
        </div>
    );
};
