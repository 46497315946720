import { useCallback } from 'react';

import { StaticModal, useModal } from 'modules/modals';

export const useSubscriptionUpgradeModal = () => {
    const { modal, updateModal } = useModal(StaticModal.SUBSCRIPTION_UPGRADE);

    const openUpgradeModal = useCallback(() => {
        updateModal(true);
    }, [updateModal]);

    const closeUpgradeModal = useCallback(() => {
        updateModal(false);
    }, [updateModal]);

    return {
        openUpgradeModal,
        closeUpgradeModal,
        isOpen: modal?.visibility ?? false,
    };
};
