import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',
});

export const cryptedCharacters: TRuleWithTheme = ({ theme }) => ({
    maxWidth: '16rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    ...theme.typography.Crypted,
});
