import { useFela } from 'react-fela';
import { Navigate, useMatch } from 'react-router-dom-v5-compat';

import config from 'config';

import { AdminFirewall } from 'modules/auth';

import { SecurityAuditSecretsTabs } from '../SecurityAuditSecretsTabs';

import { SecurityAuditReportOverview } from '../SecurityAuditReportOverview';
import { IgnoreSecurityAuditReportModal } from '../IgnoreSecurityAuditReportModal';

import { SecurityAuditReportSecrets } from '../SecurityAuditReportSecrets';
import { SecurityAuditLevelSecrets } from '../SecurityAuditLevelSecrets';
import { SecurityAuditAgeSecrets } from '../SecurityAuditAgeSecrets';
import { SecurityAuditLeaksSecrets } from '../SecurityAuditLeaksSecrets';

import * as felaRules from './SecurityAuditSecretsPage.rules';

export const SecurityAuditSecretsPage = () => {
    const { css } = useFela();

    const isReportPage = useMatch(config.routes.securityAudit);
    const isLevelPage = useMatch(config.routes.securityAuditLevel);
    const isAgePage = useMatch(config.routes.securityAuditAge);
    const isLeaksPage = useMatch(config.routes.securityAuditLeaks);

    return (
        <AdminFirewall firewall={<Navigate to={config.routes.home} replace />}>
            <div className={css(felaRules.container)}>
                <SecurityAuditSecretsTabs />

                {isReportPage && (
                    <>
                        <IgnoreSecurityAuditReportModal />
                        <SecurityAuditReportOverview />
                    </>
                )}

                <div className={css(felaRules.listContainer)}>
                    {isReportPage && <SecurityAuditReportSecrets />}
                    {isLevelPage && <SecurityAuditLevelSecrets />}
                    {isAgePage && <SecurityAuditAgeSecrets />}
                    {isLeaksPage && <SecurityAuditLeaksSecrets />}
                </div>
            </div>
        </AdminFirewall>
    );
};
