import { FormattedMessage } from 'react-intl';
import { useFela } from 'react-fela';
import tinycolor from 'tinycolor2';

import type { Theme } from 'styles/theme';

import { useSecurityAuditAgeReports } from 'modules/secrets';

import { SecurityAuditGraph } from '../SecurityAuditGraph';

export const SecurityAuditAgeGraph = () => {
    const reports = useSecurityAuditAgeReports();

    const { theme } = useFela<Theme>();

    const levelColors = [
        tinycolor(theme.colors.blue).setAlpha(0.8).toString(),
        tinycolor(theme.colors.blue).setAlpha(0.6).toString(),
        theme.colors.blue,
    ];

    return (
        <SecurityAuditGraph
            reports={reports}
            columnKey={report => `${report.age}`}
            columnLabel={report => <FormattedMessage id={`password.age.${report.age}`} />}
            columnColor={report => levelColors[report.age]}
        />
    );
};
