import { FormField, type FormFieldProps } from '../FormField';

import { Label } from '../Label';
import { LabelSize } from '../Label/Label.rules';

import { SettingsItem } from '../SettingsItem';

import * as felaRules from './SettingsFormItem.rules';

export interface SettingsFormItemProps extends FormFieldProps {}

export function SettingsFormItem({ label, name, children }: SettingsFormItemProps) {
    return (
        <SettingsItem>
            <FormField
                name={name}
                label={
                    <Label size={LabelSize.LARGE} customStyle={felaRules.label}>
                        {label}
                    </Label>
                }
                customStyle={felaRules.container}
            >
                {children}
            </FormField>
        </SettingsItem>
    );
}
