import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import { Alert } from 'modules/ui';

import { ErrorCode } from 'services/utilities/isApiError';

import * as felaRules from './LoginErrorAlert.rules';

export interface LoginErrorAlertProps {
    errorCode: string | null;
}

export const LoginErrorAlert = ({ errorCode }: LoginErrorAlertProps) => {
    const { css } = useFela(felaRules);

    switch (errorCode) {
        case ErrorCode.CONFIGURED_SUPER_ADMIN_USER_DOESNT_EXIST_IN_THE_WORKSPACE:
        case ErrorCode.CONFIGURED_SUPER_ADMIN_IS_NOT_AN_ADMIN:
            return (
                <Alert
                    type="error"
                    message={<FormattedMessage id="error.auth.invalid-admin" />}
                    rootClassName={css(felaRules.root)}
                />
            );

        default:
            return null;
    }
};
