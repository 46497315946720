import { useEffect, useRef } from 'react';
import { VariableSizeList } from 'react-window';
import { intersection, pick, union } from 'lodash';

import { useBreakpoint } from 'hooks/useBreakpoint';
import { isEmptyOrUndefined } from 'utilities/string';
import type { KeysOfUnion } from 'types/utils';

import { Fields, SecretListId } from '../../constants';
import { useSharingIndicatorVisibility, useSecretsListOffset } from '../../hooks';
import type { Secret } from '../../types';

import { useSecretsList } from '../../hooks/useSecretsList';

import { ItemRenderer } from './ItemRenderer';

export const SECRET_LIST_ITEM_SIZES = {
    base: 94,
    propertyRowSize: 44,
    sharingIndicator: 38.875,
    tags: 36,
};

const DISPLAYED_PROPERTIES = [
    Fields.USERNAME,
    Fields.PASSWORD,
    Fields.HAS_TOTP,
    Fields.REDACTED_CARD_NUMBER,
    Fields.EXPIRATION_DATE,
    Fields.CARDHOLDER_NAME,
    Fields.CVV_CODE,
    Fields.PUBLIC_KEY,
    Fields.PRIVATE_KEY,
    Fields.CREDENTIALS,
    Fields.SECURE_NOTE,
] as const satisfies Array<KeysOfUnion<Secret>>;

export interface SecretsListProps {
    height: number;
    width: number;
}

export const SecretsList = ({ height, width }: SecretsListProps) => {
    const { data } = useSecretsList();

    const isMin768 = useBreakpoint('sm');
    const listRef = useRef<VariableSizeList<typeof data>>(null);

    const handleOffset = useSecretsListOffset(SecretListId.MAIN_LIST, listRef);

    const { getSharingIndicatorVisibility } = useSharingIndicatorVisibility();

    useEffect(() => {
        listRef.current?.resetAfterIndex(0);
    }, [isMin768, data]);

    return (
        <VariableSizeList<typeof data>
            ref={listRef}
            height={height}
            width={width}
            itemData={data}
            itemCount={data.length}
            estimatedItemSize={
                isMin768
                    ? SECRET_LIST_ITEM_SIZES.base + SECRET_LIST_ITEM_SIZES.propertyRowSize
                    : SECRET_LIST_ITEM_SIZES.base + SECRET_LIST_ITEM_SIZES.propertyRowSize * 5
            }
            itemSize={index => {
                const item = data[index];
                const sensitiveFields = item.sensitiveFields ?? [];

                const displayedPropertiesLength = union(
                    Object.entries(pick(item, DISPLAYED_PROPERTIES))
                        .filter(
                            ([, value]) => (typeof value === 'string' && !isEmptyOrUndefined(value)) || value === true,
                        )
                        .map(([key]) => key as Fields),
                    intersection(sensitiveFields, DISPLAYED_PROPERTIES),
                ).length;

                const isSharingIndicatorVisible = getSharingIndicatorVisibility({
                    share: item.share,
                    userPermissions: item.userPermissions,
                });

                const hasTags = item.tags ? item.tags.length > 0 : false;

                let itemSize = SECRET_LIST_ITEM_SIZES.base;
                if (isMin768) return itemSize + SECRET_LIST_ITEM_SIZES.propertyRowSize;

                itemSize += displayedPropertiesLength * SECRET_LIST_ITEM_SIZES.propertyRowSize;
                if (isSharingIndicatorVisible) itemSize += SECRET_LIST_ITEM_SIZES.sharingIndicator;
                if (hasTags) itemSize += SECRET_LIST_ITEM_SIZES.tags;

                return itemSize;
            }}
            itemKey={index => {
                const item = data[index];
                return item.key;
            }}
            onScroll={({ scrollOffset }) => handleOffset(scrollOffset)}
        >
            {/* @ts-expect-error */}
            {ItemRenderer}
        </VariableSizeList>
    );
};
