import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',

    gap: '0.62rem',
});
export const heading: TRuleWithTheme = () => ({
    textOverflow: 'ellipsis',
    overflow: 'hidden',

    paddingBottom: '0.125rem',
    marginTop: 0,
});
