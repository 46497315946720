import { FormattedMessage, useIntl } from 'react-intl';

import { FormField, InfoLabel, Label, TextareaInput } from 'modules/forms';
import { Spacer } from 'modules/ui';

import { Fields } from '../../../constants';

export interface SshKeyFieldsProps {}

const FIELDS = [Fields.PRIVATE_KEY, Fields.PUBLIC_KEY] as const;

export const SshKeyFields = () => {
    const { formatMessage } = useIntl();

    return (
        <>
            {FIELDS.map(field => (
                <div key={field}>
                    <FormField
                        label={
                            <Label>
                                <FormattedMessage id={`secret.${field}.label`} />
                            </Label>
                        }
                        name={field}
                        htmlFor={field}
                    >
                        <TextareaInput
                            name={field}
                            placeholder={formatMessage({ id: `secret.${field}.placeholder` })}
                            rows={5}
                            id={field}
                        />
                    </FormField>

                    {field === Fields.PUBLIC_KEY && (
                        <>
                            <Spacer space={0.5} />
                            <InfoLabel>
                                <FormattedMessage id={`secret.${field}.description`} />
                            </InfoLabel>
                        </>
                    )}
                </div>
            ))}
        </>
    );
};
