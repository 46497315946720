import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { IS_DEMO } from 'constants/index';
import { useAppSelector } from 'hooks/useAppSelector';

import { loadOrder } from '../services/actions';
import { selectOrderApi, selectOrderInfo } from '../services/selectors';

export const useFetchOrder = () => {
    const api = useAppSelector(selectOrderApi);
    const order = useAppSelector(selectOrderInfo);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!IS_DEMO) {
            dispatch(loadOrder());
        }
    }, [dispatch]);

    return {
        api,
        order,
    };
};
