import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFela } from 'react-fela';
import tinycolor from 'tinycolor2';

import config from 'config';

import type { Theme } from 'styles/theme';

import { Link, Text } from 'modules/ui';

import { UP_TO_LEAKS, useSecurityAuditLeaksReports } from 'modules/secrets';

import { SecurityAuditGraph } from '../SecurityAuditGraph';

export const SecurityAuditLeaksGraph = () => {
    const reports = useSecurityAuditLeaksReports();

    const { theme } = useFela<Theme>();

    const levelColors = [
        tinycolor(theme.colors.blue).setAlpha(0.8).toString(),
        tinycolor(theme.colors.blue).setAlpha(0.6).toString(),
        theme.colors.blue,
    ];

    return (
        <SecurityAuditGraph
            reports={reports}
            columnKey={report => `${report.leaks}`}
            columnLabel={report => (
                <FormattedMessage
                    id={`password.leaks.${report.leaks}`}
                    values={{
                        upToLeaks: UP_TO_LEAKS,
                    }}
                />
            )}
            columnColor={report => levelColors[report.leaks]}
            label={
                <Text size="small">
                    <FormattedMessage
                        id="securityAudit.leaks.info"
                        values={{
                            br: <br />,
                            a: (parts: ReactNode[]) => (
                                <Link size="small" type="native" href={config.links.leaks}>
                                    {parts}
                                </Link>
                            ),
                        }}
                    />
                </Text>
            }
        />
    );
};
