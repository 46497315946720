import { useFela } from 'react-fela';

import { Text, TextTag } from 'modules/ui';

import type { Secret } from '../../../../types';

import * as felaRules from './SecretItemTags.rules';

const MAX_TAGS = 3;
const ELEMENT_TYPE = 'span';

export interface SecretItemTagsProps {
    tags: Secret['tags'];
}

export const SecretItemTags = ({ tags }: SecretItemTagsProps) => {
    const { css } = useFela();

    if (!tags || tags.length === 0) {
        return null;
    }

    return (
        <div className={css(felaRules.container)}>
            {[...tags]
                .sort()
                .slice(0, MAX_TAGS)
                .map(tag => (
                    <TextTag key={tag} elementType={ELEMENT_TYPE}>
                        {tag}
                    </TextTag>
                ))}

            {tags.length > MAX_TAGS && (
                <Text size="small" elementType={ELEMENT_TYPE} color="foregroundsTertiary">
                    +{tags.length - MAX_TAGS}
                </Text>
            )}
        </div>
    );
};
