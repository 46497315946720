import { forwardRef } from 'react';
import { useFela } from 'react-fela';
import classNames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';

import { Input } from 'antd';
import type { InputProps, InputRef } from 'antd/es/input';

import { style } from './TextInput.rules';

export interface TextInputProps extends InputProps {
    customStyle?: typeof style;
    name?: string;
    transformValue?: (value: string) => string;
}

export const TextInput = forwardRef<InputRef, TextInputProps>(
    ({ customStyle, name, onChange, onBlur, transformValue, ...props }, forwardedRef) => {
        const { css } = useFela();
        const context = useFormContext();

        if (!context || !name) {
            return (
                <Input
                    name={name}
                    ref={forwardedRef}
                    onChange={e => {
                        if (transformValue) {
                            e.target.value = transformValue(e.target.value);
                        }

                        onChange?.(e);
                    }}
                    onBlur={onBlur}
                    {...props}
                    className={classNames(css(style, customStyle), props.className)}
                />
            );
        }

        return (
            <Controller
                name={name}
                render={({ field: { onChange: hookFormOnChange, onBlur: hookFormOnBlur, value } }) => (
                    <Input
                        ref={forwardedRef}
                        name={name}
                        value={value}
                        onChange={e => {
                            if (transformValue) {
                                e.target.value = transformValue(e.target.value);
                            }

                            hookFormOnChange(e);

                            onChange?.(e);
                        }}
                        onBlur={e => {
                            hookFormOnBlur();

                            onBlur?.(e);
                        }}
                        {...props}
                        className={classNames(css(style, customStyle), props.className)}
                    />
                )}
            />
        );
    },
);
