import { useHasGlobalSharingPermissions } from 'modules/settings';
import { hasReadPermission } from 'utilities/permission';

import { isPublicSharingActive } from '../../../utilities';
import { useSecretDetailContext } from '../SecretDetailProvider';

export const useIsPublicSharingActiveWithPermissions = () => {
    const { share, userPermissions } = useSecretDetailContext();

    const isSharingActive = isPublicSharingActive(share);
    const hasGlobalSharingPermissions = useHasGlobalSharingPermissions();
    const hasSecretReadPermissions = hasReadPermission(userPermissions);

    return isSharingActive && hasGlobalSharingPermissions && hasSecretReadPermissions;
};
