import { useIntl } from 'react-intl';

import { possiblyTranslate } from '../utils';
import { SubmitError } from '../types';

export const useTranslateSubmitError = <Values extends {}>() => {
    const { formatMessage } = useIntl();

    const translate = (...[name, error, options]: SubmitError<Values>) => {
        const translatedError = possiblyTranslate(error, { formatMessage });
        return [name, translatedError, options] as const satisfies SubmitError<Values>;
    };

    return translate;
};
