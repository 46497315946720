import { FormattedMessage } from 'react-intl';

import { hasReadPermission } from 'utilities/permission';
import { isEmptyOrUndefined } from 'utilities/string';
import { CopyButton } from 'modules/ui';

import { SecretFieldSection } from '../../SecretFieldSection';
import type { Secret } from '../../../types';
import { SecretType } from '../../../constants';
import { EmptyContentGuard } from '../EmptyContentGuard';

export interface ApiCredentialsSectionsProps {
    secret: Extract<Secret, { type: SecretType.ApiCredentials }>;
}

export const ApiCredentialsSections = ({ secret }: ApiCredentialsSectionsProps) => {
    const { userPermissions, username, credentials, hostname } = secret;

    const hasRead = hasReadPermission(userPermissions);

    return (
        <EmptyContentGuard>
            {!isEmptyOrUndefined(username) && (
                <SecretFieldSection heading={<FormattedMessage id="secret.username.label" />}>
                    <CopyButton text={username} />
                </SecretFieldSection>
            )}

            {!isEmptyOrUndefined(credentials) && (
                <SecretFieldSection heading={<FormattedMessage id="secret.credentials.label" />}>
                    <CopyButton type="password" text={credentials} disabled={!hasRead} />
                </SecretFieldSection>
            )}

            {!isEmptyOrUndefined(hostname) && (
                <SecretFieldSection heading={<FormattedMessage id="secret.hostname.label" />}>
                    <CopyButton text={hostname} />
                </SecretFieldSection>
            )}
        </EmptyContentGuard>
    );
};
