import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';
import { useFormState } from 'react-hook-form';

import { Button } from 'modules/ui';

import { SecretsField } from '../SecretsField';
import { SecretsFormatField } from '../SecretsFormatField';
import { DelimiterField } from '../DelimiterField';
import { ReplaceSecretsField } from '../ReplaceSecretsField';

import * as felaRules from './ImportFormFields.rules';

export const ImportFormFields = () => {
    const { css } = useFela();

    const { isSubmitting } = useFormState();

    return (
        <>
            <SecretsField />
            <div className={css(felaRules.optionsWrapper)}>
                <SecretsFormatField />
                <DelimiterField />
            </div>
            <div className={css(felaRules.buttonWrapper)}>
                <ReplaceSecretsField />
                <Button type="primary" htmlType="submit" loading={isSubmitting} disabled={isSubmitting}>
                    <FormattedMessage id="import.form.button" />
                </Button>
            </div>
        </>
    );
};
