import type { FieldValues } from 'react-hook-form';

import type { SubmitActions, SubmitError } from '../types';

export function handleFormSubmit<Values extends FieldValues>(
    callback: (values: Values, actions: SubmitActions<Values>) => void,
) {
    return (values: Values) => {
        let actions: SubmitActions<Values>;
        const resolver = new Promise<SubmitError<Values> | void>(resolve => {
            actions = {
                success: () => {
                    resolve();
                },
                failure: (...error) => {
                    if (error.length !== 0) {
                        resolve(error);
                    } else {
                        resolve();
                    }
                },
            };
        });

        // @ts-expect-error
        callback(values, actions);

        return resolver;
    };
}
