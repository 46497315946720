import { Link } from 'modules/ui';

import type { PasswordTypeSecret } from '../../types';

import * as felaRules from './SecretUrl.rules';

const schemeRegExp = /^([a-z0-9]+):\/\//;

export interface SecretUrlProps {
    web: PasswordTypeSecret['web'];
}

export const SecretUrl = ({ web }: SecretUrlProps) => (
    <Link type="native" size="big" href={web?.match(schemeRegExp) ? web : `http://${web}`} customStyle={felaRules.link}>
        {web}
    </Link>
);
