import { FormattedMessage } from 'react-intl';
import { useNavigate, generatePath } from 'react-router-dom-v5-compat';
import { useFela } from 'react-fela';
import { useLocation } from 'react-router';

import { config } from 'config/config';
import { Button, Icon, Text } from 'modules/ui';
import { hasWritePermission } from 'utilities/permission';

import { useSecretDetailContext } from '../SecretDetail/SecretDetailProvider';

import * as felaRules from './UpdateSecretButton.rules';

export const UpdateSecretButton = () => {
    const navigate = useNavigate();
    const { css } = useFela();
    const { pathname, search } = useLocation();

    const { userPermissions, id } = useSecretDetailContext();
    const canEdit = hasWritePermission(userPermissions);

    return (
        <div className={css(felaRules.container)}>
            <Button
                icon={<Icon type="edit" />}
                type="primary"
                size="large"
                onClick={() => {
                    navigate(
                        generatePath(config.routes.updateSecret, {
                            id,
                        }),
                        { state: { from: pathname + search } },
                    );
                }}
                disabled={!canEdit}
            >
                <span>
                    <FormattedMessage id="secret.update.button" />
                </span>
            </Button>
            {!canEdit && (
                <Text elementType="span" size="extraSmall" customStyle={felaRules.cannotEditMessage}>
                    <FormattedMessage id="secret.cannotEdit.info" />
                </Text>
            )}
        </div>
    );
};
