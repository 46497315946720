import { FormattedMessage } from 'react-intl';

import type { MessageKey } from 'modules/localizations/translations/types';
import { Alert, Button, Icon } from 'modules/ui';

import { useSubscriptionUpgradeModal } from '../../hooks';

import * as felaRules from './SubscriptionRequiredAlert.rules';

export interface SubscriptionRequiredAlertProps {
    message: MessageKey;
}

export const SubscriptionRequiredAlert = ({ message }: SubscriptionRequiredAlertProps) => {
    const { openUpgradeModal } = useSubscriptionUpgradeModal();

    return (
        <Alert
            icon={<Icon type="premium" />}
            showIcon
            type="warning"
            customStyle={felaRules.alert}
            message={
                <FormattedMessage
                    id={message}
                    values={{
                        button: chunks => (
                            <Button type="link" size="small" inheritColor underline onClick={openUpgradeModal}>
                                {chunks}
                            </Button>
                        ),
                    }}
                />
            }
        />
    );
};
