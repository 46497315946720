import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    marginBottom: '1.25rem',
});

export const title: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: '1.625em',
    letterSpacing: '0.047em',

    color: theme.colors.foregroundsPrimary,
});

export const divider: TRuleWithTheme = ({ theme }) => ({
    marginTop: '1rem',
    marginBottom: '1.25rem',

    borderTop: 'none',
    borderleft: 'none',
    borderRight: 'none',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.colors.foregroundsSeparator,
});

export const billingEmail: TRuleWithTheme = ({ theme }) => ({
    ...theme.typography.ParagraphSmall,
});

export const info: TRuleWithTheme = ({ theme }) => ({
    marginBottom: '0.25rem',

    ...theme.typography.ParagraphSmall,

    color: theme.colors.foregroundsTertiary,

    ':last-of-type': {
        marginBottom: '1.25rem',
    },
});

export const changeSubscriptionWarning: TRuleWithTheme = ({ theme }) => ({
    marginTop: '1rem',
    fontWeight: 400,
});
