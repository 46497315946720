import { IS_GA_ENABLED, IS_PRODUCTION_NODE_ENV } from 'constants/index';

import { gtmMockInterface } from './gtmMockInterface';
import { gtmInterface, ITagManager } from './gtmInterface';

const getGTM = IS_GA_ENABLED && IS_PRODUCTION_NODE_ENV ? gtmInterface() : gtmMockInterface();

type Promisified<T> = {
    [K in keyof T]: T[K] extends (...args: any) => infer R ? (...args: Parameters<T[K]>) => Promise<R> : T;
};

export const TagManager: Promisified<ITagManager> = {
    initialize: async (...options) => {
        const gtm = await getGTM;

        gtm.initialize(...options);
    },
    push: async (...options) => {
        const gtm = await getGTM;

        gtm.push(...options);
    },
};
