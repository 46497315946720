import { FormattedMessage } from 'react-intl';

import { Label, TagsInput, FormField } from 'modules/forms';

import { Fields } from '../../../constants';

import { useTagsOptions } from '../../../modules/tags';

export const TagsField = () => {
    const name = Fields.TAGS;

    const label = (
        <Label>
            <FormattedMessage id={`secret.${name}.label`} />
        </Label>
    );

    const options = useTagsOptions();

    return (
        <FormField label={label} name={name}>
            <TagsInput name={name} options={options} />
        </FormField>
    );
};
