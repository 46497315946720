import { createAction } from '@reduxjs/toolkit';

import type { SearchSecretAttributeType, SearchHistory, Secret, SecretById, ViewFilter } from '../../types';
import type { SecretListId } from '../../constants';

import types from '../actionTypes';

export * from './load';
export * from './sync';

export * from './create';
export * from './read';
export * from './update';
export * from './remove';
export * from './ignoreReport';
export * from './share';
export * from './readShare';
export * from './totp';
export * from './delete';
export * from './tags';

export const setSecret = createAction(types.SET_SECRET, (id: Secret['id'], payload: Secret) => ({
    meta: {
        id,
    },
    payload,
}));

export const setSecrets = createAction(
    types.SET_SECRETS,
    (
        byIds: SecretById,
        ids: Secret['id'][],
        {
            replace = false,
        }: {
            replace?: boolean;
        } = {},
    ) => ({
        meta: {
            replace,
        },
        payload: {
            byIds,
            ids,
        },
    }),
);

export const setSecretsNameFilter = createAction(types.SET_SECRETS_NAME_FILTER, (value: string) => ({
    payload: {
        value,
    },
}));

export const setSecretsListOffset = createAction(
    types.SET_SECRETS_LIST_OFFSET,
    (offset: number, listId: SecretListId) => ({
        payload: { offset, listId },
    }),
);

export const toggleActiveSecretAttributeFilter = createAction(
    types.TOGGLE_ACTIVE_SECRET_ATTRIBUTE_FILTER,
    (value: string, label: string, type: SearchSecretAttributeType, active: boolean) => ({
        payload: {
            value,
            label,
            type,
            active,
        },
    }),
);

export const clearActiveSecretAttributeFilters = createAction(types.CLEAR_ACTIVE_SECRET_ATTRIBUTE_FILTERS);

export const setSecretsTotalCount = createAction(types.SET_SECRETS_TOTAL_COUNT, (totalCount: number) => ({
    payload: {
        totalCount,
    },
}));

export const setViewFilter = createAction(types.SET_SECRETS_VIEW, (view: ViewFilter | null, verbose?: boolean) => ({
    payload: { view },
    meta: {
        verbose,
    },
}));

export const setViewFilterIds = createAction(types.SET_SECRETS_VIEW_IDS, (ids: string[] | null) => ({
    payload: { ids },
}));

export const setSearchHistory = createAction<SearchHistory>(types.SET_SEARCH_HISTORY);

export const removeSearchHistory = createAction(types.REMOVE_SEARCH_HISTORY, (value: SearchHistory['value']) => ({
    payload: { value },
}));
