import { useDispatch } from 'react-redux';
import type { DefaultValues } from 'react-hook-form';

import { TagManager } from 'services/utilities';
import { Form, handleFormSubmit, useTranslateSubmitError } from 'modules/forms';

import { Delimiter, SecretsFormat } from '../../types';
import { importFormSchema, type ImportFormSchemaType, type ImportFormValues } from '../../schemas';

import { startImport } from '../../services/actions';
import { ImportFormFields } from './ImportFormFields';
import { Fields } from '../../constants';

const defaultValues = {
    format: SecretsFormat.PASSWD,
    delimiter: Delimiter.COMMA,
    replaceSecrets: false,
} satisfies DefaultValues<ImportFormValues>;

export const ImportForm = () => {
    const dispatch = useDispatch();

    const translate = useTranslateSubmitError<ImportFormValues>();

    return (
        <Form<ImportFormSchemaType, ImportFormValues>
            schema={importFormSchema}
            defaultValues={defaultValues}
            onSubmit={async (values, { setError, resetField, reset }) => {
                const error = await handleFormSubmit<ImportFormValues>((values, actions) => {
                    TagManager.push('used_import_export');
                    dispatch(startImport(values, actions));
                })(values);

                if (error) {
                    const [errorField] = error;

                    const translatedError = translate(...error);
                    setError(...translatedError);

                    if (errorField === Fields.SECRETS) resetField(errorField, { keepError: true });
                } else {
                    reset();
                }
            }}
        >
            <ImportFormFields />
        </Form>
    );
};
