import { useFela } from 'react-fela';
import { FormattedMessage, useIntl } from 'react-intl';
import { Controller } from 'react-hook-form';

import { Icon, Link, Text } from 'modules/ui';

import type { ReviewPromptFormValues } from '../../../../schema';
import { SITES, useHandleSiteVisit } from './hooks';

import * as felaRules from './ReviewSites.rules';

export const ReviewSites = () => {
    const { css } = useFela();
    const { formatMessage } = useIntl();

    const handleSiteVisit = useHandleSiteVisit();

    return (
        <ul className={css(felaRules.sitesList)}>
            {SITES.map(site => (
                <li key={site} className={css(felaRules.siteItem)}>
                    <div>
                        <Link
                            color="accentPrimary"
                            size="big"
                            type="native"
                            href={formatMessage({ id: `review-prompt.form.sites.${site}.link` })}
                            onClick={() => handleSiteVisit(site)}
                        >
                            <FormattedMessage id={`review-prompt.form.sites.${site}.title`} />
                        </Link>
                        <Text size="small" color="foregroundsTertiary">
                            <FormattedMessage id={`review-prompt.form.sites.${site}.description`} />
                        </Text>
                    </div>
                    <Controller<ReviewPromptFormValues>
                        name={`${site}Visited`}
                        render={({ field: { value: checked } }) => (
                            <div className={css(felaRules.checkCircle)}>
                                {checked && (
                                    <div className={css(felaRules.checkmarkContainer)}>
                                        <Icon
                                            type="checkmark"
                                            color="iconsOnPrimary"
                                            width="1.06231rem"
                                            height="0.75rem"
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    />
                </li>
            ))}
        </ul>
    );
};
