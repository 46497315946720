import { useRef } from 'react';
import { useFela } from 'react-fela';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Modal } from 'modules/modals';
import { Heading, Loadable, Text } from 'modules/ui';

import { selectSaasCheckoutApi } from '../../services/selectors';
import { useGetIframeUrl, useIframeDimensions, useReceiveIframeMessages } from './hooks';
import { useSubscriptionUpgradeModal } from '../../hooks';

import * as felaRules from './SubscriptionUpgradeModal.rules';

export const SubscriptionUpgradeModal = () => {
    const { css } = useFela();
    const { formatMessage } = useIntl();

    const { isOpen, closeUpgradeModal } = useSubscriptionUpgradeModal();

    const iframeRef = useRef<HTMLIFrameElement>(null);
    const iframeContainerRef = useRef<HTMLDivElement>(null);
    const { iframeHeight } = useIframeDimensions(iframeContainerRef);
    const iframeUrl = useGetIframeUrl();

    const { inProgress } = useSelector(selectSaasCheckoutApi);

    useReceiveIframeMessages();

    return (
        <Modal
            open={isOpen}
            width="100%"
            zIndex={2000}
            closable
            onClose={closeUpgradeModal}
            extend={{ container: felaRules.modalContainer }}
        >
            <div className={css(felaRules.contentContainer)}>
                <div className={css(felaRules.textContainer)}>
                    <Heading elementType="h2" size="big" color="black">
                        <FormattedMessage id="saas.upgradeModal.title" />
                    </Heading>
                    <Text size="small" color="foregroundsTertiary">
                        <FormattedMessage id="saas.upgradeModal.text" />
                    </Text>
                </div>

                <div className={css(felaRules.iframeContainer)} ref={iframeContainerRef}>
                    <Loadable showLoader={inProgress}>
                        <iframe
                            className={css(felaRules.iframe)}
                            height={iframeHeight}
                            src={iframeUrl}
                            title={formatMessage({ id: 'saas.upgradeModal.pricingPlans.title' })}
                            width="100%"
                            ref={iframeRef}
                        />
                    </Loadable>
                </div>
            </div>
        </Modal>
    );
};
