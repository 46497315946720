import { FormattedMessage } from 'react-intl';

import { hasReadPermission } from 'utilities/permission';
import { isEmptyOrUndefined } from 'utilities/string';

import { Fields, SecretType } from '../../../../../../constants';
import type { Secret } from '../../../../../../types';
import { CopySection } from '../CopySection';

export interface DatabaseCredentialsSectionsProps {
    secret: Extract<Secret, { type: SecretType.DatabaseCredentials }>;
}

export const DatabaseCredentialsSections = ({ secret }: DatabaseCredentialsSectionsProps) => {
    const { username, sensitiveFields } = secret;

    const hasRead = hasReadPermission(secret.userPermissions);

    return (
        <>
            <CopySection
                visible={!isEmptyOrUndefined(username)}
                headingMessageKey="secret.username.label"
                secret={secret}
                property={Fields.USERNAME}
            />
            <CopySection
                visible={sensitiveFields?.includes(Fields.PASSWORD)}
                headingMessageKey="secret.password.label"
                secret={secret}
                property={Fields.PASSWORD}
                mask={<FormattedMessage id="crypted.password" />}
                disabled={!hasRead}
            />
        </>
    );
};
