import { FormattedMessage } from 'react-intl';
import { useFela } from 'react-fela';

import { Text, Empty } from 'modules/ui';
import { isEmptyOrUndefined } from 'utilities/string';

import { useSharedSecretDetail } from '../../hooks/useSharedSecretDetail';

import type { Share } from '../../types';

import { SecretFieldSection } from '../SecretFieldSection';
import { SecretNameWithIcon } from '../SecretNameWithIcon';
import { SecretFieldSectionsByType } from '../SecretDetailSections';
import { SecretFile } from '../SecretFile';

import * as felaRules from './ShareSecretDetail.rules';

export interface SecretDetailProps {
    id: Share['id'];
}

export const ShareSecretDetail = ({ id }: SecretDetailProps) => {
    const secret = useSharedSecretDetail(id);
    const { css } = useFela();

    if (!secret) {
        return <Empty data-testid="shared-secret-empty" text={<FormattedMessage id="secret.share.empty" />} />;
    }

    const { note, file } = secret;

    return (
        <>
            <SecretFieldSection>
                <SecretNameWithIcon secret={secret} />
            </SecretFieldSection>
            <div className={css(felaRules.propertiesContainer)}>
                <SecretFieldSectionsByType secret={secret} />
                {file ? (
                    <SecretFieldSection heading={<FormattedMessage id="secret.file" />}>
                        <SecretFile file={file} />
                    </SecretFieldSection>
                ) : null}
                {!isEmptyOrUndefined(note) ? (
                    <SecretFieldSection heading={<FormattedMessage id="secret.note" />}>
                        <Text preserveNewLines>{note}</Text>
                    </SecretFieldSection>
                ) : null}
            </div>
        </>
    );
};
