import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    '& .ant-modal': {
        transformOrigin: 'unset !important',
    },
    '& .ant-modal .ant-modal-content': {
        padding: '1.5rem',

        borderRadius: '0.25rem',
    },
    '& .ant-modal .ant-modal-close': {
        color: theme.colors.black,

        width: 'unset',
        height: 'unset',

        right: 0,
    },
    '& .ant-modal .ant-modal-close:hover': {
        backgroundColor: 'transparent',
    },
    '& .ant-modal .ant-modal-close .ant-modal-close-x': {
        width: 40,
        height: 40,

        lineHeight: '40px',
    },
});

export const buttons: TRuleWithTheme<{
    alignButtonsRight: boolean;
}> = ({ alignButtonsRight }) => ({
    ...(alignButtonsRight
        ? {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',

              gap: '0.625rem',
          }
        : undefined),
});
