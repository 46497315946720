import { FormattedMessage } from 'react-intl';

import { Button, Text } from 'modules/ui';

import { ReviewPromptLayout } from '../ReviewPromptLayout';
import { ReviewPromptForm } from './ReviewPromptForm';
import { ReviewPromptStep, useReviewPrompt } from '../../hooks';

export const ReviewPromptSteps = () => {
    const { step, setStep, closeModal, onReviewSubmit } = useReviewPrompt();

    switch (step) {
        case ReviewPromptStep.Intro: {
            return (
                <ReviewPromptLayout
                    showImage
                    title={`review-prompt.${step}.title`}
                    content={
                        <Text preserveNewLines>
                            <FormattedMessage id={`review-prompt.${step}.text`} />
                        </Text>
                    }
                    buttons={
                        <>
                            <Button type="primary" size="large" onClick={() => setStep(ReviewPromptStep.Form)}>
                                <FormattedMessage id={`review-prompt.${step}.button.next`} />
                            </Button>
                            <Button type="link" size="large" onClick={closeModal}>
                                <FormattedMessage id={`review-prompt.${step}.button.ignore`} />
                            </Button>
                        </>
                    }
                />
            );
        }

        case ReviewPromptStep.Form: {
            return (
                <ReviewPromptLayout
                    showImage={false}
                    title={`review-prompt.${step}.title`}
                    content={
                        <ReviewPromptForm
                            onSubmitSuccess={() => {
                                onReviewSubmit();
                                setStep(ReviewPromptStep.ThankYou);
                            }}
                            onClose={closeModal}
                        />
                    }
                />
            );
        }

        case ReviewPromptStep.ThankYou: {
            return (
                <ReviewPromptLayout
                    showImage
                    title={`review-prompt.${step}.title`}
                    content={
                        <Text preserveNewLines>
                            <FormattedMessage id={`review-prompt.${step}.text`} />
                        </Text>
                    }
                    buttons={
                        <Button type="default" size="large" onClick={closeModal}>
                            <FormattedMessage id={`review-prompt.${step}.button.close`} />
                        </Button>
                    }
                />
            );
        }

        default:
            return null;
    }
};
