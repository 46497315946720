import { FormattedMessage } from 'react-intl';

import { Label, TextInput, FormField } from 'modules/forms';
import type { FormFieldProps, TextInputProps } from 'modules/forms';

import type { Fields } from '../../../constants';

export interface TextFieldProps extends Pick<FormFieldProps, 'help' | 'fullWidth' | 'required'> {
    name: Exclude<
        Fields,
        Fields.HAS_TOTP | Fields.CARD_NUMBER | Fields.REDACTED_CARD_NUMBER | Fields.CVV_CODE | Fields.EXPIRATION_DATE
    >;
    onChange?: () => void;
    extend?: {
        formItem?: FormFieldProps['customStyle'];
        input?: TextInputProps['customStyle'];
    };
    hideLabel?: boolean;
    placeholder?: string;
    transformValue?: (value: string) => string;
}

export const TextField = ({
    name,
    help = null,
    onChange,
    extend,
    hideLabel,
    fullWidth,
    placeholder,
    transformValue,
    required,
}: TextFieldProps) => {
    const label = (
        <>
            <Label>
                <FormattedMessage id={`secret.${name}.label`} />
            </Label>
            {help}
        </>
    );

    return (
        <FormField
            name={name}
            htmlFor={name}
            label={hideLabel ? null : label}
            fullWidth={fullWidth}
            customStyle={extend?.formItem}
            required={required}
        >
            <TextInput
                name={name}
                id={name}
                placeholder={placeholder}
                onChange={onChange}
                customStyle={extend?.input}
                transformValue={transformValue}
            />
        </FormField>
    );
};
