import felaPluginEmbedded from 'fela-plugin-embedded';
import felaPluginExtend from 'fela-plugin-extend';
import felaPluginFallbackValue from 'fela-plugin-fallback-value';
import felaPluginNamedKeys from 'fela-plugin-named-keys';
import felaPluginPrefixer from 'fela-plugin-prefixer';
import felaPluginUnit from 'fela-plugin-unit';

import sortMediaQueryMobileFirst from 'fela-sort-media-query-mobile-first';

import { IS_DEVELOPMENT_NODE_ENV } from 'constants/index';

import type { Theme } from 'styles/theme';

export const config = {
    devMode: IS_DEVELOPMENT_NODE_ENV,
    enhancers: [sortMediaQueryMobileFirst()].filter(Boolean),
    plugins: [
        felaPluginEmbedded(),
        felaPluginNamedKeys<{
            theme: Theme;
        }>(({ theme }) => ({
            min768: `@media (min-width: ${theme.breakpoints.sm}px)`,
            min1024: `@media (min-width: ${theme.breakpoints.md}px)`,
            min1440: `@media (min-width: ${theme.breakpoints.lg}px)`,
            max768: `@media (max-width: ${theme.breakpoints.sm - 1}px)`,
            max1024: `@media (max-width: ${theme.breakpoints.md - 1}px)`,
            max1440: `@media (max-width: ${theme.breakpoints.lg - 1}px)`,
        })),
        felaPluginUnit('px', {
            lineHeight: 'em',
        }),
        felaPluginFallbackValue(),
        felaPluginPrefixer(),
        felaPluginExtend(),
    ],
};
