import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom-v5-compat';

import { useAppSelector } from 'hooks/useAppSelector';

import type { Share } from '../types';

import { readShareSecret } from '../services/actions';
import { selectReadShareSecretApi } from '../services/selectors';

export const useFetchShareSecret = () => {
    const { id } = useParams<{
        id: Share['id'];
    }>();

    const { inProgress: showLoader } = useAppSelector(state => selectReadShareSecretApi(state, id ?? ''));

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(readShareSecret(id ?? ''));
    }, [id, dispatch]);

    return {
        id,
        showLoader,
    };
};
