import type { ReactNode } from 'react';
import { useController } from 'react-hook-form';

import type { EntityWithPermissions } from 'types';
import { permissionByRole } from 'utilities/permission';
import { FormField, FormFieldProps, SelectInput } from 'modules/forms';
import { useDefaultAccessRole } from 'modules/settings';

interface Option<Value extends EntityWithPermissions> {
    value: Value['id'];
    entity: Value;
    label: string;
    key: string;
}

interface RenderValueFn<Value extends EntityWithPermissions> {
    (
        value: Value[],
        actions: {
            onUpdate: (value: Value) => void;
            onRemove: (value: Value['id']) => void;
        },
    ): ReactNode;
}
export interface SelectFieldProps<Value extends EntityWithPermissions> {
    id: string;
    name: string;
    placeholder: string;
    label: FormFieldProps['label'];
    children: RenderValueFn<Value>;
    loadingMore: boolean;
    onLoadMore: () => void;
    hasMore: boolean;
    onSearch: (filter: string) => void;
    onChange?: () => void;
    options: Option<Value>[];
    popupShouldIgnoreParentContainer?: boolean;
}

const dummyValue = [];

export function SelectField<Value extends EntityWithPermissions>({
    id,
    placeholder,
    name,
    children,
    label,
    options,
    onChange,
    loadingMore,
    onLoadMore,
    onSearch,
    hasMore,
    popupShouldIgnoreParentContainer,
}: SelectFieldProps<Value>) {
    const defaultAccessRole = useDefaultAccessRole();

    const { field } = useController({ name });

    const value = Boolean(field.value) ? field.value : dummyValue;

    const handleChange = (val: Value[]) => {
        field.onChange(val);
        onChange?.();
    };

    return (
        <FormField name={name} label={label}>
            <SelectInput
                id={id}
                value={dummyValue}
                placeholder={placeholder}
                onBlur={field.onBlur}
                onChange={(val: string) => {
                    const matchingOption = options.find(option => val === option.value);

                    if (!matchingOption) {
                        return;
                    }

                    handleChange([
                        ...value,
                        {
                            ...matchingOption.entity,
                            accessPermissions: defaultAccessRole ? permissionByRole[defaultAccessRole] : null,
                        },
                    ]);

                    onSearch('');
                }}
                options={options
                    .filter(option => !value.find(v => v.id === option.value))
                    .map(({ value, label, key }) => ({ value, label, key }))}
                showSearch
                onSearch={onSearch}
                optionFilterProp="children"
                loadingMore={loadingMore}
                loadMore={onLoadMore}
                hasMore={hasMore}
                popupShouldIgnoreParentContainer={popupShouldIgnoreParentContainer}
            />
            {children(value, {
                onUpdate: (val: Value) => {
                    handleChange(value.map(v => (v.id === val.id ? val : v)));
                },
                onRemove: (id: Value['id']) => {
                    handleChange(value.filter(v => v.id !== id));
                },
            })}
        </FormField>
    );
}
