import { tokensSelector } from '@ackee/petrus';

import { store } from 'modules/core/modules/redux';

import type { AccessToken, DemoAccessToken } from '../types';
import { isDemoAccessToken } from '../services/utils';
import { obtainValidAccessToken } from '../utils';

export async function requestAuthHeaderInterceptor(request: Request) {
    const accessToken = await obtainValidAccessToken();

    if (accessToken) {
        request.headers.set('Authorization', `Bearer ${accessToken.token}`);
    } else {
        request.headers.delete('Authorization');
    }

    return request;
}

export function demoUserInterceptor(request: Request) {
    const state = store.getState();
    const accessToken = tokensSelector(state)?.accessToken as AccessToken | DemoAccessToken;

    if (isDemoAccessToken(accessToken)) {
        request.headers.set('x-user-email', accessToken.googleUser.email);
    }

    return request;
}
