import type { AnyAction } from 'redux';
import type { Task } from 'redux-saga';

import { call, cancel, cancelled, fork, put, take } from 'redux-saga/effects';

export interface ActionCreator<Type extends string = string> {
    (...args: any[]): {
        type: Type;
    };
    toString: () => Type;
}

export function takeLatestRequest<R extends ActionCreator>(
    {
        actionCreator,
        cancelActionFunction,
        idSelector,
    }: {
        actionCreator: R;
        idSelector: (action: ReturnType<R>) => string;
        cancelActionFunction?: (action: ReturnType<R>) => AnyAction;
    },
    handler: (action: ReturnType<R>, signal: AbortSignal) => void,
) {
    const tasks = new Map<string, Task>();

    return fork(function* () {
        try {
            while (true) {
                const action: ReturnType<R> = yield take(actionCreator.toString());

                const id = idSelector(action);

                if (tasks.has(id)) {
                    const task = tasks.get(id);
                    if (task) yield cancel(task);

                    tasks.delete(id);
                }

                const task: Task = yield fork(function* () {
                    const controller = new AbortController();

                    try {
                        yield call(handler, action, controller.signal);
                    } finally {
                        if (yield cancelled()) {
                            controller.abort();

                            if (typeof cancelActionFunction === 'function') {
                                yield put(cancelActionFunction(action));
                            }
                        }
                    }
                });

                task.toPromise().then(() => {
                    if (tasks.has(id)) {
                        tasks.delete(id);
                    }
                });

                tasks.set(id, task);
            }
        } finally {
            if (yield cancelled()) {
                for (const [, task] of tasks.entries()) {
                    yield cancel(task);
                }
            }
        }
    });
}
