import type { TRuleWithTheme } from 'styles/theme';

export const sitesList: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',

    paddingTop: '0.5rem',
    paddingBottom: '2rem',
});

export const siteItem: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    padding: '0.75rem 1rem',

    borderRadius: '0.5rem',

    backgroundColor: theme.colors.backgroundsOnSecondary,
});

export const checkCircle: TRuleWithTheme = ({ theme }) => ({
    position: 'relative',

    width: '2rem',
    height: '2rem',

    borderRadius: '50%',
    border: `0.125rem solid ${theme.colors.foregroundsSeparator}`,

    backgroundColor: theme.colors.backgroundsSecondary,
});

export const checkmarkContainer: TRuleWithTheme = ({ theme }) => ({
    position: 'absolute',
    top: '-0.125rem',
    left: '-0.125rem',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    width: '2rem',
    height: '2rem',

    borderRadius: '50%',

    backgroundColor: theme.colors.accentPrimary,
});
