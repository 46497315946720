import { useFela } from 'react-fela';
import { Form as AntForm } from 'antd';
import { type FieldValues, useFormContext } from 'react-hook-form';
import type { FormItemProps as AntFormItemProps } from 'antd/es/form';

import { mapFieldState } from './utils';

import * as felaRules from './FormField.rules';

export interface FormFieldProps extends Omit<AntFormItemProps, 'className'> {
    customStyle?: typeof felaRules.container;
    fullWidth?: boolean;
    name?: string | string[];
}

export function FormField<FormValues extends FieldValues>({
    fullWidth = true,
    customStyle,
    name,
    ...props
}: FormFieldProps) {
    const { css } = useFela({ fullWidth });
    const context = useFormContext<FormValues>();

    const mappedFieldStateProps = mapFieldState<FormValues>(name, context?.formState);

    return <AntForm.Item {...props} {...mappedFieldStateProps} className={css(felaRules.container, customStyle)} />;
}
