import { put, takeEvery, take } from 'redux-saga/effects';

import { config } from 'config/config';
import { logger } from 'config/logger';
import { api } from 'config/antonio';

import { actions as messagesActions } from 'modules/messages';
import { actions as secretsActions } from 'modules/secrets';

import { Fields } from '../../constants';

import types from '../actionTypes';

import {
    importRequestSuccess,
    importRequestFailure,
    importValidationRequest,
    startImport,
    importRequest,
    importValidationRequestSuccess,
    importValidationRequestFailure,
    importValidationRequestCancel,
} from '../actions';

function* importHandler({ payload, meta }: ReturnType<typeof startImport>) {
    try {
        const formData = new FormData();
        formData.append(Fields.SECRETS, payload.secrets);
        formData.append(Fields.SECRETS_FORMAT, payload.format);
        formData.append(Fields.DELIMITER, payload.delimiter);
        formData.append(Fields.REPLACE_SECRETS, JSON.stringify(payload.replaceSecrets));

        // Validate import
        yield put(importValidationRequest(payload));

        const validationResult:
            | ReturnType<typeof importValidationRequestSuccess>
            | ReturnType<typeof importValidationRequestFailure>
            | ReturnType<typeof importValidationRequestCancel> = yield take([
            importValidationRequestSuccess.toString(),
            importValidationRequestFailure.toString(),
            importValidationRequestCancel.toString(),
        ]);

        switch (validationResult.type) {
            case types.IMPORT_VALIDATION_REQUEST_SUCCESS: {
                yield put(importRequest());

                yield* api.patch(config.api.secrets, formData);

                yield put(importRequestSuccess());

                yield put(
                    messagesActions.displaySuccessMessage({
                        message: { id: 'success.import-export.import' },
                        options: {
                            duration: 5,
                        },
                    }),
                );

                yield put(secretsActions.syncSecrets(true));

                meta.success();

                break;
            }
            case types.IMPORT_VALIDATION_REQUEST_FAILURE: {
                if ((validationResult as ReturnType<typeof importValidationRequestFailure>).meta.isCsvValidationError) {
                    meta.failure(Fields.SECRETS, { message: 'import.form.error' });
                }

                break;
            }
            default: {
                meta.success();
            }
        }
    } catch (e) {
        yield put(
            messagesActions.displayErrorMessage({
                message: { id: 'error.import-export.import' },
                options: {
                    duration: 5,
                },
            }),
        );

        yield put(importRequestFailure());

        logger.error(e);

        meta.failure();
    }
}

export default function* importSaga() {
    yield takeEvery(startImport.toString(), importHandler);
}
