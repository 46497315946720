import { z } from 'zod';

import type { MessageKey } from 'modules/localizations';

export const reviewPromptFormSchema = z.object({
    name: z.string().min(1, { message: 'form.error.required' satisfies MessageKey }),
    email: z.string().email({ message: 'form.error.email' satisfies MessageKey }),
    tocAgreement: z.boolean().refine(val => val === true, { message: 'form.error.required' satisfies MessageKey }),
    trustpilotVisited: z.boolean(),
    g2Visited: z.boolean(),
});

export type ReviewPromptFormSchemaType = typeof reviewPromptFormSchema;

export type ReviewPromptFormValues = z.infer<typeof reviewPromptFormSchema>;
