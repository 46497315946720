import { delay } from 'lodash';
import { useFormContext } from 'react-hook-form';

import { ReviewPromptFormValues } from '../../../../../schema';

export const SITES = ['trustpilot', 'g2'] as const;

export const useHandleSiteVisit = () => {
    const { setValue } = useFormContext<ReviewPromptFormValues>();

    const handleSiteVisit = (site: (typeof SITES)[number]) => {
        delay(
            () => {
                setValue(`${site}Visited`, true);
            },
            15 * 1000, // 15 seconds
        );
    };

    return handleSiteVisit;
};
