import { useFela } from 'react-fela';
import { FormattedMessage, useIntl } from 'react-intl';

import config from 'config';
import { Heading, Loader, Pane } from 'modules/ui';

import { useFetchOrder } from '../../hooks/useFetchOrder';
import { OrderInfo } from '../OrderInfo';
import { SettingsFormProvider } from '../SettingsFormProvider';
import { SettingsForm } from '../SettingsForm';
import { DeleteSecrets } from '../DeleteSecrets';
import { SettingsLink } from '../SettingsLink';

import * as felaRules from './Settings.rules';

export const Settings = () => {
    const { css } = useFela();
    const { api, order } = useFetchOrder();
    const { formatMessage } = useIntl();

    if (api.inProgress) {
        return <Loader />;
    }

    return (
        <div className={css(felaRules.container)}>
            <Heading elementType="h1" color="black" customStyle={felaRules.title}>
                <FormattedMessage id="settings.title" />
            </Heading>
            <OrderInfo order={order} />
            <SettingsFormProvider>
                <Pane>
                    <SettingsForm />
                    <SettingsLink
                        to={config.routes.tagsSettings}
                        title={formatMessage({ id: 'settings.links.tags' })}
                    />
                    <SettingsLink
                        to={config.routes.groupsVisibilitySettings}
                        title={formatMessage({ id: 'settings.links.groupsVisibility' })}
                    />
                    <DeleteSecrets />
                </Pane>
            </SettingsFormProvider>
        </div>
    );
};
