import { z } from 'zod';

import { Fields, SecretType } from '../constants';
import { basicSecretFormSchema } from './basicSecretFormSchema';

export const databaseCredentialsSecretFormSchema = basicSecretFormSchema.merge(
    z.object({
        [Fields.TYPE]: z.literal(SecretType.DatabaseCredentials),
        [Fields.DATABASE_NAME]: z.string().optional(),
        [Fields.DATABASE_TYPE]: z.string().optional(),
        [Fields.SERVER]: z.string().optional(),
        [Fields.PORT]: z.string().nullish(),
        [Fields.USERNAME]: z.string().optional(),
        [Fields.PASSWORD]: z.string().optional(),
    }),
);
