import type { TRuleWithTheme } from 'styles/theme';

export const label: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '0.25rem',
});

export const readOnlyInput: TRuleWithTheme = ({ theme }) => ({
    '&.ant-input-affix-wrapper-readonly': {
        backgroundColor: theme.colors.inputsBackgroundPlaceholder,
        cursor: 'not-allowed',

        '& .ant-input': {
            cursor: 'not-allowed',
        },
    },
});
