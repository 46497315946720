export const formatExpirationValue = (event: React.FormEvent<HTMLInputElement>) => {
    let start = event.currentTarget.selectionStart;
    let end = event.currentTarget.selectionEnd;

    let val = (event.target as HTMLInputElement).value;

    const slashIndex = val.indexOf('/');

    // Allow only digits and slash
    val = val.replace(/[^0-9/]/g, '');

    // The first character cannot be slash
    if (val[0] === '/') {
        val = '';
    }

    // Allow only one slash
    if (slashIndex !== val.length - 1) {
        for (let i = 0; i < val.length; i++) {
            if (val[i] === '/') {
                val = val.slice(0, i) + val.slice(i + 1);
            }
        }
    }

    // The first month digit should be 0 or 1
    if (val.length === 1 && parseInt(val[0], 10) > 1) {
        val = `0${val[0]}/`;

        start = 3;
        end = start;
    }

    // Handle cases where the user types 1/ (should become 01/)
    if (val.length === 2 && val[1] === '/') {
        val = `0${val[0]}/`;
        start ? (start += 1) : (start = 1);
        end = start;
    }

    // Add slash after the second digit if it's not already there
    if (val.length >= 3 && val[2] !== '/') {
        val = `${val.slice(0, 2)}/${val.slice(2)}`;

        if (start && start === 3) {
            start += 1;
            end = start;
        }
    }

    const [month, year] = val.split('/');

    // Handle cases where the user types 00 as month (should become 0)
    if (month.length === 2 && month === '00') {
        val = '0';
    }

    // Move second month digit to first year digit if the month would be greater than 12
    if (month.length >= 2 && parseInt(month, 10) > 12) {
        val = `0${month.slice(0, 1)}/${month.slice(1, 2)}${year || ''}`;

        if (start) {
            start += 2;
            end = start;
        }
    }

    // Allow only 5 characters (MM/YY)
    if (val.length > 5) {
        val = val.slice(0, 5);
    }

    (event.target as HTMLInputElement).value = val;

    event.currentTarget.setSelectionRange(start, end);
};
