import { basicApiReducer } from '@ackee/redux-utils';
import { combineReducers } from 'redux';

import { saasCheckoutActions } from '../actions';

const saasCheckout = basicApiReducer({
    actionTypes: saasCheckoutActions.types,
});

export const apiReducer = combineReducers({
    saasCheckout,
});
