import { useFormContext } from 'react-hook-form';

import type { SecretFormValues } from '../schemas';
import { Fields } from '../constants';

export const useUpdateSecretForm = () => {
    const {
        formState: { isDirty, isSubmitting, errors },
        getValues,
    } = useFormContext<SecretFormValues>();

    const submitError = errors?.root;

    const secretType = getValues(Fields.TYPE);

    return {
        isDirty,
        isSubmitting,
        submitError,
        secretType,
    };
};
