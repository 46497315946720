import { z } from 'zod';

import type { MessageKey } from 'modules/localizations/translations/types';
import type { EntityWithPermissions } from 'types';
import type { MessageKeyWithParams } from 'modules/forms';

import type { Base64File } from '../types';

import { Fields, SecretType } from '../constants';
import { isValidFileSize } from '../utilities';

export const basicSecretFormSchema = z.object({
    [Fields.TYPE]: z.nativeEnum(SecretType),
    [Fields.NAME]: z.string().min(1, { message: 'form.error.required' satisfies MessageKey }),
    [Fields.NOTE]: z.string().optional(),
    [Fields.TAGS]: z.array(z.string()).optional(),
    [Fields.GROUPS]: z.array(z.custom<EntityWithPermissions>()).optional(),
    [Fields.WHITELIST_USERS]: z.array(z.custom<EntityWithPermissions>()).optional(),
    [Fields.FILE]: z
        .custom<Base64File>()
        .refine(val => isValidFileSize(val), {
            message: `form.error.fileSize|{"maxSize":"${
                process.env.REACT_APP_SAAS === 'true' ? '30KiB' : '512KiB'
            }"}` satisfies MessageKeyWithParams,
        })
        .nullish(),
});
