import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useFela } from 'react-fela';

import { Icon, IconButton } from 'modules/ui';

import { useCopySecretValue } from '../hooks';
import type { Secret } from '../../../../types';

import * as felaRules from './CopySecretValue.rules';
import type { Fields } from '../../../../constants';

export interface CopySecretValueProps {
    id: Secret['id'];
    property: Fields;
    disabled?: boolean;
    onCopy?: (props: { success: boolean }) => void;
}

export function CopySecretValue({ id, property, disabled = false, onCopy = () => null }: CopySecretValueProps) {
    const { css } = useFela();
    const { formatMessage } = useIntl();

    const { inProgress, success, handleCopy } = useCopySecretValue(id, property);

    useEffect(() => {
        onCopy({ success });
    }, [success, onCopy]);

    if (inProgress) {
        return (
            <span className={css(felaRules.container)}>
                <Icon type="reloading" customStyle={felaRules.loadingIcon} />
            </span>
        );
    }

    return (
        <span className={css(felaRules.container)}>
            <IconButton
                type="ghost"
                icon="copy"
                customStyle={felaRules.button}
                disabled={disabled}
                aria-label={formatMessage({ id: 'copy' })}
                onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    handleCopy();
                }}
            />
        </span>
    );
}
