import { ReactNode } from 'react';
import { useWatch } from 'react-hook-form';

import { PasswordFields } from '../PasswordFields';
import { PaymentCardFields } from '../PaymentCardFields';
import { ApiCredentialsFields } from '../ApiCredentialsFields';
import { DatabaseCredentialsFields } from '../DatabaseCredentialsFields';
import { SshKeyFields } from '../SshKeyFields';
import { SecureNoteFields } from '../SecureNoteFields';

import { Fields, SecretType } from '../../../constants';
import type { SecretFormValues } from '../../../schemas';

const fieldsByType = {
    [SecretType.Password]: <PasswordFields />,
    [SecretType.PaymentCard]: <PaymentCardFields />,
    [SecretType.ApiCredentials]: <ApiCredentialsFields />,
    [SecretType.DatabaseCredentials]: <DatabaseCredentialsFields />,
    [SecretType.SshKey]: <SshKeyFields />,
    [SecretType.SecureNote]: <SecureNoteFields />,
} satisfies Record<SecretType, ReactNode>;

export const MainInformationFields = () => {
    const secretType = useWatch<SecretFormValues, Fields.TYPE>({ name: Fields.TYPE });

    return fieldsByType[secretType];
};
