import type { RequestSearchParams } from '@ackee/antonio-core';

import { config } from 'config/config';
import { api } from 'config/antonio';

import type { ViewFilter } from '../../types';

import { omitSensitiveKeys, preprocessSecret } from '../normalizers';

export default function* fetchSecrets({ signal, viewFilter }: { signal?: AbortSignal; viewFilter?: ViewFilter } = {}) {
    const params: RequestSearchParams = {};

    if (viewFilter) {
        if (viewFilter.type === 'group') {
            params['groupId'] = viewFilter.id;
        }
        if (viewFilter.type === 'user') {
            params['userId'] = viewFilter.id;
        }
    }

    const { data } = yield* api.get<any[]>(config.api.secrets, {
        signal,
        params,
        baseURL: config.api.v3base,
    });

    return data.map(preprocessSecret).map(omitSensitiveKeys);
}
