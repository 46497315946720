import { useState } from 'react';
import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import { Text, Overlay, Button } from 'modules/ui';

import type { WhitelistUser } from '../../types';

import { WhitelistItem } from '../WhitelistItem';

import * as felaRules from './Whitelist.rules';

const DEFAULT_ITEMS_TO_SHOW = 5;

export interface WhitelistProps {
    whitelist?: WhitelistUser[];
}

export const Whitelist = ({ whitelist = [] }: WhitelistProps) => {
    const { css } = useFela();

    const openable = whitelist.length > DEFAULT_ITEMS_TO_SHOW;

    const [open, setOpen] = useState(false);

    return (
        <div className={css(felaRules.container)}>
            <Text size="small" elementType="ul" customStyle={felaRules.list}>
                {openable && !open && <Overlay type="whiteGradient" />}
                {whitelist.slice(0, openable && !open ? DEFAULT_ITEMS_TO_SHOW : whitelist.length).map(item => (
                    <WhitelistItem key={item.id} {...item} />
                ))}
            </Text>
            {openable && (
                <Button
                    onClick={() => {
                        setOpen(!open);
                    }}
                    type="link"
                    size="small"
                >
                    {open ? <FormattedMessage id="whitelist.close" /> : <FormattedMessage id="whitelist.open" />}
                </Button>
            )}
        </div>
    );
};
