import { translateSelector } from '@ackee/jerome';
import { createSelector } from '@reduxjs/toolkit';

import type { SecurityAuditLeaksReport } from 'modules/security-audit';

import { SecurityLeaks, ListItemType, UP_TO_LEAKS } from '../../constants';
import type { SecurityAuditLeaksSecret, SecurityAuditLeaksGroup } from '../../types';

import { selectPasswordSecrets } from './list';
import { getSecretsWithGroups, compareByName } from './utilities';

export const getSecretSecurityLeaks = (leaksIncidence: number = 0) =>
    leaksIncidence === 0
        ? SecurityLeaks.NO_LEAKS
        : leaksIncidence < UP_TO_LEAKS
        ? SecurityLeaks.FEW_LEAKS
        : SecurityLeaks.MANY_LEAKS;

const selecSecurityAuditLeaksListSecret = createSelector(selectPasswordSecrets, secrets =>
    secrets.map<SecurityAuditLeaksSecret>(secret => ({
        ...secret,
        securityLeaks: getSecretSecurityLeaks(secret.leaksIncidence),
        listItemType: ListItemType.SECRET,
        key: `leaks-secret-${secret.id}`,
    })),
);

export const selectSecurityAuditLeaksSortedListSecrets = createSelector(
    [selecSecurityAuditLeaksListSecret, translateSelector],
    (secrets, { locale }) =>
        secrets.sort(function (secretA, secretB) {
            return secretB.securityLeaks - secretA.securityLeaks || compareByName(secretA, secretB, locale);
        }),
);

const createAuditLeaksGroup = (securityLeaks: SecurityLeaks): SecurityAuditLeaksGroup => ({
    listItemType: ListItemType.GROUP,
    key: `leaks-group-${securityLeaks}`,
    securityLeaks,
    count: 0,
});

export const getSecurityAuditSecretsWithLeaksGroups = (secrets: SecurityAuditLeaksSecret[]) =>
    getSecretsWithGroups(
        secrets,
        (nextSecret, currentSecret) => nextSecret.securityLeaks !== currentSecret.securityLeaks,
        secret => createAuditLeaksGroup(secret.securityLeaks),
        group => {
            group.count += 1;
        },
    );

export const selectSecurityAuditSecretsWithLeaksGroups = createSelector(
    [selectSecurityAuditLeaksSortedListSecrets],
    getSecurityAuditSecretsWithLeaksGroups,
);

export const selectSecurityAuditLeaksReports = createSelector(selecSecurityAuditLeaksListSecret, secrets => {
    const initialAgg: Record<SecurityLeaks, number> = {
        [SecurityLeaks.NO_LEAKS]: 0,
        [SecurityLeaks.FEW_LEAKS]: 0,
        [SecurityLeaks.MANY_LEAKS]: 0,
    };

    const secretGroups = secrets.reduce<Record<SecurityLeaks, number>>((report, secret) => {
        report[secret.securityLeaks] += 1;

        return report;
    }, initialAgg);

    const reports: SecurityAuditLeaksReport[] = [
        SecurityLeaks.MANY_LEAKS,
        SecurityLeaks.FEW_LEAKS,
        SecurityLeaks.NO_LEAKS,
    ].map(leaks => ({
        percentage: (secretGroups[leaks] / secrets.length) * 100,
        total: secretGroups[leaks],
        leaks,
    }));

    return reports;
});
