import { FormattedMessage } from 'react-intl';

import { ConfirmationModal, StaticModal, useModal } from 'modules/modals';
import { Heading, Icon, Text } from 'modules/ui';

import { useRemoveTag } from '../../hooks/useRemoveTag';

import * as felaRules from './RemoveTagModal.rules';

export const RemoveTagModal = () => {
    const { modal, updateModal } = useModal<{
        tag: string;
        count: number;
    }>(StaticModal.REMOVE_TAG, {
        autoClose: true,
    });

    const { api, remove } = useRemoveTag(modal?.data?.tag ?? '');

    return (
        <ConfirmationModal
            open={modal?.visibility}
            icon={null}
            width={474}
            cancelButton={{
                onClick: () => {
                    updateModal(false);
                },
                disabled: api.inProgress,
                children: <FormattedMessage id="tags.remove.cancel" />,
            }}
            okButton={{
                onClick: () => {
                    remove();
                },
                loading: api.inProgress,
                disabled: api.inProgress,
                children: <FormattedMessage id="tags.remove.ok" />,
            }}
        >
            <Heading color="black" customStyle={felaRules.title}>
                <Icon type="trash" color="alertsErrorText" />
                <FormattedMessage
                    id="tags.remove.title"
                    values={{
                        tag: modal?.data?.tag,
                    }}
                />
            </Heading>
            <Text size="small" color="brownGrey" customStyle={felaRules.info}>
                <FormattedMessage
                    id="tags.remove.info"
                    values={{
                        count: modal?.data?.count,
                    }}
                />
            </Text>
        </ConfirmationModal>
    );
};
