import { z } from 'zod';

import { Fields, SecretType } from '../constants';
import { basicSecretFormSchema } from './basicSecretFormSchema';

export const sshKeyFormSchema = basicSecretFormSchema.merge(
    z.object({
        [Fields.TYPE]: z.literal(SecretType.SshKey),
        [Fields.PRIVATE_KEY]: z.string().optional(),
        [Fields.PUBLIC_KEY]: z.string().optional(),
    }),
);
