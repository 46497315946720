import downloadFile from 'js-file-download';

import type { Base64File } from '../types';

export function getBase64EncodedFile(file: File) {
    return new Promise<Base64File>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (reader.result === null) {
                return reject(new Error('Invalid file'));
            }

            resolve({
                data: reader.result.toString(),
                name: file.name,
            });
        };
        reader.onerror = error => reject(error);
    });
}

export async function getFileFromBase64(base64File: Base64File) {
    const response = await fetch(base64File.data);
    const blob = await response.blob();

    return new File([blob], base64File.name);
}

export async function downloadBase64File(base64File: Base64File) {
    const file = await getFileFromBase64(base64File);

    downloadFile(file, file.name);
}
