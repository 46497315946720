import { type PropsWithChildren } from 'react';

import { FormSubmitContext, FormSubmitContextValue } from './context';

export interface FormSubmitProviderProps extends PropsWithChildren {
    submit: FormSubmitContextValue;
}

export const FormSubmitProvider = ({ children, submit }: FormSubmitProviderProps) => (
    <FormSubmitContext.Provider value={submit}>{children}</FormSubmitContext.Provider>
);
