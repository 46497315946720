import type { TRuleWithTheme } from 'styles/theme';

export const sharingContainer: TRuleWithTheme = ({ theme }) => ({
    marginTop: '1.25rem',
    marginBottom: 0,

    paddingTop: '0.75rem',
    paddingLeft: '1rem',
    paddingBottom: '0.75rem',
    paddingRight: '1rem',

    backgroundColor: theme.colors.backgroundsOnSecondary,

    borderRadius: '0.5rem',
});
export const privateLinkContainer: TRuleWithTheme = ({ theme }) => ({
    marginTop: '1.25rem',
    borderTop: `1px solid ${theme.colors.foregroundsSeparator}`,
});
export const buttons: TRuleWithTheme = () => ({
    textAlign: 'right',
});
