import { FormattedMessage } from 'react-intl';

import { hasReadPermission } from 'utilities/permission';
import { isEmptyOrUndefined } from 'utilities/string';
import { CopyButton } from 'modules/ui';

import { RedactedPaymentCardNumber } from '../../RedactedPaymentCardNumber';
import { SecretFieldSection } from '../../SecretFieldSection';
import type { PaymentCardTypeSecret } from '../../../types';
import { formatPaymentCardNumber } from '../../../utilities';
import { EmptyContentGuard } from '../EmptyContentGuard';

export interface PaymentCardSectionsProps {
    secret: PaymentCardTypeSecret;
}

export const PaymentCardSections = ({ secret }: PaymentCardSectionsProps) => {
    const { userPermissions, cardNumber, expirationDate, cvvCode, cardholderName, redactedCardNumber } = secret;

    const hasRead = hasReadPermission(userPermissions);

    return (
        <EmptyContentGuard>
            {!isEmptyOrUndefined(cardNumber) && (
                <SecretFieldSection heading={<FormattedMessage id="secret.cardNumber.label" />}>
                    <CopyButton
                        type="paymentCardNumber"
                        mask={<RedactedPaymentCardNumber redactedNumber={redactedCardNumber} />}
                        text={formatPaymentCardNumber(cardNumber)}
                        disabled={!hasRead}
                    />
                </SecretFieldSection>
            )}

            {!isEmptyOrUndefined(expirationDate) && (
                <SecretFieldSection heading={<FormattedMessage id="secret.expirationDate.label" />}>
                    <CopyButton text={expirationDate} />
                </SecretFieldSection>
            )}

            {!isEmptyOrUndefined(cvvCode) && (
                <SecretFieldSection heading={<FormattedMessage id="secret.cvvCode.label" />}>
                    <CopyButton type="cvvCode" text={cvvCode} disabled={!hasRead} />
                </SecretFieldSection>
            )}

            {!isEmptyOrUndefined(cardholderName) && (
                <SecretFieldSection heading={<FormattedMessage id="secret.cardholderName.label" />}>
                    <CopyButton text={cardholderName} />
                </SecretFieldSection>
            )}
        </EmptyContentGuard>
    );
};
