import { useFela } from 'react-fela';
import { FormattedMessage, useIntl } from 'react-intl';

import { Switch } from 'modules/forms';
import { IconButton, Link, Text } from 'modules/ui';
import { useIsAdmin } from 'modules/auth';
import config from 'config';
import { useIsFreeTier } from 'modules/settings';
import { useSubscriptionUpgradeModal } from 'modules/saas-upgrade';

import { useShareSecret } from '../../../hooks/useShareSecret';
import type { Secret } from '../../../types';

import * as felaRules from './ShareSecretForm.rules';

export interface ShareSecretButtonProps {
    id: Secret['id'];
    share?: Secret['share'];
    canUserShare: boolean;
}

export const ShareSecretForm = ({ id, share, canUserShare }: ShareSecretButtonProps) => {
    const { css } = useFela();
    const intl = useIntl();

    const isAdmin = useIsAdmin();
    const isFreeTier = useIsFreeTier();

    const { inProgress, shared, handleShare } = useShareSecret(id, share);
    const { openUpgradeModal } = useSubscriptionUpgradeModal();

    return (
        <>
            <div className={css(felaRules.form)}>
                <div className={css(felaRules.title)}>
                    <Text elementType="h3">
                        <FormattedMessage id="secret.share.info" />
                    </Text>
                    {isFreeTier && (
                        <IconButton
                            type="ghost"
                            icon={{ type: 'premium', color: 'alertsWarningText' }}
                            onClick={openUpgradeModal}
                        />
                    )}
                </div>

                {canUserShare ? (
                    <Switch
                        aria-label={intl.formatMessage({
                            id: shared ? 'secret.share.disable' : 'secret.share.enable',
                        })}
                        disabled={inProgress}
                        checked={shared}
                        onChange={isFreeTier ? undefined : handleShare}
                        onClick={isFreeTier ? openUpgradeModal : undefined}
                        customStyle={isFreeTier ? felaRules.freeTierSwitchButton : undefined}
                    />
                ) : (
                    <Text size="extraSmall" color="foregroundsTertiary">
                        {isAdmin ? (
                            <FormattedMessage
                                id="secret.share.unavailable.admin"
                                values={{
                                    link: chunks => (
                                        <Link to={config.routes.settings} type="router" inheritColor>
                                            {chunks}
                                        </Link>
                                    ),
                                }}
                            />
                        ) : (
                            <FormattedMessage id="secret.share.unavailable.user" />
                        )}
                    </Text>
                )}
            </div>
            <Text size="small" color="foregroundsTertiary" elementType="p" customStyle={felaRules.description}>
                <FormattedMessage id="secret.share.description" />
            </Text>
        </>
    );
};
