import { createSelector } from '@reduxjs/toolkit';

import { hasWritePermission } from 'utilities/permission';

import { selectIdProp, authUser } from 'services/selectors';

import { selectSecretsEntities } from './entities';

import { SecretType, TOTP_VALUE_PLACEHOLDER } from '../../constants';
import { filterValuesBySchema } from './utilities';

const selectSecretsDetail = createSelector([selectSecretsEntities], ({ detail }) => detail);

export const selectSecret = createSelector([selectSecretsDetail, selectIdProp], (secrets, id) => {
    const secret = secrets[id];

    return secret
        ? {
              ...secret,
              // Keep this from version where creator was added to whitelistUsers
              whitelistUsers: secret.whitelistUsers?.filter(user => user.id !== secret.createdBy?.id),
          }
        : undefined;
});

export const selectHasSecret = createSelector([selectSecret], secret => Boolean(secret));
export const selectHasSecretPermissions = createSelector([selectSecret], secret =>
    hasWritePermission(secret?.userPermissions),
);

export const selectUpdateSecretFormInitialValues = createSelector([selectSecret], secret => {
    if (!secret) return {};

    const values = filterValuesBySchema(secret);

    if (secret.type === SecretType.Password) {
        return {
            ...values,
            TOTP: values.hasTOTP ? TOTP_VALUE_PLACEHOLDER : null,
        };
    }

    return values;
});

export const selectCreatedBy = createSelector([selectSecret, authUser], (secret, user) => {
    if (secret) {
        return secret.createdBy?.id;
    }

    return user?.id;
});

export const secretNameSelector = createSelector([selectSecret], secret => secret?.name ?? '');
