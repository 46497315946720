export interface WebsiteFaviconProps {
    width?: number;
    height?: number;
    base64Favicon: string;
    name: string;
}

export const WebsiteFavicon = ({ width = 28, height = 28, base64Favicon, name }: WebsiteFaviconProps) => (
    <img src={`data:image/png;base64,${base64Favicon}`} alt={`${name} favicon`} width={width} height={height} />
);
