import type { AccessPermission, User } from 'types';

import type { Group } from 'modules/groups';
import type { WhitelistUser } from 'modules/whitelist';

import type { Fields, ListItemType, SecretType, SecurityAge, SecurityLeaks, SecurityLevel } from './constants';
import type { SecretFormValues } from './schemas';

export enum SearchOptionType {
    /**
     * Literal search term (e.g. "foo bar"). Saved to search history when neither record, tag or group is selected. Search is done locally.
     */
    FILTER = 'filter',
    /**
     * Secret tag searchable by name. Search is done locally.
     */
    TAG = 'tag',
    /**
     * Google Workspace group searchable by name. Search results are fetched from API.
     */
    GROUP = 'group',
    /**
     * Secret record searchable by name or username. Search is done locally.
     */
    RECORD = 'record',
}

export interface SearchHistory {
    value: string;
    label: string;
    type: SearchOptionType;
}

export enum SearchSecretAttributeType {
    TAG = 'tag',
    GROUP = 'group',
}

export interface ViewFilter {
    id: string;
    email: string;
    name: string;
    type: 'user' | 'group';
}

export interface Base64File {
    name: string;
    data: string;
}

export interface Share {
    id: string;
    until: string;
}

export interface TOTPInfo {
    algorithm: string;
    codeLength: number;
    period: number;
}

export interface TOTP {
    code: string;
    validityStart: number;
    validityEnd: number;
}

interface SecretBase {
    id: string;
    createdAt?: string;
    updatedAt?: string;
    userPermissions: AccessPermission[];
    share?: Share | null;
    createdBy?: User;
    groups?: Group[];
    whitelistUsers?: WhitelistUser[];
    sensitiveFields?: Fields[];
}

interface AdditionalPasswordSecretProperties {
    securityLevel?: SecurityLevel;
    ignoreSecurityReport?: boolean;
    leaksIncidence?: number;
    favicon?: string;
    hasPassword?: boolean;
    passwordHashed?: string;
    passwordUpdatedAt?: string;
}

interface AdditionalPaymentCardSecretProperties {
    redactedCardNumber?: string;
}

export type PasswordTypeSecret = SecretBase &
    AdditionalPasswordSecretProperties &
    Extract<SecretFormValues, { type: SecretType.Password }>;

export type PaymentCardTypeSecret = SecretBase &
    AdditionalPaymentCardSecretProperties &
    Extract<SecretFormValues, { type: SecretType.PaymentCard }>;

export type OtherTypesSecret = SecretBase &
    Exclude<SecretFormValues, { type: SecretType.Password | SecretType.PaymentCard }>;

export type Secret = PasswordTypeSecret | PaymentCardTypeSecret | OtherTypesSecret;

export interface ListSecretBase {
    key: string;
    listItemType: ListItemType.SECRET;
}

export interface ListGroupBase {
    key: string;
    listItemType: ListItemType.GROUP;
}

export type ListSecret = ListSecretBase &
    Secret & {
        latinisedName: string;
        group: string;
    };

export interface ListGroup extends ListGroupBase {
    name: string;
}

export type SecurityAuditLevelSecret = ListSecretBase & PasswordTypeSecret;

export interface SecurityAuditLevelGroup extends ListGroupBase {
    securityLevel: SecurityLevel;
    count: number;
}

export type SecurityAuditAgeSecret = ListSecretBase &
    PasswordTypeSecret & {
        securityAge: SecurityAge;
    };

export interface SecurityAuditAgeGroup extends ListGroupBase {
    securityAge: SecurityAge;
    count: number;
}

export type SecurityAuditDuplicitiesSecret = ListSecretBase &
    PasswordTypeSecret & {
        duplicities?: number;
    };

export interface SecurityAuditDuplicitiesGroup extends ListGroupBase {
    count: number;
}

export type SecurityAuditLeaksSecret = ListSecretBase &
    PasswordTypeSecret & {
        securityLeaks: SecurityLeaks;
    };

export interface SecurityAuditLeaksGroup extends ListGroupBase {
    securityLeaks: SecurityLeaks;
    count: number;
}

export type SecretById = {
    [key: Secret['id']]: Secret;
};

export enum EntityKey {
    SECRETS = 'secrets',
}

export type SecurityAuditLevelItem = SecurityAuditLevelSecret | SecurityAuditLevelGroup;

export type SecurityAuditAgeItem = SecurityAuditAgeSecret | SecurityAuditAgeGroup;

export type SecurityAuditLeaksItem = SecurityAuditLeaksSecret | SecurityAuditLeaksGroup;

export type SecurityAuditReportItem =
    | SecurityAuditDuplicitiesGroup
    | SecurityAuditDuplicitiesSecret
    | SecurityAuditAgeItem
    | SecurityAuditLeaksItem
    | SecurityAuditLevelItem;

export interface ListItemSize {
    desktop: number;
    mobile: number;
}
