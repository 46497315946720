import { SecretType } from '../../../constants';
import type { Secret } from '../../../types';
import { ApiCredentialsSections } from '../ApiCredentialsSections';
import { DatabaseCredentialsSections } from '../DatabaseCredentialsSections';
import { PasswordSections } from '../PasswordSections';
import { PaymentCardSections } from '../PaymentCardSections';
import { SecureNoteSections } from '../SecureNoteSections';
import { SshKeySections } from '../SshKeySections';

export interface SecretFieldsByTypeProps {
    secret: Secret;
}

export const SecretFieldSectionsByType = ({ secret }: SecretFieldsByTypeProps) => {
    switch (secret.type) {
        case SecretType.Password:
            return <PasswordSections secret={secret} />;
        case SecretType.PaymentCard:
            return <PaymentCardSections secret={secret} />;
        case SecretType.ApiCredentials:
            return <ApiCredentialsSections secret={secret} />;
        case SecretType.DatabaseCredentials:
            return <DatabaseCredentialsSections secret={secret} />;
        case SecretType.SshKey:
            return <SshKeySections secret={secret} />;
        case SecretType.SecureNote:
            return <SecureNoteSections secret={secret} />;
        default:
            return null;
    }
};
