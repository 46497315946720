import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    width: 'calc(100% - 5rem)',
    maxWidth: '34rem',

    marginTop: '2.5rem',
    marginRight: 'auto',
    marginBottom: '2.5rem',
    marginLeft: 'auto',
});

export const wrapper: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',

    width: '100%',
    minHeight: '16.5rem',

    backgroundColor: theme.colors.white,
    borderRadius: '1rem',

    ':not(:last-child)': {
        marginBottom: '2.5rem',
    },
});

export const graph: TRuleWithTheme = () => ({
    display: 'flex',

    width: '100%',

    marginTop: '3rem',
});
