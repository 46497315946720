import { TOTP_VALUE_PLACEHOLDER } from '../constants';
import { getFileFromBase64 } from './files';
import type { Base64File } from '../types';

export const TOTP_REGEX =
    /^([oO][tT][pP][aA][uU][tT][hH]:\/\/[tT][oO][tT][pP]\/.*[sS][eE][cC][rR][eE][tT]=[a-zA-Z1-7]{16,}.*|[a-zA-Z1-7]{16,})$/;

export const isValidTOTP = (value: string | null) => {
    if (value === null) return false;

    if (value === TOTP_VALUE_PLACEHOLDER) {
        return true;
    }

    const valueWithoutSpaces = value.replace(/\s/g, '');

    return TOTP_REGEX.test(valueWithoutSpaces);
};

export const MAX_FILE_SIZE = process.env.REACT_APP_SAAS === 'true' ? 30 * 1024 : 512 * 1024; // 30KiB for SAAS, 512KiB for clients

export const isValidFileSize = async (value: Base64File) => {
    if (!value) return false;

    const file = await getFileFromBase64(value);

    return file.size <= MAX_FILE_SIZE;
};
