import type { CSSProperties } from 'react';

import type { ListSecret } from 'modules/secrets/types';

import { SecretItem } from '../SecretItem';

export interface ItemRendererProps {
    className: string;
    style: CSSProperties;
    data: ListSecret[];
    index: number;
}

const defaultData = [];

export const ItemRenderer = ({ className, style, data = defaultData, index }: ItemRendererProps) => {
    const { key, ...secret } = data[index];

    return (
        <div className={className} style={style}>
            <SecretItem key={key} {...secret} />
        </div>
    );
};
