import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    overflow: 'auto',
    whiteSpace: 'nowrap',

    paddingRight: '1.5rem',
    paddingBottom: '1.5rem',
    paddingLeft: '1.5rem',

    MsOverflowStyle: 'none',
    scrollbarWidth: 'none',

    '&::-webkit-scrollbar': {
        display: 'none',
    },

    min1024: {
        paddingTop: '1.5rem',
    },
});

export const link: TRuleWithTheme = ({ theme }) => ({
    position: 'relative',

    display: 'inline-block',

    height: '2.5rem',

    paddingTop: 'calc(0.5rem - 1px)',
    paddingRight: 'calc(1rem - 1px)',
    paddingBottom: 'calc(0.5rem - 1px)',
    paddingLeft: 'calc(1rem - 1px)',

    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: '1.5rem',

    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '1.25rem',
    borderColor: 'transparent',

    color: theme.colors.black,

    ':hover': {
        color: theme.colors.brightMagenta,
    },

    ':focus': {
        color: theme.colors.brightMagenta,
    },

    '&.active': {
        cursor: 'default',

        borderColor: theme.colors.brightMagenta,
        color: theme.colors.brightMagenta,
    },

    '& > svg': {
        position: 'absolute',

        top: 0,
        right: 0,
    },
});
