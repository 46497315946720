import { AUTH_SESSION_END } from '@ackee/petrus';
import { basicResetReducer } from '@ackee/redux-utils';
import { createReducer } from '@reduxjs/toolkit';

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import type { SecretListId } from '../../constants';
import type { SearchSecretAttributeType, SearchHistory, ViewFilter } from '../../types';

import {
    setSecretsNameFilter,
    setSecretsListOffset,
    toggleActiveSecretAttributeFilter,
    clearActiveSecretAttributeFilters,
    setSecretsTotalCount,
    setViewFilter,
    setViewFilterIds,
    setSearchHistory,
    removeSearchHistory,
} from '../actions';

const MAX_HISTORY = 8;

const initialState: {
    activeSecretsAttributesFilters: {
        value: string;
        label: string;
        type: SearchSecretAttributeType;
    }[];
    secretsFilter: string;
    secretsListOffset: {
        offset: number;
        listId: SecretListId;
    } | null;
    viewFilter: ViewFilter | null;
    viewFilterIds: string[] | null;
    searchHistory: SearchHistory[];
    totalCount: number;
} = {
    activeSecretsAttributesFilters: [],
    secretsFilter: '',
    secretsListOffset: null,
    viewFilter: null,
    viewFilterIds: null,
    searchHistory: [],
    totalCount: 0,
};

const childReducer = createReducer(initialState, builder => {
    builder
        .addCase(setSecretsNameFilter, (state, action) => {
            state.secretsFilter = action.payload.value;
        })
        .addCase(setSecretsListOffset, (state, action) => {
            state.secretsListOffset = action.payload;
        })
        .addCase(toggleActiveSecretAttributeFilter, (state, action) => {
            const {
                payload: { active, ...newFilter },
            } = action;

            let activeFilters = state.activeSecretsAttributesFilters;

            if (active) {
                if (
                    !activeFilters.some(
                        activeFilter => activeFilter.type === newFilter.type && activeFilter.value === newFilter.value,
                    )
                ) {
                    activeFilters.push(newFilter);
                }
            } else {
                activeFilters = activeFilters.filter(activeFilter => {
                    if (activeFilter.type !== newFilter.type) return true;
                    if (activeFilter.value !== newFilter.value) return true;
                    return false;
                });
            }

            state.activeSecretsAttributesFilters = activeFilters;
        })
        .addCase(clearActiveSecretAttributeFilters, state => {
            state.activeSecretsAttributesFilters = [];
        })
        .addCase(setSecretsTotalCount, (state, action) => {
            state.totalCount = action.payload.totalCount;
        })
        .addCase(setViewFilter, (state, action) => {
            state.viewFilter = action.payload.view;
        })
        .addCase(setViewFilterIds, (state, action) => {
            state.viewFilterIds = action.payload.ids;
        })
        .addCase(setSearchHistory, (state, action) => {
            const option = action.payload;
            const index = state.searchHistory.findIndex(
                ({ value, type }) => type === option.type && value === option.value,
            );

            if (index > -1) {
                state.searchHistory.splice(index, 1);
            }

            state.searchHistory.unshift(option);

            if (state.searchHistory.length > MAX_HISTORY) {
                state.searchHistory = state.searchHistory.slice(0, MAX_HISTORY);
            }
        })
        .addCase(removeSearchHistory, (state, action) => {
            const valueToRemove = action.payload.value;

            state.searchHistory = state.searchHistory.filter(({ value }) => value !== valueToRemove);
        });
});

const resettedList = basicResetReducer(childReducer, AUTH_SESSION_END);

export const reducer = persistReducer(
    {
        key: 'secrets.index',
        storage,
        whitelist: ['searchHistory', 'viewFilter', 'viewFilterIds'],
    },
    resettedList,
);
