import { useCallback, useEffect, useRef, useState } from 'react';
import { milliseconds } from 'date-fns';

import { StaticModal, useModal } from 'modules/modals';
import { useSyncRef } from 'hooks/useSyncRef';
import { IS_CYPRESS_RUNNING } from 'constants/index';

export enum ReviewPromptStep {
    Intro = 'intro',
    Form = 'form',
    ThankYou = 'thankYou',
}

interface ReviewModalState {
    reviewSubmitted: boolean;
    modalDisplayedCount: number;
    nextDisplayTime: number;
}

const INITIAL_MODAL_STATE = {
    reviewSubmitted: false,
    modalDisplayedCount: 0,
    nextDisplayTime: new Date().getTime(),
} satisfies ReviewModalState;

const MIN_INTERVAL = milliseconds({ months: 1 });
const MAX_DISPLAY_COUNT = 5;

export const useReviewPrompt = () => {
    const { modal, updateModal } = useModal<ReviewModalState>(StaticModal.REVIEW_PROMPT);
    const [step, setStep] = useState<ReviewPromptStep>(ReviewPromptStep.Intro);

    const modalRef = useSyncRef(modal);
    const updateModalRef = useRef(updateModal);

    const closeModal = useCallback(() => {
        updateModalRef.current(false, {
            reviewSubmitted: modalRef.current?.data?.reviewSubmitted ?? false,
            modalDisplayedCount: modalRef.current?.data?.modalDisplayedCount
                ? modalRef.current.data.modalDisplayedCount + 1
                : 1,
            nextDisplayTime: new Date().getTime() + MIN_INTERVAL,
        });
    }, [modalRef]);

    const onReviewSubmit = useCallback(() => {
        updateModalRef.current(true, {
            reviewSubmitted: true,
            nextDisplayTime: modalRef?.current?.data?.nextDisplayTime ?? 0,
            modalDisplayedCount: modalRef?.current?.data?.modalDisplayedCount ?? 0,
        });
    }, [modalRef]);

    useEffect(() => {
        if (IS_CYPRESS_RUNNING) {
            return;
        }

        if (!modalRef.current?.data) {
            updateModalRef.current(true, INITIAL_MODAL_STATE);
            return;
        }

        if (modalRef.current.data.reviewSubmitted || modalRef.current.data.modalDisplayedCount >= MAX_DISPLAY_COUNT) {
            return;
        }

        if (modalRef.current.data.nextDisplayTime < new Date().getTime()) {
            updateModalRef.current(true, modalRef.current.data);
        }
    }, [modalRef]);

    return { modal, step, setStep, closeModal, onReviewSubmit };
};
