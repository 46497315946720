import { combineRules } from 'fela';

import type { TRuleWithTheme } from 'styles/theme';

export const select: TRuleWithTheme = ({ theme }) => {
    const activeStyle = {
        borderColor: theme.colors.accentSecondary,
        boxShadow: 'none',
    };

    const borderWidth = 1;

    return {
        '&.ant-select': {
            width: '100%',
            height: '100%',
            maxWidth: '100%',
        },

        '&.ant-select .ant-select-selector': {
            height: '2.5rem',

            fontSize: '1rem',
            fontFamily: theme.fontFamily,
            lineHeight: '1.5rem',

            paddingLeft: '0.75rem',
            paddingRight: '0.5rem',

            color: theme.colors.black,
            backgroundColor: theme.colors.white,

            borderWidth: borderWidth,
            borderStyle: 'solid',
            borderColor: theme.colors.inputsBorder,
            borderRadius: '0.25rem',
        },

        '&.ant-select .ant-select-selector .ant-select-selection-search-input': {
            height: `calc(2.5rem - ${2 * borderWidth}px)`,
        },
        '&.ant-select .ant-select-selector .ant-select-selection-placeholder': {
            lineHeight: `calc(2.5rem - ${2 * borderWidth}px)`,
        },
        '&.ant-select .ant-select-selector .ant-select-selection-item': {
            lineHeight: `calc(2.5rem - ${2 * borderWidth}px)`,
        },

        '&.ant-select.ant-select-outlined.ant-select-focused:not(.ant-select-disabled) .ant-select-selector':
            activeStyle,
        '&.ant-select.ant-select-open:not(.ant-select-disabled) .ant-select-selector': activeStyle,
        '&.ant-select.ant-select-outlined:not(.ant-select-disabled):hover .ant-select-selector': activeStyle,

        '&.ant-select .ant-select-selection-placeholder': {
            color: theme.colors.foregroundsPlaceholder,
        },

        '&.ant-select.ant-select-show-arrow .ant-select-selection-item': {
            paddingRight: '2rem',
        },

        '&.ant-select.ant-select-show-arrow .ant-select-selection-placeholder': {
            paddingRight: '2rem',
        },

        '& .ant-select-dropdown': {
            paddingTop: '0.25rem',
            paddingRight: 0,
            paddingBottom: '0.25rem',
            paddingLeft: 0,

            borderRadius: '0.25rem',
        },

        '& .ant-select-dropdown .ant-empty-image': {
            display: 'none',
        },

        '& .ant-select-dropdown .ant-select-item-group': {
            height: '1px',
            minHeight: 'unset',

            padding: 0,

            marginTop: '0.375rem',
            marginBottom: '0.375rem',
            marginLeft: '1rem',
            marginRight: '1rem',

            backgroundColor: theme.colors.foregroundsSeparator,

            overflow: 'hidden',
        },

        '& .ant-select-dropdown .ant-select-item-group:first-child': {
            display: 'none',
        },

        '& .ant-select-selection-search': {
            marginLeft: 0,
        },
    };
};

export const option: TRuleWithTheme = ({ theme }) => ({
    '&.ant-select-item-option': {
        padding: '0.5rem 1rem',
        fontWeight: 500,
        fontFamily: theme.fontFamily,
        color: theme.colors.black,
        fontSize: '0.875rem',
        lineHeight: '1.5rem',
    },

    '&.ant-select-item-option.ant-select-item-option-selected': {
        backgroundColor: theme.colors.paleMauve,
        color: theme.colors.black,

        fontWeight: 500,
    },

    '&.ant-select-item-option.ant-select-item-option-active': {
        backgroundColor: theme.colors.white,
        color: theme.colors.blue,
    },

    '&.ant-select-item-option.ant-select-item-option-active.ant-select-item-option-selected': {
        backgroundColor: theme.colors.paleMauve,
        color: theme.colors.black,
    },
});

export const infinite: TRuleWithTheme = () => ({
    '&.ant-select-item-option': {
        cursor: 'default',

        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
    },

    '&.ant-select-item-option .ant-select-item-option-content': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

export const arrow: TRuleWithTheme = () => ({
    transform: 'scale(1, 1)',
    transition: 'transform 0.3s ease-in-out',
});

export const arrowOpen: TRuleWithTheme = combineRules(arrow, () => ({
    transform: 'scale(1, -1)',
}));
