import { createAction } from '@reduxjs/toolkit';

import type { Secret, TOTP } from '../../types';
import types from '../actionTypes';

export const getSecretTOTP = createAction(types.GET_SECRET_TOTP, (id: Secret['id'], isPubliclyShared?: boolean) => ({
    meta: { id, isPubliclyShared },
    payload: undefined,
}));

export const getSecretTOTPSuccess = createAction(types.GET_SECRET_TOTP_SUCCESS, (id: Secret['id'], totps: TOTP[]) => ({
    meta: { id, lastSuccessAt: new Date().toISOString() },
    payload: totps,
}));

export const getSecretTOTPFailure = createAction(types.GET_SECRET_TOTP_FAILURE, (id: Secret['id'], error: any) => ({
    meta: { id },
    payload: undefined,
    error,
}));

export const getSecretTOTPReset = createAction(types.GET_SECRET_TOTP_RESET, (id: Secret['id']) => ({
    meta: { id },
    payload: undefined,
}));

export const removeSecretTOTP = createAction(types.REMOVE_SECRET_TOTP, (id: Secret['id'], code: TOTP['code']) => ({
    meta: {
        code,
        id,
    },
    payload: undefined,
}));
