import type { ReactNode } from 'react';
import { combineRules } from 'fela';

import { Alert, Icon } from 'modules/ui';

import * as felaRules from './InfoLabel.rules';

export interface InfoLabelProps {
    children: ReactNode;
    customStyle?: typeof felaRules.message;
}

export const InfoLabel = ({ children, customStyle }: InfoLabelProps) => (
    <Alert
        type="info"
        message={children}
        showIcon
        icon={<Icon type="info" width={16} height={16} />}
        customStyle={customStyle ? combineRules(felaRules.message, customStyle) : felaRules.message}
    />
);
