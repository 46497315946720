import { useMemo } from 'react';
import { useFela } from 'react-fela';
import { FormattedMessage, FormattedDate, useIntl } from 'react-intl';
import { generatePath } from 'react-router';
import { useDispatch } from 'react-redux';

import config from 'config';

import { Button, Text, useCopyToClipboard } from 'modules/ui';
import { actions as messagesActions } from 'modules/messages';

import { useShareSecret } from '../../../hooks/useShareSecret';

import type { Secret } from '../../../types';

import * as felaRules from './ShareSecretInfo.rules';

export interface ShareSecretButtonProps {
    id: Secret['id'];
    share?: Secret['share'];
}

export const ShareSecretInfo = ({ id, share }: ShareSecretButtonProps) => {
    const { inProgress, handleShare } = useShareSecret(id, share);
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();

    const { css } = useFela();

    const shareUrl = useMemo(() => {
        if (share) {
            return new URL(
                generatePath(config.routes.share, {
                    id: share.id,
                }),
                window.location.toString(),
            ).toString();
        }
    }, [share]);

    const { handleCopy } = useCopyToClipboard({
        onSuccess: () => {
            dispatch(
                messagesActions.displaySuccessMessage({
                    message: formatMessage({ id: 'success.secrets.share' }),
                    options: { duration: 1 },
                }),
            );
        },
        onError: () => {
            dispatch(
                messagesActions.displayErrorMessage({
                    message: formatMessage({ id: 'error.secrets.share' }),
                    options: { duration: 1 },
                }),
            );
        },
    });

    return (
        <>
            <p className={css(felaRules.wrapper)}>
                <Text elementType="span" customStyle={felaRules.input}>
                    {shareUrl}
                </Text>

                <Button
                    type="primary"
                    onClick={() => {
                        if (shareUrl) handleCopy(shareUrl);
                    }}
                >
                    <FormattedMessage id="secret.share.copy" />
                </Button>
            </p>
            <Text size="small" color="brownGrey" elementType="p">
                <span className={css(felaRules.validUntil)}>
                    <FormattedMessage id="secret.share.validUntil" />
                </span>
                <FormattedDate
                    year="numeric"
                    month="numeric"
                    day="numeric"
                    hour="numeric"
                    minute="numeric"
                    value={share?.until}
                />
                <Button
                    type="text"
                    onClick={() => {
                        handleShare(true);
                    }}
                    disabled={inProgress}
                    size="small"
                    customStyle={felaRules.reset}
                >
                    <FormattedMessage id="secret.share.reset" />
                </Button>
            </Text>
        </>
    );
};
