import { camelCase } from 'lodash';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { useFela } from 'react-fela';
import { type ReactNode, useMemo } from 'react';

import { IS_SAAS } from 'constants/index';
import config from 'config';
import { Button, Icon, Link, Pane, Text } from 'modules/ui';
import { InfoLabel } from 'modules/forms';
import type { MessageKey } from 'modules/localizations';
import { useSubscriptionUpgradeModal } from 'modules/saas-upgrade';

import { useIsFreeTier } from '../../hooks';
import type { Order } from '../../types';

import * as felaRules from './OrderInfo.rules';

export interface OrderInfoProps {
    order?: Order;
}

interface OrderInfoContent {
    title: MessageKey;
    email: string | null;
    button: ReactNode;
    interval: Order['interval'];
    price: Order['amount'];
    userTier: Order['tier'] | null;
}

export const OrderInfo = ({ order }: OrderInfoProps) => {
    const { css } = useFela();
    const { formatMessage } = useIntl();

    const isFreeTier = useIsFreeTier();
    const { openUpgradeModal } = useSubscriptionUpgradeModal();

    const content: OrderInfoContent | null = useMemo(() => {
        if (isFreeTier) {
            return {
                title: 'settings.order.type.free',
                email: null,
                price: '0',
                interval: 'month',
                userTier: null,
                button: (
                    <Button type="link" size="middle" underline onClick={openUpgradeModal}>
                        <FormattedMessage id="settings.order.upgradeButton" />{' '}
                        <Icon type="link" width={12} height={12} />
                    </Button>
                ),
            } satisfies OrderInfoContent;
        }

        if (order) {
            return {
                title: IS_SAAS ? 'settings.order.type.workspace' : `settings.order.type.${order.interval}`,
                email: order.billingEmail,
                interval: order.interval,
                price: order.amount,
                userTier: order.tier,
                button: (
                    <Link size="regular" type="native" href={config.links.stripe}>
                        <FormattedMessage id="settings.order.link" /> <Icon type="link" width={12} height={12} />
                    </Link>
                ),
            } satisfies OrderInfoContent;
        }

        return null;
    }, [isFreeTier, order, openUpgradeModal]);

    if (!content) return null;

    return (
        <Pane
            data-testid="settings-order-info"
            extend={{
                container: felaRules.container,
            }}
        >
            <Text size="labelSmall" color="foregroundsTertiary">
                <FormattedMessage id="settings.order.type.title" />
            </Text>
            <h2 className={css(felaRules.title)}>
                <FormattedMessage id={content?.title} />
                {Boolean(content.email) && (
                    <span
                        className={css(felaRules.billingEmail)}
                        aria-label={formatMessage({
                            id: 'settings.order.billingEmail',
                        })}
                    >
                        <Text size="small">{content.email}</Text>
                    </span>
                )}
            </h2>
            <hr className={css(felaRules.divider)} />
            <p className={css(felaRules.info)}>
                <FormattedMessage
                    id={`settings.order.amount.${content.interval}`}
                    values={{
                        price: (
                            <FormattedNumber
                                value={Number.parseInt(content.price, 10) / 100} // Price comes in US cents and we need to convert it to dollars
                                minimumFractionDigits={0}
                                maximumFractionDigits={2}
                                // eslint-disable-next-line react/style-prop-object
                                style="currency"
                                currency="USD"
                            />
                        ),
                    }}
                />
            </p>
            {content.userTier ? (
                <p className={css(felaRules.info)}>
                    <FormattedMessage id={`settings.order.tier.${camelCase(content.userTier)}` as MessageKey} />
                </p>
            ) : null}

            {content.button}

            {!IS_SAAS && (
                <InfoLabel customStyle={felaRules.changeSubscriptionWarning}>
                    <FormattedMessage id="settings.order.changeSubscriptionWarning" />
                </InfoLabel>
            )}
        </Pane>
    );
};
