import { z } from 'zod';

import { AccessRole } from 'types';

import { SharingPermission } from '../constants';

export const settingsFormSchema = z.object({
    defaultPermission: z.nativeEnum(AccessRole),
    sharingPermission: z.nativeEnum(SharingPermission),
});

export type SettingsFormSchemaType = typeof settingsFormSchema;

export type SettingsFormValues = z.infer<typeof settingsFormSchema>;
