import type { DefaultValues } from 'react-hook-form';

import { Fields, SecretType } from './index';
import type { SecretFormValues } from '../schemas';

const commonDefaultValues = {
    [Fields.NAME]: '',
    [Fields.NOTE]: '',
    [Fields.TAGS]: [],
    [Fields.GROUPS]: [],
    [Fields.WHITELIST_USERS]: [],
    [Fields.FILE]: null,
};

export const passwordDefaultValues = {
    ...commonDefaultValues,
    [Fields.TYPE]: SecretType.Password,
    [Fields.USERNAME]: '',
    [Fields.WEB]: '',
    [Fields.PASSWORD]: '',
    [Fields.TOTP]: null,
    [Fields.HAS_TOTP]: false,
} satisfies DefaultValues<SecretFormValues>;

export const paymentCardDefaultValues = {
    ...commonDefaultValues,
    [Fields.TYPE]: SecretType.PaymentCard,
    [Fields.CARD_NUMBER]: '',
    [Fields.EXPIRATION_DATE]: '',
    [Fields.CVV_CODE]: '',
    [Fields.CARDHOLDER_NAME]: '',
} satisfies DefaultValues<SecretFormValues>;

export const apiCredentialsDefaultValues = {
    ...commonDefaultValues,
    [Fields.TYPE]: SecretType.ApiCredentials,
    [Fields.USERNAME]: '',
    [Fields.CREDENTIALS]: '',
    [Fields.HOSTNAME]: '',
} satisfies DefaultValues<SecretFormValues>;

export const databaseCredentialsDefaultValues = {
    ...commonDefaultValues,
    [Fields.TYPE]: SecretType.DatabaseCredentials,
    [Fields.DATABASE_NAME]: '',
    [Fields.DATABASE_TYPE]: '',
    [Fields.SERVER]: '',
    [Fields.PORT]: '',
    [Fields.USERNAME]: '',
    [Fields.PASSWORD]: '',
} satisfies DefaultValues<SecretFormValues>;

export const sshKeyDefaultValues = {
    ...commonDefaultValues,
    [Fields.TYPE]: SecretType.SshKey,
    [Fields.PUBLIC_KEY]: '',
    [Fields.PRIVATE_KEY]: '',
} satisfies DefaultValues<SecretFormValues>;

export const secureNoteDefaultValues = {
    ...commonDefaultValues,
    [Fields.TYPE]: SecretType.SecureNote,
    [Fields.SECURE_NOTE]: '',
} satisfies DefaultValues<SecretFormValues>;

export const secretTypeToDefaultValues = {
    [SecretType.Password]: passwordDefaultValues,
    [SecretType.PaymentCard]: paymentCardDefaultValues,
    [SecretType.ApiCredentials]: apiCredentialsDefaultValues,
    [SecretType.DatabaseCredentials]: databaseCredentialsDefaultValues,
    [SecretType.SshKey]: sshKeyDefaultValues,
    [SecretType.SecureNote]: secureNoteDefaultValues,
};
