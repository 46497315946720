import { FormattedMessage } from 'react-intl';

import { ConfirmationModal, StaticModal, useModal } from 'modules/modals';
import { Heading, Text } from 'modules/ui';

import * as felaRules from './HideGroupModal.rules';

import type { GroupWithUsedCount } from '../../types';
import { useChangeGroupVisibility } from '../../hooks';

export interface HideGroupModalProps {}

export const HideGroupModal = (props: HideGroupModalProps) => {
    const { modal, updateModal } = useModal<{
        group: GroupWithUsedCount;
    }>(StaticModal.HIDE_GROUP, {
        autoClose: true,
    });

    const { changeGroupVisibility, inProgress } = useChangeGroupVisibility(modal?.data?.group.id ?? '');

    return (
        <ConfirmationModal
            open={modal?.visibility}
            icon={null}
            width={474}
            cancelButton={{
                onClick: () => {
                    updateModal(false);
                },
                children: <FormattedMessage id="groups.hide.cancel" />,
            }}
            okButton={{
                disabled: inProgress,
                loading: inProgress,
                onClick: () => {
                    changeGroupVisibility(false);
                },
                children: <FormattedMessage id="groups.hide.ok" />,
            }}
        >
            <Heading color="black" customStyle={felaRules.title}>
                <FormattedMessage
                    id="groups.hide.title"
                    values={{
                        group: modal?.data?.group.name,
                    }}
                />
            </Heading>
            <Text size="small" color="brownGrey" customStyle={felaRules.info}>
                <FormattedMessage
                    id="groups.hide.info"
                    values={{
                        count: modal?.data?.group.usedCount,
                    }}
                />
            </Text>
        </ConfirmationModal>
    );
};
