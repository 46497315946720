import type { SVGProps } from 'react';

export const SecretTypeSshKey = (props: SVGProps<SVGSVGElement>) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.2824 9.37535C9.52444 9.37535 8.09494 7.94585 8.09494 6.18785C8.09494 4.42985 9.52444 3.00035 11.2824 3.00035C13.0404 3.00035 14.4699 4.42985 14.4699 6.18785C14.4699 7.94585 13.0404 9.37535 11.2824 9.37535ZM10.2242 1.61585C8.49094 1.99535 7.09669 3.3941 6.71269 5.12585C6.39619 6.5531 6.73819 7.89485 7.48369 8.9261L1.71994 14.6899C1.42669 14.9831 1.42669 15.4571 1.71994 15.7504C2.01319 16.0436 2.48719 16.0436 2.78044 15.7504L3.16669 15.3641L4.35619 16.5536C4.64869 16.8469 5.12344 16.8469 5.41669 16.5536C5.70919 16.2611 5.70919 15.7864 5.41669 15.4931L4.22719 14.3036L5.09944 13.4314L6.28894 14.6209C6.58144 14.9141 7.05619 14.9141 7.34944 14.6209C7.64194 14.3284 7.64194 13.8536 7.34944 13.5604L6.15994 12.3709L8.54419 9.9866C9.57619 10.7321 10.9179 11.0741 12.3452 10.7576C14.0769 10.3729 15.4757 8.9786 15.8544 7.24535C16.5992 3.8366 13.6329 0.870352 10.2242 1.61585Z"
            fill="currentColor"
        />
    </svg>
);
