import { TRuleWithTheme } from 'styles/theme';

export const alert: TRuleWithTheme = ({ theme }) => ({
    '&.ant-alert': {
        alignItems: 'center',
        marginTop: '0.5rem',
        marginBottom: 0,
    },

    '&.ant-alert .ant-alert-message': {
        fontSize: '0.75rem',
    },
});
