import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Heading, Text } from 'modules/ui';
import { ConfirmationModal, useModal } from 'modules/modals';

import { IGNORE_SECURITY_AUDIT_REPORT_MODAL } from '../../constants';
import type { Secret } from '../../types';

import { ignoreReportSecret } from '../../services/actions';

import { heading } from './IgnoreSecurityAuditReportModal.rules';

export const IgnoreSecurityAuditReportModal = () => {
    const { modal, updateModal } = useModal<{
        id: Secret['id'];
    }>(IGNORE_SECURITY_AUDIT_REPORT_MODAL, {
        autoClose: true,
    });

    const dispatch = useDispatch();

    return (
        <ConfirmationModal
            open={modal?.visibility ?? false}
            width={474}
            icon={null}
            okButton={{
                onClick: () => {
                    dispatch(ignoreReportSecret(modal?.data?.id ?? ''));
                    updateModal(false);
                },
                children: <FormattedMessage id="secret.modal.ignoreReport.ok" />,
            }}
            cancelButton={{
                onClick: () => {
                    updateModal(false);
                },
                children: <FormattedMessage id="secret.modal.ignoreReport.cancel" />,
            }}
            closable={false}
        >
            <Heading color="black" customStyle={heading} elementType="h2">
                <FormattedMessage id="secret.modal.ignoreReport.title" />
            </Heading>
            <Text size="small" color="brownGrey">
                <FormattedMessage id="secret.modal.ignoreReport.text" />
            </Text>
        </ConfirmationModal>
    );
};
