import type { ReactNode } from 'react';

import { useFela } from 'react-fela';
import { FormattedNumber } from 'react-intl';

import { Label } from 'modules/forms';
import { Text } from 'modules/ui';

import * as felaRules from './SecurityAuditGraphColumn.rules';

export interface SecurityAuditGraphColumnProps {
    total: number;
    percentage: number;
    label: ReactNode;
    color?: string;
    columnsCount: number;
    maxPercentage: number;
    isFirstColumn: boolean;
    isLastColumn: boolean;
}

export const SecurityAuditGraphColumn = ({
    percentage,
    total,
    label,
    color,
    columnsCount,
    maxPercentage,
    isFirstColumn,
    isLastColumn,
}: SecurityAuditGraphColumnProps) => {
    const { css } = useFela({ percentage, maxPercentage, color, columnsCount, isFirstColumn, isLastColumn });

    return (
        <div className={css(felaRules.wrapper)}>
            <div className={css(felaRules.columnContainer)}>
                <div className={css(felaRules.column)}>
                    <div className={css(felaRules.columnLabel)}>
                        <Text elementType="div" size="small" color="black" customStyle={felaRules.columnPercentage}>
                            {/* eslint-disable-next-line react/style-prop-object */}
                            <FormattedNumber value={percentage / 100} style="percent" maximumFractionDigits={1} />
                            <span className={css(felaRules.columnTotal)}>({total})</span>
                        </Text>
                    </div>
                </div>
            </div>
            <div className={css(felaRules.labelContainer)}>
                <Label customStyle={felaRules.label}>{label}</Label>
            </div>
        </div>
    );
};
