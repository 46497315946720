import { useIntl } from 'react-intl';

import { Fields } from '../../../constants';
import { TextField } from '../TextField';
import { PasswordField } from '../PasswordField';

export const DatabaseCredentialsFields = () => {
    const { formatMessage } = useIntl();

    return (
        <>
            <TextField name={Fields.DATABASE_NAME} />
            <TextField
                name={Fields.DATABASE_TYPE}
                placeholder={formatMessage({ id: `secret.${Fields.DATABASE_TYPE}.placeholder` })}
            />
            <TextField
                name={Fields.SERVER}
                placeholder={formatMessage({ id: `secret.${Fields.SERVER}.placeholder` })}
            />
            <TextField name={Fields.PORT} transformValue={val => val.replace(/[^\d]/g, '')} />
            <TextField name={Fields.USERNAME} />
            <PasswordField />
        </>
    );
};
