import { put, takeEvery } from 'redux-saga/effects';

import { config } from 'config/config';
import { logger } from 'config/logger';
import { api } from 'config/antonio';

import type { Secret } from '../../types';

import { readSecretSuccess, readSecretFailure, setSecret, setSecrets, readSecret } from '../actions';

import { normalizeSecret } from '../normalizers';

const prepareSecret = (secret: Secret, id: string): Secret => ({
    ...secret,
    id,
});

function* handler({ meta }: ReturnType<typeof readSecret>) {
    try {
        const { data } = yield* api.get<Secret>(config.api.secret, {
            uriParams: {
                id: meta.id,
            },
        });

        const secret = prepareSecret(data, meta.id);

        const { entities, result } = normalizeSecret(secret);

        yield put(setSecret(result, entities.secrets[result]));

        yield put(setSecrets(entities.secrets, [result]));

        yield put(readSecretSuccess(meta.id));
    } catch (e) {
        logger.error(e);

        yield put(readSecretFailure(meta.id, e));
    }
}

export default function* readSecrets() {
    yield takeEvery(readSecret.toString(), handler);
}
