import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';

import { Button, Icon, Link } from 'modules/ui';
import { useIsAdmin } from 'modules/auth';
import config from 'config';

import { SubscriptionType } from '../../../constants';
import { SubscriptionAlertModal } from '../SubscriptionAlertModal';

import * as felaRules from '../SubscriptionFirewall.rules';

export interface CanceledSubscriptionAlertProps {}

export const CanceledSubscriptionAlert = (props: CanceledSubscriptionAlertProps) => {
    const isAdmin = useIsAdmin();
    const { css } = useFela();
    const history = useHistory();

    const subscription = SubscriptionType.CANCELED;

    return (
        <SubscriptionAlertModal
            title={{ id: `settings.subscription.${subscription}.alert.title` }}
            text={{
                id: isAdmin
                    ? `settings.subscription.${subscription}.alert.adminText`
                    : `settings.subscription.${subscription}.alert.text`,
            }}
            endContent={
                <div className={css(felaRules.buttons)}>
                    {isAdmin ? (
                        <Link type="native" href={config.links.stripe}>
                            <Button icon={<Icon type="link" customStyle={felaRules.icon} />} type="primary">
                                <span>
                                    <FormattedMessage
                                        id={`settings.subscription.${subscription}.alert.updatePaymentMethod`}
                                    />
                                </span>
                            </Button>
                        </Link>
                    ) : null}
                    <Button
                        icon={<Icon type="logout" width="16" height="16" customStyle={felaRules.icon} />}
                        onClick={() => {
                            history.push(config.routes.logout);
                        }}
                    >
                        <span>
                            <FormattedMessage id={`settings.subscription.${subscription}.alert.logout`} />
                        </span>
                    </Button>
                </div>
            }
        />
    );
};
