import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Controller } from 'react-hook-form';

import { Button } from 'modules/ui';
import { Checkbox, Form, FormField } from 'modules/forms';

import { demoLoginFormSchema, type DemoLoginFormSchemaType, type DemoLoginFormValues } from '../../schemas';

import { login } from '../../services/actions';
import { GdprLabel } from './GdprLabel';

import { button } from './DemoLoginForm.rules';

export const DemoLoginForm = () => {
    const dispatch = useDispatch();

    return (
        <Form<DemoLoginFormSchemaType, DemoLoginFormValues>
            schema={demoLoginFormSchema}
            onSubmit={() => {
                dispatch(login());
            }}
            defaultValues={{ gdpr: false }}
        >
            <FormField<DemoLoginFormValues> name="gdpr">
                <Controller<DemoLoginFormValues, 'gdpr'>
                    name="gdpr"
                    render={({ field: { ref, value, onChange, ...field } }) => (
                        <Checkbox
                            id="btn_demo_terms"
                            {...field}
                            checked={value}
                            onChange={e => {
                                onChange(e.target.checked);
                            }}
                        >
                            <GdprLabel />
                        </Checkbox>
                    )}
                />
            </FormField>

            <Button id="btn_demo_launch" type="primary" htmlType="submit" customStyle={button}>
                <FormattedMessage id="auth.login.button.demo" />
            </Button>
        </Form>
    );
};
