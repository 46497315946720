import { useEffect, useState } from 'react';

const DEFAULT_IFRAME_HEIGHT = 750;

export const useIframeDimensions = (iframeContainerRef: React.RefObject<HTMLDivElement>) => {
    const iframeContainerHeight = iframeContainerRef.current?.clientHeight ?? DEFAULT_IFRAME_HEIGHT;

    const [iframeHeight, setIframeHeight] = useState(iframeContainerHeight);

    useEffect(() => {
        const onResize = () => {
            setIframeHeight(iframeContainerHeight);
        };

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [iframeContainerHeight]);

    return { iframeHeight };
};
