export interface SavedSelection {
    start: number;
    end: number;
}

export const saveSelection = function (container: HTMLElement | null): SavedSelection | null {
    if (!container) return null;

    const selection = window.getSelection();
    if (!selection) return null;

    const range = selection.getRangeAt(0);

    const preSelectionRange = range.cloneRange();

    preSelectionRange.selectNodeContents(container);
    preSelectionRange.setEnd(range.startContainer, range.startOffset);

    const start = preSelectionRange.toString().length;

    return {
        start: start,
        end: start + range.toString().length,
    };
};

export const restoreSelection = function (container: HTMLElement, savedSel: SavedSelection) {
    let charIndex = 0;

    const range = document.createRange();
    range.setStart(container, 0);
    range.collapse(true);

    let node: Node | undefined = container;
    const nodeStack: Node[] = [];

    let foundStart = false;

    do {
        // The node is a text node
        if (node.nodeType === 3) {
            const nextCharIndex = charIndex + node.textContent!.length;

            if (foundStart) {
                if (savedSel.end >= charIndex && savedSel.end <= nextCharIndex) {
                    range.setEnd(node, savedSel.end - charIndex);
                    break;
                }
            } else {
                if (savedSel.start >= charIndex && savedSel.start <= nextCharIndex) {
                    range.setStart(node, savedSel.start - charIndex);
                    foundStart = true;
                }
            }

            charIndex = nextCharIndex;
        } else {
            let i = node.childNodes.length;

            while (i--) {
                nodeStack.push(node.childNodes[i]);
            }
        }

        node = nodeStack.pop();
    } while (node);

    const sel = window.getSelection();

    sel?.removeAllRanges();
    sel?.addRange(range);
};

export const setSelectionAtTheEnd = (container: HTMLElement | null) => {
    const selection = window.getSelection();

    selection?.setPosition(container, container?.innerText.length);
};
