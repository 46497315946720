import { z } from 'zod';

import { Fields } from '../constants';

import { passwordSecretFormSchema } from './passwordSecretFormSchema';
import { paymentCardSecretFormSchema } from './paymentCardSecretFormSchema';
import { apiCredentialsFormSchema } from './apiCredentialsFormSchema';
import { databaseCredentialsSecretFormSchema } from './databaseCredentialsSchema';
import { sshKeyFormSchema } from './sshKeyFormSchema';
import { secureNoteSecretFormSchema } from './secureNoteSecretFormSchema';

export const secretFormSchema = z.discriminatedUnion(Fields.TYPE, [
    passwordSecretFormSchema,
    paymentCardSecretFormSchema,
    apiCredentialsFormSchema,
    databaseCredentialsSecretFormSchema,
    sshKeyFormSchema,
    secureNoteSecretFormSchema,
]);

export type SecretFormSchemaType = typeof secretFormSchema;

export type SecretFormValues = z.infer<typeof secretFormSchema>;
