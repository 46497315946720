import { apiSelector } from '@ackee/redux-utils';
import { useParams } from 'react-router-dom-v5-compat';
import { useDispatch } from 'react-redux';
import { useWatch } from 'react-hook-form';

import { useAppSelector } from 'hooks/useAppSelector';
import { useModal } from 'modules/modals';

import { Fields, REMOVE_SECRET_MODAL_KEY } from '../constants';
import { removeSecret } from '../services/actions';
import type { SecretFormValues } from '../schemas';

export const useRemoveSecret = () => {
    const { id } = useParams<{
        id: string;
    }>();

    const name = useWatch<SecretFormValues>({ name: Fields.NAME }) as string;

    const { modal, updateModal } = useModal(REMOVE_SECRET_MODAL_KEY);

    const { inProgress: deleting } = useAppSelector(state => apiSelector(state, 'secrets', 'remove', id));

    const dispatch = useDispatch();

    const handleRemove = () => {
        if (!id) return;

        dispatch(removeSecret(id));
    };

    const handleClose = () => {
        updateModal(false);
    };

    return {
        open: modal?.visibility,
        name,
        deleting,
        handleRemove,
        handleClose,
    };
};
