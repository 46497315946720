import { isEmpty } from 'lodash';

import { useSecurityAuditAgeSecretsList } from '../../hooks/useSecurityAuditAgeSecretsList';

import { SecretsListContainer } from '../SecretsListContainer';
import { SecurityAuditAgeSecretsList } from '../SecurityAuditAgeSecretsList';
import { SecurityAuditListEmpty } from '../SecurityAuditListEmpty';

export const SecurityAuditAgeSecrets = () => {
    const data = useSecurityAuditAgeSecretsList();

    return (
        <SecretsListContainer
            isEmpty={isEmpty(data)}
            empty={<SecurityAuditListEmpty messageId="securityAudit.list.empty" />}
        >
            {({ height, width }) => (
                <SecurityAuditAgeSecretsList data={data} height={height ?? 600} width={width ?? 800} />
            )}
        </SecretsListContainer>
    );
};
