import { FormattedMessage } from 'react-intl';
import type { ReactNode } from 'react';

import { Text } from 'modules/ui';
import type { MessageKey } from 'modules/localizations/translations/types';

import { SecretFieldSection } from '../../../../../SecretFieldSection';
import { CopySecretValue } from '../../../CopySecretValue';

import type { Secret } from '../../../../../../types';
import type { Fields } from '../../../../../../constants';

import * as felaRules from './CopySection.rules';

export interface CopySectionProps {
    secret: Secret;
    property: Fields;
    mask?: ReactNode;
    headingMessageKey: MessageKey;
    visible?: boolean;
    disabled?: boolean;
}

export const CopySection = ({ secret, property, mask, headingMessageKey, visible, disabled }: CopySectionProps) => {
    if (!visible) return null;

    const displayedText = mask ?? secret[property];

    return (
        <SecretFieldSection
            heading={<FormattedMessage id={headingMessageKey} />}
            customLabelStyle={felaRules.propertyLabel}
        >
            <Text size="regular" customStyle={mask ? felaRules.cryptedCharacters : felaRules.propertyText}>
                {displayedText}
            </Text>
            <CopySecretValue id={secret.id} property={property} disabled={disabled} />
        </SecretFieldSection>
    );
};
