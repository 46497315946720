import type { ReactNode } from 'react';

import attrAccept from 'attr-accept';

import type { RcFile } from 'antd/es/upload';

import { InnerInput } from './InnerInput';
import { InnerFile } from './InnerFile';

export enum FileInputAction {
    DROPPED = 'dropped',
    UNSUPPORTED = 'unsupported',
}

export interface FileBase {
    name: string;
}

export interface FileInputProps<F extends FileBase> {
    file?: F | null;
    onFile: (file?: RcFile, action?: `${FileInputAction}`) => void;
    accept?: string;
    disabled?: boolean;
    hasError?: boolean;
    renderFile?: (options: { onRemove: () => void }) => ReactNode;
}

export function FileInput<F extends FileBase>({
    file,
    onFile,
    renderFile,
    disabled,
    accept,
    hasError,
}: FileInputProps<F>) {
    if (file) {
        const onRemove = () => {
            onFile(undefined);
        };

        return (
            <>
                {renderFile?.({
                    onRemove,
                }) ?? <InnerFile name={file.name} onRemove={onRemove} />}
            </>
        );
    }

    return (
        <InnerInput
            disabled={disabled}
            hasError={hasError}
            onFile={file => {
                if (!file) return;

                onFile(
                    file,
                    accept && !attrAccept(file, accept) ? FileInputAction.UNSUPPORTED : FileInputAction.DROPPED,
                );
            }}
        />
    );
}
