import { useFela } from 'react-fela';
import type { PropsWithChildren } from 'react';

import * as felaRules from './SideSection.rules';

export interface SideSectionProps extends PropsWithChildren {}

export const SideSection = ({ children }: SideSectionProps) => {
    const { css } = useFela();

    if (!children) {
        return null;
    }

    return <div className={css(felaRules.container)}>{children}</div>;
};
