import { apiSelector } from '@ackee/redux-utils';

import type { AppState } from 'types';

import type { Secret, Share } from '../../types';

export const selectLoadSecretsApi = (state: AppState) => apiSelector(state, 'secrets', 'load');
export const selectDeleteSecretsApi = (state: AppState) => apiSelector(state, 'secrets', 'delete');
export const selectReadSecretApi = (state: AppState, id: Secret['id']) => apiSelector(state, 'secrets', 'read', id);
export const selectIgnoreReportSecretApi = (state: AppState, id: Secret['id']) =>
    apiSelector(state, 'secrets', 'ignoreReport', id);

export const selectShareSecretApi = (state: AppState, id: Secret['id']) => apiSelector(state, 'secrets', 'share', id);
export const selectReadShareSecretApi = (state: AppState, id: Share['id']) =>
    apiSelector(state, 'secrets', 'readShare', id);
export const selectGetSecretTOTPApi = (state: AppState, id: Secret['id']) => apiSelector(state, 'secrets', 'totp', id);
