import { type UseFormStateReturn, type FieldValues } from 'react-hook-form';
import type { FormItemProps as AntFormItemProps } from 'antd/es/form';

function getFieldError<FormValues extends FieldValues>(
    name: string | string[],
    { errors }: UseFormStateReturn<FormValues>,
) {
    const firstErrorFieldName = Array.isArray(name) ? name.find(n => n in errors) : name;

    if (!firstErrorFieldName) return;

    return errors[firstErrorFieldName];
}

function getIsTouched<FormValues extends FieldValues>(
    name: string | string[],
    { touchedFields }: UseFormStateReturn<FormValues>,
) {
    return Array.isArray(name) ? name.some(n => n in touchedFields) : name in touchedFields;
}

function getValidateStatus<FormValues extends FieldValues>(
    name: string | string[],
    formState: UseFormStateReturn<FormValues>,
): AntFormItemProps['validateStatus'] {
    const fieldError = getFieldError(name, formState);
    const fieldTouched = getIsTouched(name, formState);

    if (fieldTouched || formState.isSubmitted) {
        if (fieldError) return 'error';
        if (formState.isValid) return 'success';
    }
    return undefined;
}

export function mapFieldState<FormValues extends FieldValues>(
    name?: string | string[],
    formState?: UseFormStateReturn<FormValues>,
) {
    const props: AntFormItemProps = {};

    if (!name || !name.length || !formState) return props;

    const { isSubmitted } = formState;

    const fieldError = getFieldError(name, formState);
    const fieldTouched = getIsTouched(name, formState);

    if ((fieldTouched || isSubmitted) && fieldError) {
        props.help = <>{fieldError?.message}</>;
    }

    props.validateStatus = getValidateStatus(name, formState);

    return props;
}
