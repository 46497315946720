import { FormattedMessage } from 'react-intl';

import { hasReadPermission } from 'utilities/permission';
import { isEmptyOrUndefined } from 'utilities/string';

import { RedactedPaymentCardNumber } from '../../../../../RedactedPaymentCardNumber';
import { Fields, SecretType } from '../../../../../../constants';
import type { Secret } from '../../../../../../types';
import { CopySection } from '../CopySection';

export interface PaymentCardSectionsProps {
    secret: Extract<Secret, { type: SecretType.PaymentCard }>;
}

export const PaymentCardSections = ({ secret }: PaymentCardSectionsProps) => {
    const { sensitiveFields, expirationDate, cardholderName, redactedCardNumber } = secret;

    const hasRead = hasReadPermission(secret.userPermissions);

    return (
        <>
            <CopySection
                visible={!isEmptyOrUndefined(redactedCardNumber)}
                headingMessageKey="secret.cardNumber.label"
                secret={secret}
                property={Fields.CARD_NUMBER}
                disabled={!hasRead}
                mask={<RedactedPaymentCardNumber redactedNumber={redactedCardNumber} />}
            />
            <CopySection
                visible={!isEmptyOrUndefined(expirationDate)}
                headingMessageKey="secret.expirationDate.label"
                secret={secret}
                property={Fields.EXPIRATION_DATE}
            />
            <CopySection
                visible={sensitiveFields?.includes(Fields.CVV_CODE)}
                headingMessageKey="secret.cvvCode.label"
                secret={secret}
                property={Fields.CVV_CODE}
                disabled={!hasRead}
                mask={<FormattedMessage id="crypted.cvvCode" />}
            />
            <CopySection
                visible={!isEmptyOrUndefined(cardholderName)}
                headingMessageKey="secret.cardholderName.label"
                secret={secret}
                property={Fields.CARDHOLDER_NAME}
            />
        </>
    );
};
