import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import { Text } from 'modules/ui';

import { SecretType } from '../../../../constants';
import { SecretTypeIcon } from '../../../SecretTypeIcon';

import * as felaRules from './SelectOption.rules';

export interface SelectOptionProps {
    type: SecretType;
}

export const SelectOption = ({ type }: SelectOptionProps) => {
    const { css } = useFela();

    return (
        <div className={css(felaRules.container)}>
            <SecretTypeIcon type={type} />
            <Text size="small" customStyle={felaRules.text}>
                <FormattedMessage id={`secret.type.${type}`} />
            </Text>
        </div>
    );
};
