import { createAction } from '@reduxjs/toolkit';

import types from '../actionTypes';
import type { SettingsFormValues } from '../../schemas';

export const updateSettings = createAction(types.UPDATE_SETTINGS, (values: SettingsFormValues, actions: any) => ({
    meta: actions,
    payload: values,
}));

export const updateSettingsSuccess = createAction<SettingsFormValues>(types.UPDATE_SETTINGS_SUCCESS);

export const updateSettingsFailure = createAction(types.UPDATE_SETTINGS_FAILURE, (error: any) => ({
    payload: undefined,
    error,
}));
