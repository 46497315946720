import { basicResetReducer, containerReducer, type ContainerState } from '@ackee/redux-utils';
import { AUTH_SESSION_END } from '@ackee/petrus';
import { createReducer } from '@reduxjs/toolkit';
import { uniq } from 'lodash';
import { combineReducers, type Reducer } from 'redux';
import { persistReducer } from 'redux-persist';

import storage from 'redux-persist-indexeddb-storage';

import type { Secret, SecretById, TOTP } from '../../types';
import { SECRETS_CACHE, SecretType } from '../../constants';

import {
    ignoreReportSecretSuccess,
    removeSecretSuccess,
    shareSecretSuccess,
    readShareSecret,
    readShareSecretSuccess,
    setSecret,
    setSecrets,
    getSecretTOTPSuccess,
    getSecretTOTPReset,
    removeSecretTOTP,
    deleteSecretsSuccess,
    updateTag,
    removeTag,
} from '../actions';

const initialState: {
    byIds: SecretById;
    ids: Secret['id'][];
} = {
    byIds: {},
    ids: [],
};

const updateTagFn = (tags: string[] | undefined, tag: [string, string]) =>
    tags?.map(t => (t === tag[0] ? tag[1] : t)) ?? undefined;

const removeTagFn = (tags: string[] | undefined, tag: string) => tags?.filter(t => t !== tag) ?? undefined;

const list = createReducer(initialState, builder => {
    builder
        .addCase(setSecrets, (state, action) => {
            const { byIds, ids } = action.payload;

            if (action.meta.replace) {
                state.byIds = byIds;
                state.ids = ids;

                return;
            }

            state.byIds = {
                ...state.byIds,
                ...byIds,
            };
            state.ids = uniq([...state.ids, ...ids]);
        })
        .addCase(ignoreReportSecretSuccess, (state, action) => {
            const secret = state.byIds[action.meta.id];

            if (secret && secret.type === SecretType.Password) {
                secret.ignoreSecurityReport = true;
            }
        })
        .addCase(shareSecretSuccess, (state, action) => {
            const secret = state.byIds[action.meta.id];

            if (secret) {
                secret.share = action.payload;
            }
        })
        .addCase(removeSecretSuccess, (state, action) => {
            state.ids = state.ids.filter(id => id !== action.meta.id);
        })
        .addCase(deleteSecretsSuccess, () => initialState)
        .addCase(updateTag, (state, action) => {
            const ids = Object.keys(state.byIds);

            ids.forEach(id => {
                state.byIds[id].tags = updateTagFn(state.byIds[id].tags, action.payload.tag);
            });
        })
        .addCase(removeTag, (state, action) => {
            const ids = Object.keys(state.byIds);

            ids.forEach(id => {
                state.byIds[id].tags = removeTagFn(state.byIds[id].tags, action.payload.tag);
            });
        });
});

const resettedList = basicResetReducer(list, AUTH_SESSION_END);

const persistedList = persistReducer(
    {
        key: SECRETS_CACHE,
        storage: storage(SECRETS_CACHE),
    },
    resettedList,
);

// @ts-expect-error
const detailInitialState: Secret = null;

const detailChildReducer = createReducer(detailInitialState, builder => {
    builder.addCase(setSecret, (_, action) => ({
        // @ts-expect-error
        id: action.meta.id,
        ...action.payload,
    }));
    // @ts-expect-error
    builder.addCase(ignoreReportSecretSuccess, state => {
        if (state) {
            return {
                ...state,
                ignoreSecurityReport: true,
            };
        }
        return null;
    });
    // @ts-expect-error
    builder.addCase(shareSecretSuccess, (state, action) => {
        if (state) {
            return {
                ...state,
                share: action.payload,
            };
        }
        return null;
    });
    builder.addCase(updateTag, (state, action) => {
        state.tags = updateTagFn(state.tags, action.payload.tag);
    });
    builder.addCase(removeTag, (state, action) => {
        state.tags = removeTagFn(state.tags, action.payload.tag);
    });
});

const detail = containerReducer({
    actionTypes: [setSecret.toString(), ignoreReportSecretSuccess.toString(), shareSecretSuccess.toString()],
    childReducer: detailChildReducer,
    selectors: {
        itemId: action => action.meta.id,
    },
}) as Reducer<ContainerState<Secret>>;

// @ts-expect-error
const shareInitialState: Secret = null;

const shareChildReducer = createReducer(shareInitialState, builder => {
    builder.addCase(readShareSecret, () => shareInitialState);
    builder.addCase(readShareSecretSuccess, (_, action) => ({
        ...action.payload,
        id: action.meta.id,
    }));
});

const share = containerReducer({
    actionTypes: [readShareSecret.toString(), readShareSecretSuccess.toString()],
    childReducer: shareChildReducer,
    selectors: {
        itemId: action => action.meta.id,
    },
}) as Reducer<ContainerState<Secret>>;

const totpInitialState: TOTP[] = [];

const totpChildReducer = createReducer(totpInitialState, builder => {
    builder.addCase(getSecretTOTPSuccess, (state, action) => [...state, ...action.payload]);
    builder.addCase(getSecretTOTPReset, () => totpInitialState);
    builder.addCase(removeSecretTOTP, (state, action) => state.filter(({ code }) => code !== action.meta.code));
});

const totp = containerReducer({
    actionTypes: [getSecretTOTPSuccess.toString(), getSecretTOTPReset.toString(), removeSecretTOTP.toString()],
    childReducer: totpChildReducer,
    selectors: {
        itemId: action => action.meta.id,
    },
}) as Reducer<ContainerState<TOTP[]>>;

export const entitiesReducer = combineReducers({
    list: persistedList,
    detail: basicResetReducer(detail, AUTH_SESSION_END),
    totp: basicResetReducer(totp, AUTH_SESSION_END),
    share,
});
