import { useCallback, useEffect, useMemo, useRef } from 'react';

import { debounce } from 'lodash';

export function useAutosaveForm<Values>(submit: () => Promise<Values | undefined> | undefined) {
    const submitPromiseRef = useRef<Promise<Values | undefined> | null>(null);
    const intermediateSubmitRef = useRef(false);

    const handleSubmit = useCallback(() => {
        const optionallySubmit = () => {
            const submitPromise = submit();
            submitPromiseRef.current = submitPromise ?? null;

            submitPromiseRef.current?.finally(() => {
                submitPromiseRef.current = null;
                // If there was a submit in the middle of the running submit, trigger it
                if (intermediateSubmitRef.current) {
                    setTimeout(() => {
                        // Move the submit to the next tick of the loop so we can be sure all the other code has finnished
                        // Without the timeout, react-final-form in optionallySubmit would return undefined from submit()
                        // as it would wait until this current promise is finnished and it is not
                        optionallySubmit();
                        intermediateSubmitRef.current = false;
                    }, 0);
                }
            });
        };

        if (submitPromiseRef.current) {
            // If a submit is in progress, wait untill it finishes and then trigger a new one
            intermediateSubmitRef.current = true;
        } else {
            optionallySubmit();
        }
    }, [submit]);

    const delayedSubmit = useMemo(() => debounce(handleSubmit, 500), [handleSubmit]);

    useEffect(
        () => () => {
            delayedSubmit.cancel();
        },
        [delayedSubmit],
    );

    return { delayedSubmit, handleSubmit };
}
