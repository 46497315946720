import { put, select } from 'redux-saga/effects';

import { api } from 'config/antonio';
import { config } from 'config/config';
import { logger } from 'config/logger';

import { TagManager } from 'services/utilities';
import { selectRouting } from 'services/selectors';
import { takeLatestRequest } from 'services/utilities/takeRequest';

import {
    updateSecretSuccess,
    updateSecretFailure,
    setSecrets,
    updateSecretReset,
    updateSecret,
    setSecret,
} from '../actions';
import { normalizeSecret } from '../normalizers';
import { prepareSecretPayload } from '../selectors/utilities';

function* handler({ meta, payload }: ReturnType<typeof updateSecret>, signal: AbortSignal) {
    try {
        const { data } = yield* api.put<any>(config.api.secret, prepareSecretPayload(payload), {
            uriParams: {
                id: meta.id,
            },
            signal,
        });

        const routing: ReturnType<typeof selectRouting> = yield select(selectRouting);

        if (routing.location?.pathname.includes('security-audit')) {
            TagManager.push('updated_security_report_password');
        }

        const { entities, result } = normalizeSecret(data);

        yield put(updateSecretSuccess(meta.id));
        yield put(setSecret(result, entities.secrets[result]));
        yield put(setSecrets(entities.secrets, [result]));

        meta.success();
    } catch (e) {
        logger.error(e);

        yield put(updateSecretFailure(meta.id, e));

        meta.failure('root', { message: 'error.secrets.update' });
    }
}

export default function* () {
    yield takeLatestRequest(
        {
            actionCreator: updateSecret,
            cancelActionFunction: action => updateSecretReset(action.meta.id),
            idSelector: action => action.meta.id,
        },
        handler,
    );
}
