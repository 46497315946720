import { lazy, Suspense, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFela } from 'react-fela';
import { delay } from 'lodash';
import useToggle from 'react-use-toggle';

import { useController } from 'react-hook-form';

import { Icon } from 'modules/ui';
import { Label, TextInput, PasswordInput, FormField } from 'modules/forms';

import { Fields } from '../../../constants';

import { GeneratePasswordWidget } from '../../GeneratePasswordWidget';
import { GeneratorButtonPopover } from './GeneratorButtonPopover';

import * as felaRules from './PasswordField.rules';

const PasswordStrength = lazy(() =>
    import('../PasswordStrength').then(imports => ({
        default: imports.PasswordStrength,
    })),
);

export interface PasswordFieldProps {
    fullWidth?: boolean;
}

export const PasswordField = ({ fullWidth }: PasswordFieldProps) => {
    const { css } = useFela();

    const [hidden, toggle] = useToggle(true);
    const [generatorButtonPopoverOpen, setGeneratorPopoverButtonOpen] = useState(false);
    const [passwordGeneratorOpen, setPasswordGeneratorOpen] = useState(false);

    const name = Fields.PASSWORD;

    const {
        field: { onChange, onBlur, value },
    } = useController({ name });

    const label = (
        <Label>
            <FormattedMessage id={`secret.${name}`} />
        </Label>
    );

    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | string | undefined) => {
        if (e === undefined) return;

        const value = typeof e === 'string' ? e : e.target.value;

        onChange(value);
    };

    const handleFocus = (e: React.FocusEvent<HTMLInputElement | HTMLSpanElement>) => {
        setGeneratorPopoverButtonOpen(true);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLSpanElement>) => {
        // Delay closing the popover so the user can click on the button after the input loses focus
        delay(setGeneratorPopoverButtonOpen, 250, false);
        onBlur();
    };

    const handleGenerate = (password: string) => {
        onChange(password);
        onBlur();
    };

    return (
        <>
            <div className={css(felaRules.wrapper)}>
                <div className={css(felaRules.container)}>
                    <FormField
                        name={name}
                        htmlFor={name}
                        label={label}
                        customStyle={felaRules.formItem}
                        fullWidth={fullWidth}
                    >
                        <GeneratePasswordWidget
                            open={passwordGeneratorOpen}
                            onClose={() => {
                                setPasswordGeneratorOpen(false);
                                setGeneratorPopoverButtonOpen(false);
                            }}
                            onSave={handleGenerate}
                        >
                            <GeneratorButtonPopover
                                isOpen={generatorButtonPopoverOpen && !passwordGeneratorOpen}
                                openPasswordGenerator={() => setPasswordGeneratorOpen(true)}
                            >
                                {hidden ? (
                                    <TextInput
                                        name={name}
                                        id={name}
                                        value={value}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="password"
                                        suffix={<Icon onClick={toggle} type="eye" color="accentPrimary" />}
                                        customStyle={felaRules.cryptedTextInput}
                                        // Hack to prevent autocomplete in Chrome: https://stackoverflow.com/a/30976223
                                        autoComplete="one-time-code"
                                    />
                                ) : (
                                    <PasswordInput
                                        id={name}
                                        value={value}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        suffix={<Icon onClick={toggle} type="eye-close" color="accentSecondary" />}
                                        customStyle={felaRules.textInput}
                                    />
                                )}
                            </GeneratorButtonPopover>
                        </GeneratePasswordWidget>

                        <Suspense fallback={null}>
                            <PasswordStrength value={value} />
                        </Suspense>
                    </FormField>
                </div>
            </div>
        </>
    );
};
