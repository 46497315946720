import { useAutosaveForm } from 'hooks/useAutosaveForm';
import { useFormSubmit } from 'modules/forms';

import { DefaultPermissionField } from '../DefaultPermissionField';
import { SharingPermissionField } from '../SharingPermissionField';

export const SettingsForm = () => {
    const submit = useFormSubmit();

    const { delayedSubmit } = useAutosaveForm(submit);

    return (
        <>
            <DefaultPermissionField onChange={delayedSubmit} />
            <SharingPermissionField onChange={delayedSubmit} />
        </>
    );
};
