import type { ParsedState, SearchParams } from 'modules/auth/types';

export function forwardParamsToExtension(params: SearchParams, state: ParsedState) {
    if (!state.extensionId) return;

    return new Promise<void>(res => {
        const port = chrome.runtime.connect(state.extensionId ?? '', { name: state.nonce });

        function onMessage() {
            port.postMessage(params);
            port.onMessage.removeListener(onMessage);
            res();
        }

        port.onMessage.addListener(onMessage);
    });
}
