import { z } from 'zod';

import type { MessageKey } from 'modules/localizations/translations/types';

import { Delimiter, SecretsFormat } from '../types';

export const importFormSchema = z.object({
    secrets: z.custom<File>(val => val instanceof File, { message: 'form.error.required' as MessageKey }),
    format: z.nativeEnum(SecretsFormat),
    delimiter: z.nativeEnum(Delimiter),
    replaceSecrets: z.boolean(),
});

export type ImportFormSchemaType = typeof importFormSchema;

export type ImportFormValues = z.infer<typeof importFormSchema>;
